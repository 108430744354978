/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.List {
  top: 8%;
  height: 80%;
  width: 300px;
  margin-left: -150px;
  overflow: hidden;

  .no-hover {
    &:hover {
      background-color: transparent !important;
      cursor: default !important;
    }
  }

  .message {
    font-size: 14px;
    padding: 12px 10px 5px;
    color: $gray1;
    text-transform: uppercase;
  }

  .search {
    padding: 0px 30px 5px 5px;

    i {
      font-size: 16px;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      border-right: none;
      color: $viewer_black;
      padding: 10px 10px 10px 10px;
    }

    input {
      border: none;
      height: 38px;
      padding: 10px;
      font-size: 12px;
      vertical-align: top;
      color: $viewer_black;
      width: 212px;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      border-left: none;

      &:focus {
        outline: none;
        border: 1px solid $lightorange;
        color: $darkorange;
      }
    }
  }

  .selectors {
    text-transform: uppercase;
    width: 100%;
    padding-left: 5px;
    background-color: $extremelightgray;
    border-bottom: 1px solid $lightlightlightgray;
    text-align: left;

    .selector {
      padding: 2px 0;

      span.selector-link {
        display: inline-block;
        padding: 2px 5px;
        font-size: 10px;

        &:hover {
          background-color: $lightlightgray;
          cursor: pointer;
        }
      }
    }
  }

  .pinned {
    text-transform: uppercase;
    background-color: $lightlightlightgray;
    border-top: 1px solid $lightlightgray;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;

    .pinned-item {
      display: inline-block;
      padding: 5px 10px;

      &.right {
        float: right;
      }

      &:hover {
        background-color: $lightlightgray;
        cursor: pointer;
      }
    }
  }

  .filter-container {
    @include span-columns(14);
    @include pad(5px 10px 5px);
    margin-bottom: 2px;
    background-color: #f0f0f0;

    .filter-title {
      @include span-columns(4);
      font-size: 12px;
      color: #ababab;
      text-transform: uppercase;
    }

    .filter-name {
      font-size: 12px;
      color: $lightorange;
      text-decoration: underline;

      &:hover {
        color: $darkorange;
        cursor: pointer;
      }
    }
  }

  .info-container {
    @include span-columns(14);
    @include pad(8px 10px 8px);
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
    display: flex;

    & i {
      padding-top: 4px;
    }

    & .info-title {
      padding-left: 6px;
    }
  }

  .list-items {
    @include span-columns(14);
    overflow-y: scroll;
    @include calc(height, "100% - 110px");
    @include lb-scrollbar(transparent, $lightlightgray);

    &.with-filter {
      @include calc(height, "100% - 140px");
    }

    &.with-block-info {
      @include calc(height, "100% - 170px");

      &.with-filter {
        @include calc(height, "100% - 200px");
      }
    }

    .list-items-grouping {
      @include span-columns(14);
      border-left: 5px solid $white;
      padding-bottom: 1px;

      & .list-items-grouping-title {
        @include span-columns(14);
        @include pad(5px 7px);
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 2px;

        & .actions-row {
          font-size: 10px;

          & .actions-row-item {
            text-transform: uppercase;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &.unscheduled {
        border-left: 3px solid $lightblue;

        & .list-items-grouping-title {
          background-color: $lightlightlightblue;
          border-bottom: 1px solid $lightblue;
          color: $darkblue;
        }
      }

      &.compatible {
        border-left: 3px solid $lightgreen;

        & .list-items-grouping-title {
          background-color: $lightlightlightgreen;
          border-bottom: 1px solid $lightgreen;
          color: $darkgreen;
        }
      }

      &.incompatible {
        border-left: 3px solid $red;

        & .list-items-grouping-title {
          background-color: $lightlightlightred;
          border-bottom: 1px solid $red;
          color: $darkred;
        }
      }
    }

    .loader {
      @include span-columns(14);
      @include pad(20px);
      text-align: center;

      i {
        font-size: 28px;
      }
    }

    .empty-list {
      @include span-columns(14);
      @include pad(40px 10px);
      font-size: 12px;
      text-align: center;
    }

    .ListItem {
      font-size: 12px;
      padding: 1px 2px 1px 0px;
      margin-left: 2px;
      color: $viewer_black;

      span {
        display: inline-block;
      }

      .icon {
        width: 20px;
        font-size: 12px;
      }

      .text {
        width: 100%;
        padding: 5px 5px;

        &:hover {
          background-color: $lightlightlightgray;
          cursor: pointer;
        }

        &.compatible {
          background-color: $lightlightlightgreen;
        }

        &.incompatible {
          background-color: $lightlightlightred;
        }

        &.selected {
          background-color: $postit;
          color: $gray1;
          padding-left: 20px;
        }
      }
    }
  }
}

@import "./responsive.scss";
