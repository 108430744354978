@import "bourbon.scss";
@import "neat.scss";
@import "grid-settings";
@import "variables";
@import "mixins";

$img_viewer: url("../../../../_lib/ui/images/logo_tile_beta_cut.png");
$img_maker: url("../../../../_lib/ui/images/logo_tile_blue_beta.png");
$img_viewdesign: url("../../../../_lib/ui/images/logo_tile_yellow.png");
$img_monitor: url("../../../../_lib/ui/images/logo_tile_red.png");

#SelectionScreen {
  @include outer-container;
  color: $darkdarkdarkblue;

  .page-title {
    @include span-columns(8 of 8);
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .ApplicationElement {
    @include media($desktop) {
      @include span-columns(8 of 8);
    }

    @include media($tablet) {
      @include span-columns(8 of 8);
    }

    @include media($mobile) {
      @include span-columns(8 of 8);
    }

    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.02);

    &:hover {
      cursor: pointer;
      background-color: rgba(38, 148, 232, 0.15);
    }

    .content {
      .title {
        width: 100%;
        text-transform: uppercase;
        font-size: 18px;
        color: $gray1;
        padding-left: 10px;
        padding-top: 2px;
        vertical-align: middle;
      }

      .title-new {
        padding-left: 10px;
        font-size: 12px;
        color: red;
      }

      p {
        font-size: 13px;
        padding: 0px;
        padding-top: 4px;
        padding-right: 10px;
        margin: 0px;
        line-height: 16px;
      }
    }

    .ApplicationLogo {
      position: relative;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      width: 60px;
      height: 60px;
      float: left;
      margin: 0px 20px 0 10px;
      background-color: $green;
      -webkit-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

      &:hover {
        cursor: pointer;
      }

      .icon {
        margin: 0 auto;
        width: 40px;
      }

      .fa {
        padding-top: 12px;
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: white;
      }
    }
  }

  .Bulletin {
    width: 100%;
    margin-bottom: 10px;

    & > .title {
      background-color: $lightorange;
      color: $white;
      font-size: 16px;
      padding: 9px 10px;
      text-transform: uppercase;

      & > i {
        font-size: 24px;
        margin-right: 10px;
        padding-top: 1px;
      }
    }

    .content-item {
      padding: 10px 20px;
      background-color: rgba(0, 0, 0, 0.02);

      & > .title {
        font-size: 14px;
        padding-bottom: 5px;
        text-transform: uppercase;

        & .date {
          float: right;
          font-size: 10px;
        }
      }

      .desc {
        font-size: 12px;
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(38, 148, 232, 0.15);
      }
    }
  }

  .LegacyLink,
  .SupportLink {
    width: 100%;
    margin-bottom: 5px;

    & > .title {
      background-color: $lightorange;
      color: $white;
      font-size: 16px;
      padding: 9px 10px;
      text-transform: uppercase;

      & > i {
        font-size: 24px;
        margin-right: 10px;
        padding-top: 1px;
      }
    }

    .content-item {
      padding: 10px 20px;
      background-color: rgba(0, 0, 0, 0.02);

      & > .title {
        font-size: 14px;
        padding-bottom: 5px;
        text-transform: uppercase;

        & .date {
          float: right;
          font-size: 10px;
        }
      }

      .desc {
        font-size: 12px;
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(38, 148, 232, 0.15);
      }
    }
  }

  #LeftPad {
    margin-top: 50px;
    @include span-columns(4);

    @include media($mobile) {
      @include span-columns(4);
    }

    .apps > .title {
      text-transform: uppercase;
      font-size: 16px;
      color: $white;
      @include span-columns(14);
      padding: 10px 5px 10px 15px;
      background-color: $darkdarkdarkblue;
      vertical-align: top;

      & > i {
        font-size: 24px;
        margin-right: 10px;
        padding-top: 1px;
        vertical-align: bottom;
      }
    }

    .UpcomingShifts {
      @include span-columns(14);

      > .title {
        text-transform: uppercase;
        font-size: 16px;
        color: $white;
        @include span-columns(14);
        padding: 10px 5px 10px 15px;
        background-color: $darkdarkdarkblue;
        vertical-align: top;

        & > i {
          font-size: 24px;
          margin-right: 10px;
          padding-top: 1px;
          vertical-align: bottom;
        }
      }

      .slot-list {
        @include span-columns(14);
        @include pad(0px 0px 0px 0px);

        .loader {
          @include span-columns(14);
          @include pad(25px);
          text-align: center;

          i {
            font-size: 28px;
          }
        }

        .slots-container {
          @include span-columns(4.66666667);
          background-color: $lightlightlightgray;
          border-left: 1px solid $white;
          border-bottom: 1px solid $lightlightgray;

          .date {
            @include pad(5px 10px);
            text-align: left;
            font-size: 14px;
            text-transform: uppercase;
            background-color: $lightblue;
            color: $white;
          }

          .slots {
            @include pad(10px);

            .slot {
              padding-bottom: 10px;
              font-size: 12px;

              .assignment {
                color: $gray1;
              }

              &:last-child {
                padding-bottom: 0px;
              }
            }
          }

          &:first-child {
            background-color: #f5f5f5;
            border-left: none;
          }

          animation: fadeIn 0.3s ease-out;
        }
      }

      .footer {
        @include span-columns(14);
        @include pad(5px 10px);
        text-transform: uppercase;

        .today {
          @include span-columns(7);
          text-align: left;

          span.link {
            text-decoration: underline;

            &:hover {
              color: $lightblue;
              cursor: pointer;
            }
          }
        }

        .my {
          @include span-columns(7);
          text-align: right;

          span.link {
            text-decoration: underline;

            &:hover {
              color: $lightblue;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  #CenterPad {
    @include span-columns(6);
    @include pad(0 10px);
    padding-top: 50px;

    @include media($mobile) {
      @include span-columns(4);
      @include pad(10px 0);
      @include shift(0);
    }

    & > .title {
      text-transform: uppercase;
      font-size: 16px;
      color: $white;
      @include span-columns(14);
      padding: 10px 5px 10px 15px;
      background-color: $darkdarkdarkblue;
      vertical-align: top;

      & > i {
        font-size: 24px;
        margin-right: 10px;
        padding-top: 1px;
        vertical-align: bottom;
      }
    }
  }

  #RightPad {
    margin-top: 50px;
    @include span-columns(4);

    @include media($mobile) {
      position: static;
      @include span-columns(4);
      @include shift(0);
    }
  }

  .s1-message {
    padding-left: 10px;
    padding-top: 50px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
