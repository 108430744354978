/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.Dialog.Feedback {
  width: 50%;
  top: 10%;
  height: 80%;
  margin-left: -25%;
  background-color: $white;
  border-radius: 0;

  .title-container {
    position: relative;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    color: $white;
    width: 100%;
    padding: 5px 5px;
    background-color: rgba(32, 141, 225, 0.75);

    .text {
      padding: 5px 5px;

      i {
        font-size: 18px;
        padding-right: 10px;
        vertical-align: bottom;
      }
    }

    .sub-text {
      font-size: 10px;
    }
  }

  p {
    padding: 5px 20px 5px 20px;
    font-size: 14px;
  }

  .feedback-text {
    margin: 0 2.5%;
    width: 95%;
    height: 50%;
    border: 0;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
  }

  .action-bar {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
  }

  .cancel-button {
    padding: 7px;
    color: $gray1;
    width: 100px;
    margin: 0 auto;
    text-transform: uppercase;
    font-size: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .submit-button {
    padding: 7px;
    color: $gray1;
    background-color: $lightlightlightgray;
    width: 100px;
    margin: 0 auto 20px;
    text-transform: uppercase;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      background-color: $lightlightgray;
    }
  }
}

@import "./responsive";
