@import "flex";
@import "margin_padding";
@import "variables";

.scrollable {
  overflow-y: auto;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.action-icon-container {
  .action-icon {
    opacity: 0.3;
    font-size: 17px;
  }

  &:hover {
    .action-icon {
      cursor: pointer;
      animation: fadeIn30to100 1s;
      -moz-animation: fadeIn30to100 1s;
      -webkit-animation: fadeIn30to100 1s;
      -o-animation: fadeIn30to100 1s;
      opacity: 1;

      &:hover {
        color: $gray3;
      }

      &.danger {
        color: $darkred !important;
      }
    }
  }
}

@keyframes fadeIn30to100 {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn30to100 {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn30to100 {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn30to100 {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn30to100 {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease 250ms;
  -webkit-animation: fadeIn ease 250ms;
  -moz-animation: fadeIn ease 250ms;
  -o-animation: fadeIn ease 250ms;
  -ms-animation: fadeIn ease 250ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listitem {
  border: 1px solid transparent;
  padding: 5px 10px;
  width: 100%;

  &:hover {
    background-color: #f0f0f0;
    border: 1px solid #dcdcdc;
    cursor: pointer;
  }

  &.selected {
    color: $brand-darkblue;
  }

  &.selected-row {
    background-color: $lightlightlightblue;
    border: 1px solid $brand-darkblue;
  }

  .fa-checkbox {
    margin-right: 5px;
  }
}

.btn {
  text-align: center;
  display: inline-block;
  padding: 8px 15px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: $lightlightlightgray;
  border: 1px solid $lightlightgray;

  &:hover:enabled {
    cursor: pointer;
    background-color: $brand-lightblue;
    border: 1px solid $brand-darkblue;
    color: #ffffff;
  }
}

.btn-input {
  height: 31px;
  padding: 5px;
  width: 100%;
}

.dropdown {
  position: relative;

  .menu {
    text-align: left;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    position: absolute;
    background-color: white;
    border: 1px solid $lightlightgray;
    z-index: 10;
    padding: 5px 0px;
    display: none;

    &.open {
      display: flex;
      flex-direction: column;
      width: 500px;
      padding: 15px;
      border-radius: 10px;
      top: 45px;

      &:not(.right) {
        left: 0px;
      }

      &.right {
        right: 0px;
      }

      &.auto {
        width: auto;
        padding: 15px 25px;
      }

      &.print {
        width: 300px;
      }

      animation: fadeIn ease 250ms;
      -webkit-animation: fadeIn ease 250ms;
      -moz-animation: fadeIn ease 250ms;
      -o-animation: fadeIn ease 250ms;
      -ms-animation: fadeIn ease 250ms;
    }

    .divider {
      margin: 15px 0px;
      height: 1px;
      background-color: gainsboro;
    }

    .input-search {
      width: 100%;
      border-radius: 10px;
      height: 30px;
      padding: 10px;
      background-color: $lightlightgray;
      border: 1px solid $lightlightgray;
    }

    .scrollable {
      max-height: 500px;
    }

    .fit-content {
      min-width: 156px;
      width: max-content;
    }
  }
}

.action-link {
  display: inline-block;
  color: $lightblue;
  text-decoration: underline;

  &:hover {
    color: $darkblue;
    cursor: pointer;
  }

  &.disabled {
    color: $lightgray;
    text-decoration: none;
    pointer-events: none;
  }
}

.field-error {
  color: $red;
  font-size: 12px;
}
