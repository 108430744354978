// margin and padding values array
$space-values: (0, 1, 2, 3, 5, 7, 10, 15, 20, 25, 30, 35, 40, 45, 50) !default;

// margin and padding shorthands
$space-prefixes: (
  p: padding,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left,
  m: margin,
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left,
) !default;

@mixin make-spaces() {
  @each $attr-short, $attr-long in $space-prefixes {
    @each $value in $space-values {
      .#{$attr-short}-#{$value} {
        #{$attr-long}: #{$value}#{"px"};
      }
    }
  }
}

@include make-spaces();

$space-values: (0, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50) !default;

$spaces: (padding, margin) !default;

$space-sides: (top, right, bottom, left) !default;

@mixin make-spaces() {
  @each $space in $spaces {
    @each $value in $space-values {
      .#{$space}-#{$value} {
        #{$space}: #{$value}#{"px"};
      }
      @each $side in $space-sides {
        .#{$space}-#{$side}-#{$value} {
          #{$space}-#{$side}: #{$value}#{"px"};
        }
      }
    }
  }
}
