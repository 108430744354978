/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.DialogContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1001;
  visibility: hidden;

  &.active {
    visibility: visible;

    & .mask {
      background-color: transparent;
    }
  }

  & .mask {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: transparent;
    @include transition(all 0.5s ease 0s);
  }
}

.Dialog {
  position: absolute;
  left: 50%;
  top: 15%;
  margin-left: -15%;
  width: 30%;
  height: 45%;
  background-color: $white;
  z-index: -1;
  @include dialog-shadow;

  &.isTop {
    z-index: 1;
  }

  /* cross-dialog items */
  & .close {
    position: absolute;
    top: 2px;
    right: 0px;
    padding: 6px 10px;
    z-index: 100;

    i {
      font-size: 24px;
    }

    &:hover {
      cursor: pointer;
      color: $gray1;
    }
  }

  @include animation(bounce 0.2s ease-out);
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@import "./responsive.scss";
