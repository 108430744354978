@use "~@/common/scss/variables/theme";

.root {
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;
}

.dropdown-menu-footer {
  padding: 12px 12px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid theme.$lightgray;

  .left-actions-wrapper {
    display: flex;
    align-items: center;
  }

  .action {
    color: theme.$black;
    font-size: 13px;
    cursor: pointer;
    margin: 0;
  }

  .action-disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.2;
  }

  .action-divider {
    color: theme.$dark-border;
    margin: 0 6px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
