/* datepicker styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.DatePicker {
  @include outer-container;

  .DatePickerControls {
    @include span-columns(14);
    margin-bottom: 5px;
    padding-left: 4.5%;
    font-size: 14px;
    color: $gray1;
    text-align: left !important;

    div.text {
      display: inline-block;
      padding: 5px 20px;
      width: 175px;
      text-transform: uppercase;
      text-align: center !important;
    }

    div.month-text {
      display: inline-block;
      vertical-align: middle;
      padding: 5px 20px;
      width: 200px;
      text-transform: uppercase;

      &.left {
        float: left;
      }

      &.right {
        float: right;
      }
    }

    i.fa {
      padding: 4px 10px;
      font-size: 18px !important;
      border: 1px solid $white;

      &:hover {
        background-color: $lightlightgray;
        cursor: pointer;
      }
    }

    .arrow-left {
      vertical-align: top;
      display: inline-block;
      padding: 10px;
      width: 24px;
      height: 24px;

      &:hover {
        background-color: #cdcdcd;
        cursor: pointer;
      }

      &.inactive {
        opacity: 0.1;

        &:hover {
          background-color: #ffffff !important;
          cursor: text !important;
        }
      }
    }

    .arrow-right {
      vertical-align: middle;
      display: inline-block;
      width: 24px;
      height: 24px;

      &:hover {
        background-color: #cdcdcd;
        cursor: pointer;
      }

      &.inactive {
        opacity: 0.1;

        &:hover {
          background-color: #ffffff !important;
          cursor: text !important;
        }
      }
    }
  }

  .DatePickerCalendar {
    width: 100%;
    margin: 0 4.5%;

    .DatePickerCalendarHeader {
      width: 100%;

      .day {
        width: 13%;
        float: left;
        color: $viewer_black;
        padding: 2px 5px;
        text-transform: uppercase;
        text-align: center;
        font-size: 10px;
      }
    }

    .calendar-dates {
      width: 100%;

      .datepicker-calendar-offset {
        width: 0%;
        float: left;
        height: 10px;

        &._1 {
          width: 13%;
        }

        &._2 {
          width: 26%;
        }

        &._3 {
          width: 39%;
        }

        &._4 {
          width: 52%;
        }

        &._5 {
          width: 65%;
        }

        &._6 {
          width: 78%;
        }
      }

      .DatePickerCalendarDate {
        width: 13%;
        float: left;
        background-color: #f5f5f5;
        color: $gray1;
        padding: 8px;
        text-align: center;
        border: 1px solid $white;
        font-size: 10px;

        &.weekend {
          background-color: #e9e9e9;
        }

        &:hover {
          background-color: $lightlightgray;
          cursor: pointer;
        }

        &.unselectable {
          background-color: rgba(255, 0, 0, 0.065);
          color: $lightlightgray;

          &:hover {
            cursor: text !important;
          }
        }

        &.today {
          background-color: rgba(62, 171, 255, 0.2);
        }

        &.selected {
          background-color: $green;
          color: $white;
        }
      }
    }
  }

  .DatePickerSubmit {
    @include span-columns(14);
    @include pad(10px);
    margin-top: 10px;

    .dates {
      @include span-columns(10);
      font-size: 12px;
      padding: 5px !important;
      margin-top: 2px;
      text-align: left !important;
    }

    .submit-button {
      padding: 5px !important;
      background-color: $lightlightlightgray !important;
      border: 1px solid $lightlightgray;
      font-size: 14px !important;
      text-align: center;
      text-transform: uppercase;
      width: 70px !important;
      margin: 0px auto !important;
      color: $viewer_black !important;
      float: right;

      &:hover {
        cursor: pointer;
        background-color: $lightblue !important;
        border: 1px solid $darkdarkdarkblue !important;
        color: $white !important;
      }
    }
  }

  .DatePickerMonthSelect {
    width: 100%;
    padding: 10px;

    & .month {
      width: 25%;
      float: left;
      background-color: $lightlightlightgray !important;
      padding: 12px 10px;
      border: 2px solid $white;
      text-transform: uppercase;
      text-align: center;

      &:hover {
        background-color: $lightlightgray !important;
        cursor: pointer;
      }

      &.selected {
        background-color: $green !important;
        color: $white;
      }
    }
  }
}

.BlockDetails {
  @include pad(5px 15px);
  font-size: 12px;
  color: $viewer_black;

  &__text {
    margin-top: 4px;
  }
}
