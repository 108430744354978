/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

.moveDialog {
  .timeInput {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  &-footerContainer {
    box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 66px;
      border-radius: unset;
    }


    .footerContainer-button__submit,
    .footerContainer-button__next {
      position: absolute;
      right: 0;
    }

    .footerContainer-button__back,
    .footerContainer-button__next {
      background-color: #f0f0f0;

      &:hover {
        background-color: #e5e5e5;
      }
    }

    .footerContainer-button__submit {
      color: white;
      background-color: #5bbf19;

      &:hover {
        background-color: #49b800;
      }
    }
  }

  .MuiToolbar-root {
    height: 10px;

    .MuiTypography-h4 {
      padding-bottom: 5px;
    }

    .MuiTypography-root {
      font-size: 20px;

    }
  }
}
