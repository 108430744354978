/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.Requests {
  width: 300px;
  height: 90%;
  top: 5%;
  margin-left: -150px;
}

.Dialog {
  & .editables {
    @include span-columns(14);
    @include pad(5px 15px);

    .selectable {
      @include span-columns(14);
      margin-bottom: 2px;

      .selectable-label {
        @include span-columns(4);
        text-transform: uppercase;
        line-height: 29px;
      }

      .selectable-value {
        @include span-columns(10);
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;
        padding: 5px 10px;

        &:hover {
          background-color: $lightlightgray;
          border: 1px solid $lightgray;
          cursor: pointer;
        }
      }

      &.disabled {
        .selectable-value {
          background-color: $extremelightgray;
          border: 1px solid $lightlightgray;
          padding: 5px 10px;
          color: $lightgray;

          &:hover {
            background-color: $extremelightgray;
            border: 1px solid $lightlightgray;
            cursor: default !important;
          }
        }
      }
    }

    .textable {
      @include span-columns(14);
      margin-bottom: 5px;

      .textable-label {
        @include span-columns(4);
        text-transform: uppercase;
        line-height: 29px;
      }

      .textable-value {
        @include span-columns(9);
        @include pad(6px 10px);
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;

        &:hover {
          background-color: $lightlightgray;
          border: 1px solid $lightgray;
        }
      }

      i.fa.fa-ellipsis-v {
        @include span-columns(0.75);
        @include shift(0.25);
        @include pad(4px 5px 3px);
        font-size: 20px;
        color: $lightgray;

        &:hover {
          background-color: $lightgray;
          color: $white;
          cursor: pointer;
        }
      }
    }

    .timeable {
      @include span-columns(14);
      margin-bottom: 2px;

      .timeable-label {
        @include span-columns(5);
        text-transform: uppercase;
        line-height: 29px;
        display: none;
      }

      .timeable-value {
        @include span-columns(14);
        @include pad(6px 0px);
        overflow: hidden;

        & .label {
          vertical-align: top;
        }

        & .value {
          padding-right: 20px;

          i {
            font-size: 16px;
            padding-right: 5px;
            color: $viewer_black;
          }
        }
      }
    }

    .checkable {
      @include span-columns(14);
      @include pad(5px 0px);
      margin-bottom: 2px;

      .checkable-label {
        display: inline-block;
        vertical-align: top;
      }

      .checkable-value {
        display: inline-block;
        overflow: hidden;

        .value {
          font-size: 12px;

          i {
            font-size: 16px;
            padding-right: 5px;
            color: $viewer_black;
          }
        }
      }
    }
  }
}

@import "./responsive";
