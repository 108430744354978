/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

.request-details {
  @include span-columns(14);
  height: 100vh;
  z-index: -1;
  pointer-events: none;

  &.isTop {
    z-index: 1;

    & .Dialog {
      z-index: 1 !important;
    }
  }

  div.container-middle {
    @include span-columns(14);
    position: relative;
    height: 100vh;
  }
}

.Dialog.RequestDetails {
  width: 320px;
  height: auto;
  top: 5%;
  margin-left: -160px;
  pointer-events: all;

  .mobile-section-nav {
    @include span-columns(14);
    @include pad(0px 0px 0);

    .mobile-section-nav-tab {
      display: inline-block;
      @include pad(10px 10px);
      font-size: 13px;
      color: $viewer_black;

      &.group-2 {
        display: none;
      }

      &.active {
        background-color: $lightlightlightgray;
        color: $gray1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    @include span-columns(14);

    .header {
      @include span-columns(14);
      color: $gray1;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .content-body {
      @include span-columns(14);
    }

    .message {
      @include span-columns(14);
      font-size: 10px;
      margin-top: 2px;

      i.fa-quote-left {
        font-size: 8px;
        padding-right: 2px;
        display: none;
      }

      i.fa-quote-right {
        font-size: 8px;
        padding-left: 2px;
        display: none;
      }

      background-color: rgba(255, 191, 0, 0.4);
      color: #997200;
      border-left: 3px solid rgba(255, 195, 85, 1);

      & .message-text {
        @include span-columns(12);
        @include pad(8px 10px);
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $viewer_black;
      }

      & .expand-text {
        @include span-columns(2);
        @include pad(8px 2px);
        text-align: center;
        text-decoration: underline;
        text-transform: lowercase;

        &:hover {
          color: #333333;
          cursor: pointer;
        }
      }

      &.pending {
        background-color: rgba(62, 171, 255, 0.1);
        color: $lightblue;
        border-left: 0px solid rgba(62, 171, 255, 0.5);

        & .message-title {
          @include span-columns(14);
          @include pad(8px 10px 0);
          font-size: 13px;
          text-transform: uppercase;
        }

        & .message-text {
          @include span-columns(14);
          @include pad(8px 10px);
          height: auto;
          white-space: normal;
          overflow: visible;
          text-overflow: normal;
          font-size: 12px;
        }
      }
    }

    .submitted {
      @include span-columns(14);
      @include pad(10px 10px 0);
      text-align: left;
      font-size: 10px;

      .reason {
        display: block;
      }
    }

    .department-text-container {
      display: flex;
    }

    .sentence {
      font-size: 12px;

      .value {
        color: $gray1;
        font-size: 12px;
      }
    }

    span.underline {
      text-decoration: underline;
    }

    span.department-name-overflow {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span.semi-large {
      color: $gray1;
      font-size: 13px;
    }

    span.large {
      color: $gray1;
      font-size: 14px;
    }

    span.uppercase {
      text-transform: uppercase;
    }

    span.dark {
      color: $gray1;
    }

    .details {
      @include span-columns(14);
      text-align: left;
      max-height: calc(100vh - 160px);
      overflow-y: auto;

      .sentence-container {
        @include span-columns(14);
        @include pad(10px);
      }

      .details-ext {
        @include span-columns(14);
        @include pad(5px 0 0);
      }
    }

    .additional-details {
      @include span-columns(14);
      @include pad(5px 10px 5px);

      .additional-details-title {
        @include pad(2px 5px 2px);
        display: inline-block;
        font-size: 13px;
        color: $white;
        background-color: $lightgray;
      }

      .additional-details-content {
        @include pad(2px 0px 2px 15px);
      }
    }

    .violation-details {
      @include span-columns(14);
      @include pad(5px 10px);

      .violation-title {
        @include pad(2px 5px 2px);
        display: inline-block;
        font-size: 13px;
        color: $white;
        background-color: $lightgray;
      }

      .violation-content {
        @include pad(2px 0px 2px 0px);
      }
    }

    .stages-container {
      @include span-columns(14);

      .stages-item {
        @include span-columns(14);
        @include pad(5px 10px);

        .stages-item-title {
          @include pad(2px 5px 2px);
          display: inline-block;
          font-size: 13px;
          color: $white;
          background-color: $lightgray;
        }

        .stages-item-subtext {
          @include span-columns(14);
          @include pad(0 0 5px 5px);
        }

        .stages-item-content {
          @include span-columns(14);
          @include pad(5px 0px);
        }
      }
    }

    .modifications {
      @include span-columns(14);
      @include pad(10px 10px);
      text-align: right;
      font-size: 10px;

      & .message {
        @include span-columns(13);
        @include shift(1);
        font-size: 10px;
        border-left: 0;
        border-right: 3px solid rgba(255, 191, 0, 0.35);
        text-align: left;

        i.fa-quote-left {
          font-size: 8px;
          padding-right: 2px;
          display: none;
        }

        i.fa-quote-right {
          font-size: 8px;
          padding-left: 2px;
          display: none;
        }
      }
    }
  }

  .footer {
    @include span-columns(14);
    @include shift(0);

    margin-top: 20px;
    padding: 10px 10px 10px;
    text-align: center;

    .edit-button {
      @include span-columns(3);
      margin: 0 auto;
      text-align: center;
      padding: 5px 5px;
      font-size: 14px;
      text-transform: uppercase;
      color: $gray1;

      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;

      & .fa {
        font-size: 17px;
        margin-right: 5px;
        display: none;
      }

      &:hover {
        cursor: pointer;
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;

        & .fa {
          color: $white;
        }
      }
    }

    .last-modified {
      @include span-columns(14);
      @include pad(10px 5px 5px);
      text-align: left;

      .history-link {
        margin-left: 10px;
        color: $lightblue;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          color: $darkblue;
        }
      }
    }
  }

  // statuses
  &.granted {
    border: 5px solid rgba(73, 185, 0, 0.6);

    .close {
      color: $darkgreen;

      &:hover {
        color: $darkdarkgreen;
      }
    }

    .mobile-section-nav {
      .mobile-section-nav-tab {
        color: $darkblue;

        &.active {
          background-color: $lightgreen;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header {
      background-color: rgba(73, 185, 0, 0.6);
      color: $green;
    }

    .tag {
      color: $green;
    }

    .details {
      @include lb-scrollbar(transparent, rgba(73, 185, 0, 0.3));
    }
  }

  &.denied {
    border: 5px solid $lightred;

    .close {
      color: $darkdarkred;

      &:hover {
        color: $darkdarkdarkred;
      }
    }

    .mobile-section-nav {
      .mobile-section-nav-tab {
        color: $darkblue;

        &.active {
          background-color: $darkred;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header {
      background-color: $lightred;
      color: $white;
    }

    .tag {
      color: $red;
    }

    .details {
      @include lb-scrollbar(transparent, rgba(255, 51, 51, 0.3));
    }
  }

  &.suspended {
    border: 5px solid rgba(62, 171, 255, 0.8);

    .close {
      color: $darkblue;

      &:hover {
        color: $darkdarkdarkblue;
      }
    }

    .mobile-section-nav {
      .mobile-section-nav-tab {
        color: $darkblue;

        &.active {
          background-color: $lightblue;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header {
      background-color: rgba(62, 171, 255, 0.8);
      color: $lightblue;
    }

    .tag {
      color: $lightblue;
    }

    .details {
      @include lb-scrollbar(transparent, rgba(62, 171, 255, 0.3));
    }
  }

  &.pending {
    border: 5px solid rgba(62, 171, 255, 0.8);

    .close {
      color: $darkblue;

      &:hover {
        color: $darkdarkdarkblue;
      }
    }

    .mobile-section-nav {
      .mobile-section-nav-tab {
        color: $darkblue;

        &.active {
          background-color: $lightblue;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header {
      background-color: rgba(62, 171, 255, 0.8);
      color: $lightblue;
    }

    .tag {
      color: $lightblue;
    }

    .details {
      @include lb-scrollbar(transparent, rgba(62, 171, 255, 0.3));
    }
  }

  &.deleted {
    border: 5px solid $lightpurple;

    .close {
      color: $darkdarkpurple;

      &:hover {
        color: $darkdarkdarkpurple;
      }
    }

    .mobile-section-nav {
      .mobile-section-nav-tab {
        color: $darkdarkpurple;

        &.active {
          background-color: $purple;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header {
      background-color: $lightpurple;
      color: $purple;
    }

    .tag {
      color: $purple;
    }

    .details {
      @include lb-scrollbar(transparent, rgba(144, 121, 211, 0.4));
    }
  }
}

@import "./responsive";
