@use "~@/common/scss/variables/theme";
@use "~@/common/scss/mixins/breakpoint";

.root {
  height: 530px;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint.down("xs") {
      height: unset;
    }

    .content-items {
      overflow: auto;
      height: 100%;
      padding: 24px;

      @include breakpoint.down("xs") {
        overflow: unset;
      }
    }
  }

  .preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid theme.$light-border;

    @include breakpoint.down("xs") {
      height: unset;
      border-left: none;
      border-top: 1px solid theme.$light-border;
    }

    .preview-items {
      overflow: auto;
      height: 100%;
      word-break: break-word;

      @include breakpoint.down("xs") {
        overflow: unset;
      }
    }
  }
}

.alert-box {
  position: relative;
  margin: 24px 0;

  .close-icon {
    position: absolute;
    color: theme.$darkgray;
    top: 5px;
    right: 5px;

    span {
      font-size: 16px;
    }
  }

  ul {
    padding-left: 18px;
  }
}

.warning-box {
  max-width: 475px;
  max-height: 200px;
  overflow: auto;
  margin-bottom: 26px;

  ul {
    padding-left: 0;
  }
}
