/* dialog styles */

@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.TimeRangeSlider {
  input[type="range"] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    position: absolute;
    left: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  input[type="range"]:focus {
    outline: none;
  }

  @mixin slider-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    box-shadow: transparent;
    background: transparent;
    border-color: transparent;
    border: 0px;
    color: transparent;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    @include slider-track;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: transparent;
  }
  input[type="range"]::-moz-range-track {
    @include slider-track;
  }
  input[type="range"]::-ms-track {
    @include slider-track;
  }

  // For the handle of the slider
  @mixin slider-thumb {
    height: 12.8px;
    width: 1px;
    -webkit-appearance: none;
    margin-top: -3.6px;
    border: none;
    background: transparent;
  }
  input[type="range"]::-webkit-slider-thumb {
    @include slider-thumb;
  }
  input[type="range"]::-moz-range-thumb {
    @include slider-thumb;
  }
  input[type="range"]::-ms-thumb {
    @include slider-thumb;
  }

  // IE Specific Range Styles
  input[type="range"]::-ms-tooltip {
    display: none;
  }
  input[type="range"]::-ms-fill-lower {
    background: transparent;
  }
  input[type="range"]::-ms-fill-upper {
    background: transparent;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: transparent;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: transparent;
  }

  .slider {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-family: Arial, sans-serif;
    height: 60px;
    width: 93%;
    left: 3.5%;

    .slider-line {
      top: 25px;
      height: 12px;
      border-radius: 4px;
      position: relative;
      display: block;
      overflow: hidden;
      outline: none !important;
      background-color: #2cc9a2;
    }

    .slider-bar {
      top: 25px;
      height: 12px;
      position: absolute;
      display: block;
      left: 0;
      width: 0;
      background-color: #2cc9a2;

      &.single-split {
        border-right: 4px solid #fff;
      }

      &.multi-split {
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
      }
    }

    .slider-min-label,
    .slider-max-label {
      top: 0;
      padding: 1px 3px;
      color: #080e33;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      background-color: #e1e4e9;
      border-radius: 4px;
      position: absolute;
      display: block;
      cursor: default;
    }

    .slider-min-label {
      left: 0;
    }

    .slider-max-label {
      right: 0;
    }

    .slider-left-label,
    .slider-right-label,
    .slider-single-label {
      color: white;
      font-size: 10px;
      line-height: 1.333;
      text-shadow: none;
      padding: 1px 5px;
      background-color: #ed5565;
      border-radius: 2px;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      cursor: default;
      white-space: nowrap;
    }
    .slider-single-label {
      transform: translateX(-50%);
    }
    .slider-left-label {
      transform: translateX(-85%);
    }
    .slider-right-label {
      transform: translateX(-15%);
    }

    .slider-left-label:before,
    .slider-right-label:before,
    .slider-single-label:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -6px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -3px;
      overflow: hidden;
      border: 3px solid transparent;
      border-top-color: #ed5565;
    }
    .slider-left-label:before {
      left: 80%;
    }
    .slider-right-label:before {
      left: 20%;
    }

    .slider-grid {
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
    }

    .slider-grid-pol {
      background-color: #e1e4e9 !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 8px;
      background: #000;

      &.small {
        height: 4px;
      }
    }
  }
}
