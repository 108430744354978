@import "../_lib/ui/styles/variables";

:root {
  --border: 10px solid;
  --separation: 10px;
}

.tooltipContainer {
  position: relative;
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.tooltipContent {
  color: $gray3;
  position: absolute;
  z-index: 1000;
  font-size: 14px;
  cursor: default;
  border-radius: 5px;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.16);
  animation: fadeIn ease-in-out 250ms;
  background-color: $white;
  border: 1px solid $lightgray;
  padding: 5px 10px;

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
  }

  &.top {
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    .arrow {
      bottom: -10px;
      left: calc(50% - 10px);
      border-right: var(--border) transparent;
      border-top: var(--border) $lightgray;
      border-left: var(--border) transparent;
    }
  }

  &.right,
  &.left {
    top: 50%;
    transform: translateY(-50%);

    .arrow {
      top: calc(50% - 10px);
      border-top: var(--border) transparent;
      border-bottom: var(--border) transparent;
    }
  }

  &.right {
    left: calc(100% + 12px);

    .arrow {
      left: -10px;
      border-right: var(--border) $lightgray;
    }
  }

  &.left {
    right: calc(100% + 12px);

    .arrow {
      right: -10px;
      border-left: var(--border) $lightgray;
    }
  }

  &.bottom {
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    .arrow {
      top: -10px;
      left: calc(50% - 10px);
      border-right: var(--border) transparent;
      border-bottom: var(--border) $lightgray;
      border-left: var(--border) transparent;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
