/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

.Dialog.SplitShift {
  width: 50%;
  min-width: 320px;
  min-height: 550px;
  overflow-y: scroll;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 32px;
  margin: 0 auto;

  .shift-info {
    .row {
      display: table;
      width: 100%;

      div {
        display: table-cell;
        width: 50%;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .split-text {
    color: #080e33;
    display: block;
  }

  .split-time-text-container {
    margin-bottom: 4px;

    span {
      width: 84px;
      display: inline-block;
      color: #080e33;

      &:last-child {
        padding-left: 2px;
      }
    }
  }

  .note-label {
    margin-bottom: 5px;
    color: #080e33;
  }

  textarea {
    resize: vertical;
    width: 100%;
    max-width: 100%;
    padding: 6px 10px 36px;
    background-color: rgb(225, 228, 233);
    border-radius: 12px;
    border: none;
    outline: none;
    height: 40px;
    max-height: 120px;
    color: #080e33;
  }

  .personelDropDown {
    display: inline-block;
    vertical-align: middle;

    .editables.ypad {
      color: #080e33;
      display: inline-block;
      width: 100%;
      padding: 0;

      .selectable.two-line {
        padding: 0;
        max-width: 300%;

        .selectable-label {
          display: none;
        }

        .selectable-value {
          text-decoration: none;
          font-size: 12px;
          padding: 4px 2px;
        }
      }
    }
  }

  .bottom-controls {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;

    .right-side {
      position: absolute;
      right: 32px;
      height: 100%;

      .submit {
        background-color: #2cc9a2;
        color: #121212;
        text-transform: none;
        border-radius: 32px;
        width: 120px;
        text-align: center;

        .fa-check {
          display: none;
        }
      }

      span.submit:hover {
        background-color: #2ac09b;
      }
    }
  }

  // Desktop Screen Specific
  @media screen and (min-width: 768px) {
    .split-text {
      color: #080e33;
      padding: 25px 0;
    }

    .buttons {
      position: absolute;
      right: 0px;
      top: 90px;
    }

    .split-warning-dialog {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 16vh;
      color: #000;
      font-size: 1.3em;
      letter-spacing: 1px;
    }

    .personelDropDown {
      .editables.ypad {
        .selectable.two-line {
          background-color: #f8f8f8;
          background-image: linear-gradient(0deg, white, #f8f8f8);
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 0px 8px;
          min-width: 200px;

          &:hover {
            background-color: #ddd;
            background-image: none;
          }
        }
      }
    }

    .bottom-controls {
      .right-side {
        width: 240px;

        .submit {
          right: 0;
          position: absolute;
        }

        .cancel {
          width: 80px;
          text-align: center;
          height: 38px;
          line-height: 38px;
          cursor: pointer;
          color: #ed5565;
        }
      }
    }
  }

  // Mobile Screen Specific
  @media screen and (max-width: 767px) and (min-width: 0) {
    height: 45%;
    padding: 12px 16px 16px;

    .mobile-section-nav-tab.active {
      font-size: 18px;
    }

    .TimeRangeSlider {
      display: none;
    }

    .split-text {
      margin-left: unset;
      padding: 16px;
    }

    .personelDropDown {
      .editables.ypad {
        .selectable.two-line {
          min-width: unset;
        }
      }
    }

    .bottom-controls {
      position: unset;
      margin-top: 20px;
      .right-side {
        position: unset;
        right: unset;
        width: unset;
        height: unset;

        .submit {
          margin: 0 auto;
          width: 240px;
        }

        .cancel {
          width: 240px;
          border: 1px solid #ccc;
          margin: 10px auto 20px;
          text-align: center;
          border-radius: 32px;
          height: 36px;
          line-height: 34px;
          color: #ed5565;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
