@import "bourbon.scss";
@import "neat.scss";
@import "grid-settings";
@import "variables";
@import "mixins";

.Subscription {
  width: 100%;
  margin-bottom: 5px;

  & > .title {
    background-color: $darkdarkblue;
    color: $white;
    font-size: 16px;
    padding: 9px 10px;
    text-transform: uppercase;

    & > i {
      font-size: 24px;
      margin-right: 10px;
      padding-top: 1px;
    }
  }

  .content-item {
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.02);

    .desc {
      font-size: 12px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(38, 148, 232, 0.15);
    }
  }
}
