/* dialog styles */

@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.TimeRangePicker {
  //margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 16px;

  .error-message {
    background-color: rgb(255, 205, 210);
    display: block;
    padding: 5px 10px;
    margin-right: 32px;
    margin-bottom: 24px;
    margin-top: 12px;
    border-radius: 8px;
  }

  .TimePicker {
    display: inline-block;
    padding: 0 5px;
    font-family: "Open Sans", "Lucida Grande", "Lucida Sans", "Arial", "sans-serif";
    font-size: 12px;
    color: #666;
    text-align: center;
    vertical-align: middle;
    margin-right: 20px;

    .picker-text-input-container {
      width: 50px;
      height: 28px;
      font-size: unset;
      text-align: unset;
      line-height: unset;
      vertical-align: unset;
    }

    input {
      padding: 4px 18px 4px 3px;
      width: 49px;
      text-align: center;
      font-size: 12px;
      border: 1px solid #888;
      display: inline-block;
      border-radius: 3px;
      height: 28px;
    }

    .HourPicker,
    .MinutePicker,
    .AMPMPicker {
      position: relative;

      .fa-angle-up {
        display: block;
        transition: all 0.15s ease-in;
        height: 14px;
        border: 1px solid #888;
        font-size: 12px;
        line-height: 12px;
        padding: 0 4px;
        width: 16px;
        border-radius: 0px 3px 0px 0;
        right: 2px;
        position: absolute;
      }

      .fa-angle-down {
        display: block;
        transition: all 0.15s ease-in;
        height: 14px;
        border: 1px solid #888;
        font-size: 12px;
        line-height: 12px;
        padding: 0 4px;
        width: 16px;
        right: 2px;
        position: absolute;
        border-radius: 0px 0px 3px 0;
        bottom: 2px;
      }
    }

    .AMPMPicker {
      padding: 4px 10px;
      width: 49px;
      text-align: center;
      font-size: 12px;
      border: 1px solid #888;
      display: inline-block;
      border-radius: 3px;
      height: 28px;
      background-image: linear-gradient(0deg, #eee, #fff);

      .fa-angle-down {
        left: 0;
        top: 0;
        width: 49px;
        height: 28px;
        border-radius: 3px;
        bottom: unset;
        right: unset;
        opacity: 0;
        display: block;
        transition: all 0.15s ease-in;
        border: 1px solid #888;
        font-size: 12px;
        line-height: 12px;
        padding: 0 4px;
        position: absolute;
      }

      .fa-angle-up {
        display: none;
      }
    }

    .AMPMPicker {
      padding: 4px 18px 4px 3px;
      width: 49px;
      text-align: center;
      font-size: 12px;
      border: 1px solid #888;
      display: inline-block;
      border-radius: 3px;
      height: 28px;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 0) {
    .TimePicker {
      .picker-text-input-container {
        display: inline-block;
        margin: 0;
        top: 0px;
      }

      input {
        padding: 0;
        border-radius: 0;
        margin: 0;
        width: 32px;
      }

      .HourPicker,
      .MinutePicker,
      .AMPMPicker {
        .fa-angle-up {
          border-radius: 0px 3px 3px 0px;
          height: 28px;
          line-height: 26px;
          width: 24px;
          position: relative;
          display: inline-block;
          right: unset;
          border-collapse: collapse;
          border-left: none;
          left: 65px;
          top: 0px;
        }

        .fa-angle-down {
          border-radius: 3px 0 0 3px;
          height: 28px;
          line-height: 26px;
          width: 24px;
          position: relative;
          display: inline-block;
          right: unset;
          bottom: unset;
          border-collapse: collapse;
          left: -65px;
          top: 0px;
          border-right: none;
        }
      }

      .AMPMPicker {
        .fa-angle-down {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 49px;
        }

        .fa-angle-up {
          display: none;
        }
      }
    }
  }
}
