@import "variables";
@import "grid-settings";
@import "mixins";

@import "../../../styles";

$img_holiday: url("../../../../../../../_lib/ui/images/holiday_icon.svg");

.left-column-items {
  .fixed-cell {
    padding-top: 3px;
    padding-left: 3px;
    text-overflow: clip !important;
    white-space: nowrap;
  }

  display: block;

  .group-banner--requests {
    width: 100%;
    display: block;
    background: repeating-linear-gradient(-55deg, #fff9d6, #fff9d6 2%, #fffceb 2%, #fffceb 4%);
  }

  &__tally-header {
    display: block;
    width: 100%;
    height: 20px;
    font-size: 12px;
    background: repeating-linear-gradient(-55deg, #ffeae8, #ffeae8 2%, #fff1f0 2%, #fff1f0 4%);
    color: black;
    border-bottom: $grid_normalborder;
  }

  .header {
    display: block;
    width: 100%;

    &__left-column {
      display: block;
      width: 100%;
      height: 23px !important;
      font-size: unset !important;
      padding: 2px 10px;
      text-transform: uppercase;
      background-color: $grid_headertype_color;
      color: $white;

      &--condensed {
        display: block;
        width: 100%;
        padding: 2px;
        text-align: left;
      }
    }
  }

  .print-request-leftcol {
    .fixed-cell {
      background-color: rgba(241, 232, 181, 0.8) !important;
    }
  }

  .column-print-header-wrapper {
    display: block;
    width: 100%;
    text-overflow: clip !important;

    @media print {
      break-inside: avoid !important;
      break-before: auto !important;
    }

    .tally-name-wrapper {
      display: block;
      width: 100%;
      height: 22px;

      .fixed-cell {
        display: block;
        width: 100%;
        height: 22px;
        background-color: #fee5e2;
        border-right: $grid_normalborder;
        border-bottom: $grid_normalborder;
      }
    }

    .row {
      height: 100%;
      display: block;
      width: 100%;
      text-overflow: clip !important;

      .fixed-cell {
        &--condensed {
          text-overflow: clip !important;
          white-space: nowrap;
          width: 75px;
        }

        display: block;
        height: 100%;
        border-bottom: $grid_normalborder;
        border-right: $grid_normalborder;
      }

      &--even {
        background-color: $grid_evenrow_color;

        .fixed-cell {
          background-color: #f3f1f1;
        }
      }

      &--odd {
        background-color: $grid_darkrow_color;

        .fixed-cell {
          background-color: #dbd9d9;
        }
      }
    }
  }

  .request-row-member {
    .row {
      &--odd {
        .fixed-cell {
          background-color: rgba(241, 232, 181, 0.98);
        }
      }

      &--even {
        .fixed-cell {
          background-color: rgba(248, 238, 182, 0.98);
        }
      }
    }
  }
}

.column-items {
  display: block;
  font-size: clamp(6px, 8px, 10px) !important;

  .fixed-cell {
    padding-top: 3px;
    padding-left: 3px;
  }

  @media print {
    break-inside: avoid !important;
    break-before: always !important;
    font-size: clamp(6px, 8px, 10px) !important;
  }

  .print-request {
    .contextual-clues {
      height: unset !important;
      background: transparent !important;
    }
  }

  .times {
    font-size: clamp(6px, 8px, 10px) !important;
  }

  &__tally-header {
    display: block;
    width: 100%;
    height: 20px;
    font-size: clamp(6px, 8px, 10px) !important;
    border-bottom: $grid_normalborder;
    background: repeating-linear-gradient(-55deg, #ffeae8, #ffeae8 2%, #fff1f0 2%, #fff1f0 4%);
  }

  .column-items-header {
    display: block;
    width: 100%;
    font-size: clamp(6px, 8px, 10px) !important;

    @media print {
      break-inside: avoid !important;
      break-before: auto !important;
    }

    .cell {
      display: block;
      justify-content: space-between;
      padding: 2px 5px;
      text-align: center;
      width: 100%;
      height: 23px;
      color: $white;
      font-size: clamp(6px, 8px, 10px) !important;
      background-color: $grid_headerweekday_color;
      border-left: 1px solid $lightblue;

      &:first-child {
        border-left: none;
      }

      &:hover {
        cursor: pointer;
        padding-bottom: 0px;
        border-bottom: 2px solid $darkblue;
      }

      &__header--container {
        display: flex;
        justify-content: space-around;

        span {
          margin: 0 auto;
        }
      }

      &--condensed {
        display: block;
        width: 100%;
        text-align: center;
        text-wrap: none;
        white-space: nowrap;
      }

      &--today {
        background-color: $grid_today_color;
      }

      &--weekend {
        background-color: $grid_headerweekend_color;

        &.cell--notInSchedule {
          background-color: rgba(168, 168, 168, 0.7);
        }
      }

      &--notInSchedule {
        background-color: rgba(188, 188, 188, 0.7);
        border-left: 1px dotted #ababab;
      }
    }
  }

  .row {
    display: block;
    width: 100%;

    @media print {
      break-inside: avoid !important;
      break-before: auto !important;
    }

    &--even {
      background-color: $grid_evenrow_color;

      .fixed-cell {
        background-color: #f3f1f1;
      }
    }

    &--odd {
      background-color: $grid_darkrow_color;

      .fixed-cell {
        background-color: #dbd9d9;
      }
    }

    .cell {
      &--condensed {
        text-overflow: clip !important;
        white-space: nowrap;
      }
    }
  }

  .group-banner--requests {
    width: 100%;
    display: block;
    background: repeating-linear-gradient(-55deg, #fff9d6, #fff9d6 2%, #fffceb 2%, #fffceb 4%);
  }

  .column-print-cell-wrapper {
    text-overflow: ellipsis;
    border-bottom: $grid_normalborder;
    border-right: $grid_normalborder;
    display: block;
    width: 100%;

    @media print {
      break-inside: avoid !important;
      break-before: auto !important;
    }

    &--tally {
      display: block;
      width: 100%;

      @media print {
        break-inside: avoid !important;
        break-before: auto !important;
      }

      .tally-cell {
        width: 100%;
        display: block;
        height: 22px;
        text-align: center;
        border-bottom: $grid_normalborder;
        border-right: $grid_normalborder;
        background-color: #fee5e2;

        @media print {
          break-inside: avoid !important;
          break-before: auto !important;
        }
      }
    }

    &--condensed {
      display: block;
      min-width: 44px;
      max-width: 44px;
    }

    .slot,
    .tally {
      display: block;
      text-align: center;
      line-height: 17px !important;

      &--times-shown {
        line-height: 14px !important;
      }

      &--condensed {
        display: block;
        text-align: left;
      }

      &--over {
        color: #679f00 !important;
      }

      &--under {
        color: #ff3333 !important;
      }

      i.fa-star-o {
        position: absolute;
        color: $lightgray;
        font-size: 10px;
        top: 2px;
        left: 2px;
      }

      span.text {
        display: inline-block;
        padding: 2px 0px;
        width: 100%;
        text-overflow: ellipsis;

        &:hover {
          background-color: $postit;
          color: black;
          cursor: pointer;
        }

        &.highlighted {
          background-color: $postit;
        }
      }

      .slot-personnel-container {
        @include slot-personnel-container;
      }

      span.times {
        display: block;
        font-size: 11px;
        font-style: italic;
      }

      div.contextual-clues {
        position: absolute;
        height: unset !important;
        height: 100%;
        display: flex;
        margin-bottom: 2px;
        right: 0px;
        top: 0px;
        pointer-events: none;
        background: transparent !important;

        &.show-times {
          height: 60%;
        }

        div.contextual-clue {
          width: 5px;
          height: 5px;
          margin: 0 0 0 1px;

          &.hasNote {
            background-color: $noteindicator_color;
          }

          &.isPending {
            background-color: $pendingindicator_color;
          }

          &.isDenied {
            background-color: $deniedindicator_color;
          }

          &.isDeleted {
            background-color: $deletedindicator_color;
          }

          &.isWebRequest {
            background-color: $requestindicator_color;
          }
        }
      }

      &.Placeholder {
        font-size: 10px;
        font-style: italic;
        background-color: #ffe8e8;
        color: #ff9c9c;
      }

      &.Status {
        font-size: 10px;
        color: #b6d5af;

        &.full {
          color: #ffcccc;
        }

        &.personnel {
          font-size: 6px;
        }
      }

      @include transition(background-color 0.2s ease-in 0s, color 0.2s ease-in 0s);

      &.hidden {
        display: none;
      }

      &.trans-hover {
        & span.text {
          background-color: transparent !important;
          color: $white !important;
        }
      }

      // error classes
      &.compat {
        @include animation-name(compat_throb);
        @include animation-duration(2s);
        @include animation-timing-function(linear);
        @include animation-iteration-count(infinite);
      }

      &.demand {
        @include animation-name(demand_throb);
        @include animation-duration(2s);
        @include animation-timing-function(linear);
        @include animation-iteration-count(infinite);
      }

      &.role {
        @include animation-name(role_throb);
        @include animation-duration(2s);
        @include animation-timing-function(linear);
        @include animation-iteration-count(infinite);
      }

      &.transparent {
        background-color: transparent !important;
      }

      &.mapped {
        color: $lightgray;
      }
    }

    & .tally .text {
      &:hover {
        background-color: inherit !important;
        color: inherit !important;
        cursor: default !important;
      }
    }

    // error classes
    &.demand {
      @include animation-name(demand_throb);
      @include animation-duration(2s);
      @include animation-timing-function(linear);
      @include animation-iteration-count(infinite);
    }

    &.notInSchedule {
      color: $lightgray !important;

      .slot {
        .text {
          &:hover {
            background-color: inherit;
            cursor: text;
          }
        }
      }
    }
  }
}
