/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

.RequestsList {
  height: 100%;
  @include outer-container;
  @include pad(5px 5px 5px 5px);

  .header {
    @include span-columns(14);
    color: $gray1;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
    position: relative;

    & .new {
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      color: $viewer_black;
      padding: 2px 5px;
      font-size: 14px;
      float: right;
      margin-top: -3px;

      > i {
        padding-right: 2px;
      }

      &:hover {
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;
        cursor: pointer;
      }
    }

    & .tab {
      padding: 5px 10px;
      display: inline-block;
      color: $gray1;

      &:hover {
        background-color: $lightlightgray;
        cursor: pointer;
      }

      &.active {
        background-color: $lightlightlightgray;
        color: $gray1;
      }
    }

    .sub-header {
      @include span-columns(14);
      @include pad(5px 5px);
      background-color: $lightlightlightgray;
      color: $white;

      .filter {
        @include span-columns(2);
        color: $viewer_black;

        i {
          display: inline-block;
          padding: 7px 5px 5px;
          font-size: 20px;

          &:hover {
            background-color: $lightlightgray;
            cursor: pointer;
          }
        }

        .controls {
          position: relative;

          .Options {
            position: absolute;
            left: -2px;
            top: 2px;
            width: 250px;
            min-height: 200px;
            z-index: 2;
            background-color: rgba(255, 255, 255, 1);
            @include flydown-shadow;
            padding: 10px;
            display: none;

            .title {
              width: 100%;
              font-size: 12px;
              border-bottom: 1px solid $lightlightgray;
              padding: 0px 10px;
            }

            .values {
              font-size: 12px;
              padding: 5px 0px;

              .item {
                display: inline-block;

                span.text {
                  padding: 2px 10px;
                }

                .date {
                  .DatePicker {
                    position: absolute;
                    left: 20px;
                    top: 20px;
                    width: 250px;
                    min-height: 200px;
                    z-index: 2;
                    background-color: rgba(255, 255, 255, 1);
                    @include flydown-shadow;
                    padding: 5px;
                    display: none;

                    .DatePickerControls {
                      font-size: 12px;

                      i {
                        font-size: 14px;
                      }
                    }

                    .DatePickerCalendarHeader {
                      display: none;
                    }
                  }

                  &.active {
                    .DatePicker {
                      display: block;
                    }
                  }
                }

                &:hover {
                  background-color: $lightlightlightgray;
                  cursor: pointer;
                }

                &.active {
                  background-color: $lightblue;
                  color: $white;
                }
              }
            }

            .list-item {
              padding: 5px 10px;
              font-size: 12px;
              text-transform: uppercase;
              color: $viewer_black;

              &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.1);
              }

              &.inactive {
                color: $lightgray;
              }

              & i {
                padding-right: 10px;
              }
            }

            &:before {
              content: "";
              @include triangle(12px, #fff, up);
              position: absolute;
              top: -6px;
              left: 8px;
            }
          }

          & .submit {
            background-color: $lightlightlightgray;
            border: 1px solid $lightlightgray;
            color: $viewer_black;
            padding: 2px 5px;
            font-size: 12px;
            margin-top: 20px;
            text-align: center;

            > i {
              display: none;
              padding-right: 2px;
            }

            &:hover {
              background-color: $lightblue;
              border: 1px solid $darkblue;
              color: $white;
              cursor: pointer;
            }

            &.disabled {
              background-color: $extremelightgray;
              border: 1px solid $lightlightlightgray;
              color: $lightgray;

              &:hover {
                background-color: $extremelightgray;
                border: 1px solid $lightlightlightgray;
                color: $lightgray;
                cursor: default;
              }
            }
          }

          &.active {
            .Options {
              display: block;
            }
          }
        }
      }

      .search {
        @include span-columns(12);
        text-align: right;

        i {
          font-size: 16px;
          background-color: $extremelightgray;
          border-right: none;
          color: $viewer_black;
          padding: 8px 10px 8px 10px;
        }

        input {
          border: none;
          height: 32px;
          padding: 10px;
          font-size: 12px;
          vertical-align: top;
          color: $viewer_black;
          width: 202px;
          background-color: $extremelightgray;

          &:focus {
            outline: none;
            background-color: $lightlightgray;
          }
        }
      }
    }
  }

  .loader {
    @include span-columns(14);
    text-align: left;
    margin-top: 20px;
    margin-left: 5px;

    i {
      font-size: 24px;
    }
  }

  .items-container {
    @include span-columns(14);
    @include calc(height, "100% - 75px");
    margin-top: 2px;
    overflow-y: scroll;

    .empty {
      @include span-columns(14);
      text-align: left;
      padding: 10px;
    }

    .items-date-group-container {
      @include span-columns(14);

      .date-header {
        @include pad(5px 10px);
        margin-right: 5px;
        text-transform: uppercase;
        text-align: right;
        color: $gray1;
        background-color: $lightlightlightgray;
      }
    }

    .Item {
      @include span-columns(14);
      margin-right: 5px;
      padding: 5px 10px 5px 45px;
      border-left: 1px solid $lightlightlightgray;
      border-bottom: 1px solid $lightlightlightgray;
      position: relative;

      div.type {
        @include span-columns(1);
        text-align: center;
        min-height: 20px;
      }

      div.icon {
        position: absolute;
        top: 7px;
        left: 5px;

        .fa-stack {
          margin-right: 0px;
          vertical-align: top;

          &.pending {
            float: right;
          }
        }

        i {
          color: $lightgray;

          &.fa-square {
            color: $lightlightlightgray;
          }

          &.fa-paper-plane {
            color: $lightgray;
          }

          &.fa-question {
            color: $lightblue;
          }

          &.fa-thumbs-o-up {
            color: $green;
          }

          &.fa-thumbs-o-down {
            color: red;
          }
        }
      }

      div.info {
        @include span-columns(14);
      }

      div.actions {
        text-align: right;
        @include span-columns(2);
        display: none;

        i.fa-trash-o {
          font-size: 18px;
          height: 100%;
          padding: 5px;

          &:hover {
            color: $gray1;
          }
        }
      }

      span.date {
        font-size: 12px;
        display: block;
      }

      span.assignment {
        color: $gray1;

        i {
          padding-right: 5px;
        }
      }

      span.message {
        margin-top: 4px;
        vertical-align: middle;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 5px 10px 5px 20px;
      }

      span.command {
        font-size: 12px;
        text-transform: lowercase;
      }

      span.status {
        @include span-columns(14);
        padding-top: 0px;

        span.out,
        span.in {
          @include span-columns(14);
        }

        i {
          font-size: 12px;

          &.fa-exchange {
            padding-left: 5px;
          }
        }
      }

      span.delete {
        vertical-align: middle;
        display: inline-block;
        padding: 10px;
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }
      }

      &.granted {
        div.indicator {
          height: 32px;
        }

        div.icon {
          i {
            &.fa-square {
              color: $green;
            }

            &.fa-thumbs-o-up {
              color: $white;
            }
          }
        }
      }

      &.denied {
        div.indicator {
          height: 32px;
        }

        div.icon {
          i {
            &.fa-square {
              color: rgba(255, 0, 0, 0.75);
            }

            &.fa-thumbs-o-down {
              color: $white;
            }
          }
        }
      }

      &.suspended {
        div.indicator {
          height: 32px;
        }

        div.icon {
          i {
            &.fa-square {
              color: $lightblue;
            }

            &.fa-thumbs-o-up {
              color: $white;
            }
          }
        }
      }

      &.pending {
        div.indicator {
          height: 32px;
        }

        div.icon {
          i {
            &.fa-square {
              color: $lightblue;
            }

            &.fa-question {
              color: $white;
            }
          }
        }
      }

      &.deleted {
        div.indicator {
          height: 32px;
        }

        div.icon {
          i {
            &.fa-square {
              color: $purple;
            }

            &.fa-trash {
              color: $white;
            }
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    @include lb-scrollbar($lightlightlightgray, $lightlightgray);
  }
}
