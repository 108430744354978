/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.SlotEdit,
.Dialog.RequestEdit {
  margin-left: -140px;

  .content {
    .actions {
      .button {
        width: 220px;
      }
    }
  }
}

.SlotEdit,
.RequestEdit {
  width: 280px;
  height: auto;
  top: 40%;

  .content {
    position: relative;
    height: 100%;
    @include span-columns(14 of 14);

    .fa-stack {
      margin-right: 10px;
      width: 18px;
      height: 15px;
      font-size: 14px;
      vertical-align: middle;
      position: relative;
      display: inline-block;
      line-height: 15px;
    }

    .header {
      @include span-columns(12);
      margin-left: 5px;
      color: $gray1;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      padding: 5px 5px 5px 5px;
      border-bottom: 1px solid #dbdbdb;
      position: relative;
    }

    .actions {
      @include span-columns(14);
      @include pad(10px);

      .empty {
        font-size: 12px;
        padding: 3px 5px;
        text-transform: uppercase;
      }

      .button {
        margin: 2px 0px;
        padding: 8px 5px;
        font-size: 12px;
        text-transform: uppercase;
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;

        i {
          margin-right: 10px;
          font-size: 14px;
        }

        &:hover {
          cursor: pointer;
          background-color: $lightblue;
          border: 1px solid $darkblue;
          color: $white;
        }
      }
    }
  }
}
