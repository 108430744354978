@use "~@/common/scss/variables/theme";

div[class*="MuiPickersClock-pin"],
div[class*="MuiPickersClockPointer-pointer"] {
  background-color: theme.$darkblue !important;
}

div[class*="MuiPickersClockPointer-thumb"] {
  border-color: theme.$darkblue !important;
}

div[class*="MuiPickersClockPointer-noPoint"] {
  background-color: theme.$darkblue !important;
}

.root {
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;

  .input {
    div[class*="MuiInputBase-root"] {
      padding: 0;

      input[class*="MuiInputBase-input"] {
        font-family: theme.$font;
        position: relative;
        height: initial;
        border: 1px solid theme.$light-border;
        color: theme.$black;
        background-color: theme.$white;
        font-size: 14px;
        line-height: 1.6;
        border-radius: 2px;
        padding: 7px 11px;
        transition: all 0.3s;

        &::placeholder {
          color: theme.$black;
          opacity: 0.4;
        }

        &:placeholder-shown {
          text-overflow: ellipsis;
        }

        &:hover {
          border-color: theme.$lightblue;
        }

        &:focus {
          border-color: theme.$lightblue;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          outline: 0;
        }
      }

      div[class*="MuiInputAdornment-root"] {
        position: absolute;
        right: 10px;
      }

      button[class*="MuiIconButton-root"] {
        padding: 6px;

        svg {
          height: 20px;
          width: 20px;
        }
      }

      fieldset[class*="MuiOutlinedInput-notchedOutline"] {
        display: none;
      }
    }
  }

  .error {
    div[class*="MuiInputBase-root"] {
      input[class*="MuiInputBase-input"] {
        border-color: theme.$dark-error;

        &:hover {
          border-color: theme.$dark-error;
        }

        &:focus {
          border-color: theme.$dark-error;
          box-shadow: 0 0 0 2px rgba(226, 104, 104, 0.2);
        }
      }
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  .input {
    div[class*="MuiInputBase-root"] {
      input[class*="MuiInputBase-input"] {
        border-color: theme.$light-border !important;
      }
    }
  }
}
