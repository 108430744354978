@import "bourbon.scss";
@import "neat.scss";
@import "grid-settings";
@import "variables";
@import "mixins";

$img_logotile: url("../../../../_lib/ui/images/logo_tile_login.png");
$img_pslogotile: url("../../../../_lib/ui/images/ps-lb-logo-240.png");

#SplashScreen {
  @include outer-container;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @include pad(50px 0 50px);

  @include radial-gradient($lightblue, $darkdarkdarkblue);

  & .splash-container {
    @include span-columns(14);
  }

  & .splash-content {
    @include span-columns(14);
  }

  & .form-container {
    @include span-columns(14);
  }

  #logo-tile {
    margin: 0 auto 20px;
    width: 152px;
    height: 152px;
    background-image: $img_logotile;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 152px 152px;
    -webkit-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.35);
  }

  #ps-logo-tile {
    margin: 0 auto 20px;
    width: 152px;
    height: 152px;
    background-image: $img_pslogotile;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 152px 152px;
    -webkit-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.35);
  }

  #desc {
    font-size: $smallheadersize;
    color: $white;
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
  }

  #login_form {
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    width: 300px;
    margin: 20px auto 15px;
    position: relative;

    .login-field {
      border: 1px solid $darkblue;
      height: 50px;
      width: 100%;
      margin: 3px 0px 0px 0px;
      padding: 10px;
      font-size: $medheadersize;
      background-color: $extremelightgray;
      color: $darkblue;
      border-radius: 5px;

      &:focus {
        outline: none;
        border: 1px solid $gray1;
        background-color: $lightlightlightgray;
        color: $gray1;
      }
    }

    #login-message {
      display: none;
    }
  }

  #submit_button {
    font-size: 12px;
    color: $white;
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  #forgot_button,
  #password_button,
  #enterprise_button {
    font-size: 12px;
    color: $white;
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .login-button {
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 10px;
    width: 100px;
    margin: 2px auto;
    position: relative;
    padding: 20px 20px;

    &:hover {
      cursor: pointer;
      background-color: $darkdarkdarkblue;
    }
  }

  .sso-button {
    text-align: center;
    padding: 2px;
    width: 200px;
    margin: 20px auto 2px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .forgot-button {
    text-align: center;
    padding: 2px;
    width: 200px;
    margin: 20px auto 2px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  #login_text {
    font-size: $bodytextsize;
    text-align: center;
    width: 220px;
    margin: 0 auto;
    color: $white;
    font-style: italic;
  }

  .popup-message {
    position: fixed;
    bottom: 50px;
    width: 100%;
    text-transform: uppercase;
    display: block;
    background-color: rgba(255, 255, 255, 1);
    color: $white;
    @include dialog_shadow;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    animation: slide 0.5s ease-out;
  }
}

@keyframes slide {
  0% {
    margin-left: -5000px;
  }

  100% {
    margin-left: 0;
  }
}
