@mixin top-icon-shadow {
  -webkit-box-shadow: inset 0px 1px 12px 0px rgba(0, 0, 0, 0.85);
  -moz-box-shadow: inset 0px 1px 12px 0px rgba(0, 0, 0, 0.85);
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.85);
}

@mixin menu-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

@mixin top-menu-shadow {
  -webkit-box-shadow: inset 0px 3px 12px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: inset 0px 3px 12px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

@mixin up-shadow {
  -webkit-box-shadow: inset 0px -3px 12px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: inset 0px -3px 12px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.25);
}

@mixin flydown-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

@mixin contextmenu-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}

@mixin dialog-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

@mixin subnav-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.45);
}

@mixin fab-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.45);
}

@mixin popup-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

@mixin title-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

@mixin toolicon-shadow {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}
@mixin toolicon-shadow2 {
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
@mixin explorer-left-header-shadow {
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 7px 10px 1px rgba(0, 0, 0, 0.15);
}
@mixin dialog-tab-shadow {
  -webkit-box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 1px 5px 0px rgba(0, 0, 0, 0.35);
}

@mixin pendingslot-background($img) {
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: $img;
}

@mixin lb-scrollbar($background_color, $scrollbar_color) {
  &::-webkit-scrollbar {
    background: $background_color;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar_color;
  }
  &::-webkit-resizer,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner {
    display: none;
  }
}

// Shims for deprecated Bourbon/Neat mixins

@mixin transform($args...) {
  transform: $args;
}

@mixin animation($args...) {
  animation: $args;
}

@mixin animation-iteration-count($args...) {
  animation-iteration-count: $args;
}

@mixin animation-name($args...) {
  animation-name: $args;
}

@mixin animation-duration($args...) {
  animation-duration: $args;
}

@mixin animation-timing-function($args...) {
  animation-timing-function: $args;
}

@mixin transition($args...) {
  transition: $args;
}

@mixin hidpi($args) {
  @content;
}

@mixin calc($attr, $expr) {
  #{$attr}: calc(#{$expr});
}

@mixin triangle($size, $_2, $_3) {
}

@mixin radial-gradient($args...) {
  background: radial-gradient($args);
}

@mixin linear-gradient($args...) {
  background: linear-gradient($args);
}

@mixin keyframes($it) {
  @content;
}

@mixin slot-personnel-container {
  display: block;

  .original-personnel-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2px;

    .original-personnel {
      line-height: 7px;
      font-style: italic;
      opacity: 0.8;
    }
  }
}

@mixin department-name {
  display: block;
  font-size: 11px;
  line-height: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 5px;
}
