/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.TimePicker {
  display: inline-block;
  padding: 0px 5px;
  font-family: $bodyfontfamily;
  font-size: $bodytextsize;
  color: $viewer_black;
  text-align: center;
  vertical-align: middle;

  &.big {
    i {
      font-size: 24px;
    }
  }

  i {
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: $gray1;
    }
  }

  .HourPicker {
    display: inline-block;
    padding: 2px;
    vertical-align: middle;

    &.focused {
      color: $lightblue;
    }
  }

  .MinutePicker {
    display: inline-block;
    padding: 2px;
    vertical-align: middle;

    &.focused {
      color: $lightblue;
    }
  }

  .AMPMPicker {
    display: inline-block;
    padding: 2px;
    padding-left: 6px;
    vertical-align: middle;

    &.focused {
      color: $lightblue;
    }
  }

  .picker-text {
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }

  .picker-text-input-container {
    width: 24px;
    height: 24px;
    font-size: 12px;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
  }

  .picker-text-input {
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 12px;
    border-color: $lightlightgray;
  }

  .picker-up-arrow {
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 24px;
    min-width: 24px;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 40px;

    &:hover {
      color: $gray1;
    }
  }

  .picker-down-arrow {
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 24px;
    min-width: 24px;
    cursor: pointer;

    &:hover {
      color: $gray1;
    }
  }
}
