/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

@include media($mobile) {
  .Dialog.ExtMenu {
    width: 100%;
    margin-left: -50%;
    height: 95%;
    top: 10px;

    .content {
      .header {
        .customer {
          display: none;
        }
      }

      .feed-container {
        @include span-columns(2);

        .feed {
          .feed-title {
            font-size: 12px;
            padding: 5px;

            .filter {
              position: absolute;
              top: 1px;

              i {
                display: inline-block;
                padding: 3px 5px 2px;
                font-size: 20px;

                &:hover {
                  background-color: $green;
                  cursor: pointer;
                }
              }
            }
          }

          .feed-list {
            @include calc(height, "100% - 85px");

            .feed-items-container {
              .feed-item {
                .feed-item-header {
                  .feed-item-header-text {
                    display: block;
                    padding-left: 3px;
                  }
                }
              }
            }
          }
        }
      }

      .requests-container {
        @include span-columns(3);
      }

      .RequestsList {
        @include span-columns(4);
        @include pad(0 0 0 0);
        height: 100%;

        .tabs {
          @include span-columns(4);
          font-size: 12px;

          & .tab {
            padding: 2px 10px;
          }

          .date-control {
            position: absolute;
            top: 0px;
            right: 5px;
            padding: 1px 0px;

            & .month {
              display: inline-block;
            }

            i {
              display: inline-block;
              padding: 5px 10px 6px;
              font-size: 14px;

              &:hover {
                background-color: $orange;
                cursor: pointer;
              }
            }
          }
        }

        .items-container {
          @include span-columns(4);
          @include calc(height, "100% - 45px");
        }
      }

      .sections {
        @include span-columns(1);
        @include pad(10px 0px 0px 5px);

        .section {
          @include span-columns(4);

          .section-title {
            padding: 5px;
            font-size: 12px;
          }

          .buttons {
            @include pad(2px);

            .button {
              @include span-columns(4);
              font-size: 10px;
              padding: 5px;

              i {
                font-size: 24px;
                padding-bottom: 2px;
              }
            }
          }
        }
      }
    }

    &.not-scheduled {
      width: 90%;
      margin-left: -45%;

      .content {
        .sections {
          @include span-columns(4);
          @include pad(10px 0px 0px 0px);

          .section {
            .buttons {
              @include span-columns(4);
              @include pad(5px 5px);

              .button {
                @include span-columns(1);
              }
            }
          }
        }
      }
    }
  }
}

@include media($desktop) {
  .Dialog.ExtMenu {
    width: 60%;
    margin-left: -30%;
    height: 90%;
    top: 5%;

    .content {
      .feed-container {
        @include span-columns(7);
      }

      .sections {
        @include span-columns(5);

        .section {
          .buttons {
            .button {
              @include span-columns(7);
            }
          }
        }
      }
    }

    &.not-scheduled {
      width: 40%;
      margin-left: -20%;

      .content {
        .sections {
          @include span-columns(14);

          .section {
            .buttons {
              @include span-columns(14);
              @include pad(5px 5px);

              .button {
                @include span-columns(3.5);
              }
            }
          }
        }
      }
    }
  }
}
