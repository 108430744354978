@use "~@/common/scss/variables/theme";

.root {
  position: relative;

  .overlay {
    animation: fadeIn 0.3s;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;

    .spin-icon {
      animation: spin 0.6s infinite linear;
      border: 4px rgba(theme.$lightblue, 0.25) solid;
      border-top: 4px rgba(theme.$lightblue, 1) solid;
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    .legacy-spin-icon {
      animation: legacySpin 1.2s infinite ease-in-out;
      background-color: theme.$darkblue;
      height: 20px;
      width: 20px;
    }
  }

  .content {
    position: relative;
    overflow: hidden;
    transition: opacity 0.3s;
    opacity: 1;
  }

  .blur {
    opacity: 0.25;
    user-select: none;
    pointer-events: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes legacySpin {
  0% {
    transform: perspective(120px);
  }
  50% {
    transform: perspective(120px) rotateY(180deg);
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
