/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.BulkAction {
  width: 760px;
  height: 80%;
  top: 5%;
  margin-left: -380px;

  .header-container {
    @include span-columns(14);

    & .header {
      @include span-columns(14);
      padding: 5px;
      color: $gray1;
      font-size: 14px;
      line-height: 23px;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .mobile-section-nav {
      @include span-columns(14);

      .mobile-section-nav-tab {
        display: inline-block;
        @include pad(10px 10px);
        font-size: 24px;
        color: $darkdarkdarkblue;

        &.group-2 {
          display: none;
        }

        &.active {
          color: $darkdarkdarkblue;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  & .content-body {
    @include span-columns(14);
    height: 100%;
  }

  & .details {
    @include span-columns(7);
    @include calc(height, "100% - 145px");
    border-left: 2px solid $lightlightgray;

    & .swap-sentences {
      @include span-columns(14);
      @include pad(0px 0px 20px 0px);
      height: 100%;
      overflow-y: scroll;
      @include lb-scrollbar(transparent, $lightlightgray);

      & .sentence-container {
        @include span-columns(13.5);
        @include pad(10px 5px 10px 13px);

        &:nth-child(2n) {
          background-color: $lightlightlightgray;
        }
      }

      & .swap-num {
        @include span-columns(2);
        @include pad(0px 0px 0);
        height: 100%;
        color: $lightgray;
        font-size: 15px;
        text-align: left;
      }

      & .sentence {
        @include span-columns(12);
      }
    }

    & .sentence-container {
      & .sentence {
        font-size: 12px;

        .value {
          color: $gray1;
          font-size: 12px;
        }

        i {
          font-size: 16px;
          color: $gray1;
        }
      }

      span.underline {
        text-decoration: underline;
      }

      span.semi-large {
        color: $gray1;
        font-size: 13px;
      }

      span.large {
        color: $gray1;
        font-size: 14px;
      }

      span.uppercase {
        text-transform: uppercase;
      }

      span.dark {
        color: $gray1;
      }

      span.gray {
        color: $lightgray;
      }
    }
  }

  & .bottom-controls {
    @include up-shadow();
    width: 100%;
    background-color: $extremelightgray;
    position: absolute;
    bottom: 0px;

    & .left-side {
      width: 100px;
      height: 100%;
    }

    & .right-side {
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
    }

    span.step,
    span.submit {
      @include pad(10px);
      display: block;
      text-align: center;
      font-size: 13px;
      text-transform: uppercase;
      color: $viewer_black;
      background-color: $lightlightgray;

      i {
        display: block;
        font-size: 22px;
        padding-bottom: 5px;
      }

      &:hover {
        background-color: $lightgray;
        color: $white;
        cursor: pointer;
      }
    }

    span.submit {
      background-color: #5bbf19;
      color: $white;

      &:hover {
        background-color: $green;
      }
    }
  }

  .content {
    position: relative;
    height: 100%;
    @include span-columns(14 of 14);

    .slot-lists-container {
      @include span-columns(5);
      @include calc(height, "100% - 110px");

      & .slot-lists-headers {
        @include span-columns(14);
        @include pad(5px 5px 5px 5px);
        background-color: $white;
        border-bottom: 1px solid $lightlightlightgray;

        & .slot-list-header {
          @include span-columns(7);
          @include pad(0 0 0 10px);
          font-size: 14px;
          color: $gray1;

          & .value-text {
            font-size: 14px;
            color: $gray1;
            text-decoration: underline;
          }

          & .slot-count {
            font-size: 16px;
            color: $lightblue;
          }
        }
      }

      & .slot-lists {
        @include span-columns(14);
        @include calc(height, "100% - 0px");
        overflow-y: scroll;
        @include lb-scrollbar(transparent, $lightblue);
      }
    }

    .SlotList {
      @include span-columns(14);
      @include pad(0 10px);
      height: 100%;

      &.exchange {
        @include span-columns(7);
      }

      & .title {
        @include pad(5px 5px);
        font-size: 12px;
        text-transform: uppercase;
        color: $lightblue;
      }

      .slots-container {
        @include span-columns(14);
        padding-bottom: 20px;
      }

      & .Item {
        @include span-columns(14);
        @include pad(0px 5px 20px);

        &:last-child {
          border-bottom: none;
        }

        .date-text {
          color: $gray1;
          font-size: 13px;
          text-transform: uppercase;

          & .old-value {
            color: red;
            text-decoration: line-through;
          }

          & .new-value {
            color: $viewer_black;
          }
        }

        .time-text {
          color: $viewer_black;
          text-transform: uppercase;

          & .old-value {
            color: red;
            text-decoration: line-through;
          }

          & .new-value {
            color: $viewer_black;
          }
        }

        .value-text {
          font-size: 14px;

          & .old-value {
            color: red;
            text-decoration: line-through;
          }

          & .new-value {
            color: $gray1;
            text-decoration: underline;
          }
        }

        .subtext {
          @include span-columns(14);
          @include pad(5px 0);
          font-size: 12px;

          &.warn {
            color: #ff4c4c;
          }

          & .action-tag {
            text-transform: uppercase;
            display: inline-block;
            padding: 5px 5px;

            &.granted {
              background-color: rgba(185, 243, 174, 0.4);
              border: 1px solid rgba(185, 243, 174, 1);
            }

            &.denied {
              background-color: rgba(255, 0, 0, 0.25);
              border: 1px solid rgba(255, 0, 0, 0.6);
            }

            &.deleted {
              background-color: rgba(255, 0, 0, 0.25);
              border: 1px solid rgba(255, 0, 0, 0.6);
            }

            &.pending {
              background-color: rgba(62, 171, 255, 0.1);
              border: 1px solid rgba(62, 171, 255, 0.5);
            }
          }
        }
      }
    }

    .form-container {
      @include span-columns(7);
      @include shift(0);
      @include pad(0px 20px 0px 0px);
      @include calc(height, "100% - 145px");
      overflow-y: scroll;
      @include lb-scrollbar(transparent, $lightlightgray);

      & .inline-container {
        @include span-columns(14);
        @include pad(0 0 40px);
      }

      &.full {
        @include span-columns(14);
      }
    }

    & .subheaders {
      @include span-columns(14);
      @include pad(0px 0 0px);

      & .subheader {
        @include span-columns(7);
        @include pad(0px 15px 5px);
        font-size: 18px;
        color: $lightgray;
        border-bottom: 2px solid $lightlightgray;
      }
    }
  }

  &.no-form {
    width: 320px;
    margin-left: -160px;

    & .details {
      @include span-columns(14);
      border-left: 2px solid $white;
    }

    & .subheaders {
      & .subheader.edit {
        display: none;
      }

      & .subheader.preview {
        @include span-columns(14);
      }
    }

    .form-container {
      display: none;
    }
  }

  &.no-slots {
    width: 320px;
    margin-left: -160px;

    & .details {
      display: none;
    }

    & .form-container {
      @include span-columns(14);
      @include pad(0);
      @include shift(0);

      & .inline-container {
        border: none;
        @include pad(0);
      }
    }
  }

  &.wide {
    width: 50%;
    margin-left: -25%;
  }

  /* title decorators */
  &.grant,
  &.accept {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #87d200;
        }
      }
    }
  }

  &.deny,
  &.decline {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #ff4c4c;
        }
      }
    }
  }

  &.suspend,
  &.finalize {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #05a7ff;
        }
      }
    }
  }

  &.delete,
  &.cancel {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #8c53c6;
        }
      }
    }
  }
}

@import "./responsive.scss";
