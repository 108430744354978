/* topbar styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "site_styles";

$img_logotilesmall: url(../../images/logo_tile-small.png);
$img_logotilesmall_2x: url(../../images/logo_tile-small_2x.png);
$img_ps_logotilesmall: url(../../images/ps-lb-logo.png);
$img_ps_logotilesmall_2x: url(../../images//ps-lb-logo_2x.png);

#topbar-close {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 1px;
  width: 1px;
}

#TopBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: #f7f7f7;
  z-index: 1000;

  @include top-menu-shadow;

  #logo-tile-icon {
    float: left;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 2;
    background-image: $img_logotilesmall;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
    -webkit-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

    @include hidpi(1.5) {
      background-image: $img_logotilesmall_2x;
    }
  }

  #lb-ps-logo {
    float: left;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 2;
    background-image: $img_ps_logotilesmall;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px 40px;
    -webkit-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 10px -10px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

    @include hidpi(1.5) {
      background-image: $img_ps_logotilesmall_2x;
    }
  }

  #app-menu {
    padding: 5px 10px 0px;
    z-index: 10;

    div.icon {
      float: right;
      font-size: 24px;
    }

    &:hover {
      background-color: $lightlightlightgray;
      cursor: pointer;
    }
  }

  #user-details {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    padding: 5px 10px 0px;
    z-index: 10;

    div.text {
      display: none;

      span.customer {
        font-size: 10px;
        display: block;
      }

      span.user {
        display: block;
        text-align: right;
      }
    }

    div.icon {
      float: right;
      font-size: 24px;
    }

    &:hover {
      background-color: $lightlightlightgray;
      cursor: pointer;
    }
  }

  .ContextRibbonItem,
  .top-bar-btn {
    //background-color: $lightlightlightgray;
    background-color: $white;
    margin-top: 0;
    padding: 3px 10px 3px 10px;
    height: 40px;
    font-size: 14px;
    color: $gray1;

    //color: $darkblue;
    text-transform: uppercase;
    float: left;
    position: relative;
    z-index: 1;

    //width: 300px;
    border: 1px solid $lightlightlightgray;
    border-top: none;
    border-bottom: none;
    border-left: none;
    animation: slide 0.5s ease-out;

    // dont allow any single item to be great then 25% of the width
    // and prevent overflow
    &.limit-width-small {
      @include calc(max-width, "25%");

      //padding-bottom: 0px;
      //margin-top: -1px;
    }

    // dont allow any single item to be great then 50% of the width
    // and prevent overflow
    &.limit-width-large {
      @include calc(max-width, "50%");

      //padding-bottom: 0px;
      //margin-top: -1px;
    }

    //-webkit-box-shadow: inset 10px -10px 5px 0px rgba(0,0,0,0.15);
    //-moz-box-shadow: inset 10px -10px 5px 0px rgba(0,0,0,0.15);
    //box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.25);

    div.ribbon-text {
      display: inline-block;
      min-width: 175px;
      padding: 8px 15px 5px 15px;
      border: 1px solid $white;

      &.limit-width-small,
      &.limit-width-large {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      & .tool-tip {
        display: none;
        position: absolute;
        text-transform: lowercase;
        top: 35px;
        left: 20px;

        //width: 140px;
        color: $white;
        font-size: 12px;
        white-space: nowrap;

        .triangle {
          @include triangle(12px, rgba(0, 0, 0, 0.8), up);

          margin-left: 10px;
        }

        .message {
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 10px;
          padding: 10px;
          text-align: left;
        }
      }

      &.date {
        width: 170px;
      }

      &:hover {
        background-color: $lightlightlightgray;
        border: 1px solid $lightlightgray;
        cursor: pointer;

        & .tool-tip {
          display: block;
        }
      }
      &.selected {
        background-color: rgba($brand-lightblue, 0.1);
        border: 1px solid $lightlightgray;
      }
      &.max-width {
        max-width: 200px;
      }
    }

    &.unclickable {
      div.ribbon-text {
        min-width: 175px;
        &:hover {
          background-color: transparent;
          border: 1px solid transparent;
          cursor: default;
        }
      }
    }

    div.icons-left {
      display: inline-block;

      & i.fa {
        padding: 9px 4px 7px;
        border: 1px solid $white;
        font-size: 16px;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
          cursor: pointer;
        }
      }
    }

    div.icons-mobile {
      display: none;

      & i.fa {
        padding: 10px 4px 6px;
        border: 1px solid $white;
        font-size: 16px;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
          cursor: pointer;
        }
      }
    }

    div.icons-right {
      display: inline-block;

      & i.fa {
        padding: 9px 4px 7px;
        border: 1px solid $white;
        font-size: 16px;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
          cursor: pointer;
        }
      }
    }

    &:last-child {
      border-right: 1px solid $lightlightgray;
    }

    &.dropdown .menu {
      text-transform: none;
    }
  }

  .subnav-menu {
    position: absolute;
    right: 10px;
    top: 36px;
    background-color: $white;

    @include subnav-shadow;

    margin-top: 3px;
    width: 150px;
    display: none;

    .subnav-menu-item {
      padding: 5px 10px;
      text-transform: uppercase;

      &:hover {
        background-color: $darkblue;
        color: $white;
      }
    }

    &:before {
      content: "";

      @include triangle(10px, $white, up);

      position: absolute;
      top: -5px;
      right: 5px;
    }

    &.active {
      display: block;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .btn-icon {
    font-size: 20px;
    color: $gray3;

    .csv {
      margin-top: 7px;
    }

    &:hover {
      cursor: pointer;
      color: $brand-darkblue;
    }
  }

  .informative-icon-container {
    margin-top: 10px;
    width: 20px;
    height: 20px;
    position: relative;
    left: 80px;
  }

  .option-label {
    color: gray;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .option-divider {
    margin: 15px 0;
    height: 1px;
    background-color: gainsboro;
  }
  .cal-stan-col-opts-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :first-child {
      margin-bottom: 10px;
    }
  }
  .top-bar-icon {
    font-size: 20px;
    margin-top: 8px;
    color: $gray3;
    &.selected {
      color: $brand-lightblue;
    }
  }

  .button-group {
    flex: 1 1;
    border: 1px solid $lightlightgray;
    background-image: -webkit-gradient(linear, left top, left bottom, from($white), to($extremelightgray));
    background-image: -webkit-linear-gradient($white, $extremelightgray);
    background-image: -o-linear-gradient($white, $extremelightgray);
    background-image: linear-gradient($white, $extremelightgray);
    text-align: center;
    padding: 8px;
    cursor: pointer;
    border-collapse: collapse;
    &.selected {
      background-color: $brand-lightblue;
      background-image: none;
      border: 1px solid #073b89;
      color: $white;
    }
    &:not(.selected):hover {
      background-image: none;
      background-color: rgba($brand-lightblue, 0.1);
      border: 1px solid $lightlightgray;
    }
  }

  .radio-btn {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-weight: 400;
    height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #bbb;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }

    &:hover input ~ .checkmark {
      background-color: rgba($brand-lightblue, 0.05);
    }

    input:checked ~ .checkmark {
      background-color: $white;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $brand-lightblue;
    }
  }

  .checkbox-btn {
    cursor: pointer;
    input[type="checkbox"] {
      display: none;
    }

    label {
      padding-left: 0;
    }

    label:before {
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 10px;
      line-height: 20px;
      text-align: center;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    input[type="checkbox"]:checked + label::before {
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      font-weight: 600;
    }
  }

  .warn {
    color: #ff4c4c;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .activity-feed-chip {
    height: 12px;
    width: 12px;
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 50%;
    background-color: #ff0000;
    border: 1px solid #f0f0f0;
  }

  .dropdown-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 5px;

    span {
      margin-right: 10px;
    }

    .dropdown {
      width: 100%;
    }
  }
}

@keyframes slide {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    margin-top: -10px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@import "./responsive";
