@import "variables";
@import "../../grid/styles";

.slot-container {
  position: relative;
  overflow: visible;

  &__children-container {
    overflow: hidden;

    &:hover {
      .contextual-clues {
        background-color: $postit !important;
      }
    }
  }

  &__tooltip {
    position: absolute;
    left: -50px;
    width: 100px;
    height: 50px;
    margin-top: 5px;
    background: #fff;
  }

  .GanttSlot {
    position: relative;
    top: 0 !important;

    div.contextual-clues {
      position: absolute;
      height: 100%;
      display: flex;
      margin-bottom: 2px;
      right: 0;
      top: 0;
      pointer-events: none;
      padding-top: 3px;
      padding-right: 3px;

      &.show-times {
        height: 60%;
      }

      div.contextual-clue {
        width: 5px;
        height: 5px;
        margin: 0 0 0 1px;

        &.hasNote {
          background-color: $noteindicator_color;
        }

        &.isPending {
          background-color: $pendingindicator_color;
        }

        &.isDenied {
          background-color: $deniedindicator_color;
        }

        &.isDeleted {
          background-color: $deletedindicator_color;
        }

        &.isWebRequest {
          background-color: $requestindicator_color;
        }
      }
    }
  }
}

// Calendar containers have no left column, and so position their tooltip directly below the slot
.CalendarContainer .slot-container__tooltip {
  left: 10px;
}
