@import "bourbon.scss";
@import "neat.scss";
@import "grid-settings";
@import "variables";
@import "mixins";

$lightblue_transparent: rgba(62, 171, 255, 0.9);
$darkblue_transparent: rgba(72, 119, 184, 0.9);

#ToolBox {
  position: absolute;
  top: 0px;
  right: 75px;
  text-align: right;
  background-color: $white;
  border-left: 1px solid $lightlightgray;
  border-right: 1px solid $lightlightgray;

  .tool-desc {
    display: none;
    text-transform: uppercase;
    color: $white;
    float: left;
    font-size: 14px;
    padding: 13px 10px;
  }

  .action-icons {
    display: inline-block;
    padding: 2px 8px 3px 8px;
    border-right: 1px solid $lightlightlightgray;

    &.no-border {
      border-right: none;
    }
  }

  .tool-icons {
    display: inline-block;
    padding: 2px 8px 3px 8px;
  }

  .ToolBoxIcon {
    position: relative;
    display: inline-block;
    color: $lightgray;
    background-color: $white;
    width: 35px;
    height: 32px;
    padding: 3px 4px 4px 4px;
    margin: 3px 1px 0;
    @include toolicon-shadow;

    i {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 4px 3px;
      text-align: center;
      font-size: 20px;
    }

    & .status {
      position: absolute;
      bottom: 0;
      left: 0px;
      font-size: 12px;
      padding: 0px 2px;
      background-color: rgba(255, 255, 255, 0.8);
      color: $gray1;
    }

    & .tool-tip {
      display: none;
      position: absolute;
      top: 35px;
      left: 5px;
      color: $white;
      font-size: 12px;
      white-space: nowrap;

      .triangle {
        @include triangle(12px, rgba(0, 0, 0, 0.8), up);
        margin-left: 10px;
      }

      .message {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        padding: 10px;
        text-align: left;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $lightlightlightgray;
      color: $viewer_black;

      & .tool-tip {
        display: block;
      }
    }

    &.active {
      background-color: $lightorange;
      color: $white;
      @include toolicon-shadow;
    }

    &.disabled {
      color: $lightlightlightgray;

      &:hover {
        cursor: default;
        background-color: $white;

        & .tool-tip {
          display: none;
        }
      }
    }

    &.attention {
      background-color: rgba(255, 0, 0, 0.75);
      color: $white;
      @include transition(background-color 0.5s ease-out 0s);
    }
  }
}
