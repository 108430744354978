/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.Dialog.Subscribe {
  height: auto;

  .title-container {
    text-transform: uppercase;
    font-size: 16px;
    padding: 9px 10px;
    background-color: $darkblue;
    color: $white;

    i {
      padding-right: 10px;
    }
  }

  .body-container {
    width: 100%;
    padding: 10px;
  }

  .body-form {
    width: 100%;

    button {
      margin-right: 4px;
      text-transform: uppercase;
      font-size: 12px;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      color: $viewer_black;
      padding: 5px 5px;

      &:hover {
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;
        cursor: pointer;
      }
    }

    input[type="text"] {
      width: 100%;
    }

    label {
      font-weight: bold;
    }

    .action-bar {
      text-align: center;
    }

    .form-group {
      padding-bottom: 10px;
    }

    .edit-exclude {
      color: $lightblue;

      &:hover {
        cursor: pointer;
      }
    }

    .error {
      border-color: red;
    }
  }
}

@import "./responsive";
