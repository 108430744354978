/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.ScheduleEditsExt.ErrorDialog {
  width: 300px;
  height: 360px;
  top: 10%;
  margin-left: -150px;

  .close {
    background-color: transparent;
  }

  .header {
    @include span-columns(12);
    color: $white;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 10px 10px 5px 10px;
    background-color: rgba(255, 0, 0, 0.5);
  }

  .errors-container {
    @include span-columns(14);
    height: 280px;
    padding-top: 10px;
    padding-bottom: 40px;
    overflow-y: scroll;
    @include lb-scrollbar(transparent, $lightlightgray);
  }

  .errors-header {
    @include span-columns(14);
    line-height: 23px;
    color: $gray1;
    padding: 10px 10px 5px 10px;
  }

  .ErrorItem {
    @include span-columns(12);
    @include pad(0px 10px 15px);

    .date {
      @include span-columns(14);
      padding-top: 5px;
      text-align: left;
      color: $gray1;
      border-bottom: 1px solid #dbdbdb;

      i {
        padding-right: 2px;
      }
    }

    .message {
      @include span-columns(14);
      text-align: left;
      padding-top: 5px;
    }
  }

  & .content {
    @include span-columns(14);
    @include pad(0px 0 0px);
  }
}

@import "./responsive.scss";
