/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

@include media($mobile) {
  .DialogContainer {
    &.active {
      visibility: visible;
    }
  }

  .Dialog {
    position: absolute;
    left: 50%;
    top: 15%;
    margin-left: -45%;
    width: 90%;
    height: auto;
    background-color: $white;
  }
}
