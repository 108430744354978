.#{$fa-css-prefix}.#{$fa-css-prefix}-glass:before {
  content: fa-content($fa-var-glass-martini);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-meetup {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-o:before {
  content: fa-content($fa-var-star);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-remove:before {
  content: fa-content($fa-var-times);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-close:before {
  content: fa-content($fa-var-times);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gear:before {
  content: fa-content($fa-var-cog);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-trash-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-trash-o:before {
  content: fa-content($fa-var-trash-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-o:before {
  content: fa-content($fa-var-file);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-clock-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-clock-o:before {
  content: fa-content($fa-var-clock);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-down {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-down:before {
  content: fa-content($fa-var-arrow-alt-circle-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-up {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-up:before {
  content: fa-content($fa-var-arrow-alt-circle-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-play-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-play-circle-o:before {
  content: fa-content($fa-var-play-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-repeat:before {
  content: fa-content($fa-var-redo);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rotate-right:before {
  content: fa-content($fa-var-redo);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-refresh:before {
  content: fa-content($fa-var-sync);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-list-alt {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dedent:before {
  content: fa-content($fa-var-outdent);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-video-camera:before {
  content: fa-content($fa-var-video);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-picture-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-picture-o:before {
  content: fa-content($fa-var-image);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-photo {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-photo:before {
  content: fa-content($fa-var-image);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-image {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-image:before {
  content: fa-content($fa-var-image);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil:before {
  content: fa-content($fa-var-pencil-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-map-marker:before {
  content: fa-content($fa-var-map-marker-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil-square-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil-square-o:before {
  content: fa-content($fa-var-edit);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-share-square-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-share-square-o:before {
  content: fa-content($fa-var-share-square);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-check-square-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-check-square-o:before {
  content: fa-content($fa-var-check-square);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows:before {
  content: fa-content($fa-var-arrows-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-times-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-times-circle-o:before {
  content: fa-content($fa-var-times-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-check-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-check-circle-o:before {
  content: fa-content($fa-var-check-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mail-forward:before {
  content: fa-content($fa-var-share);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-expand:before {
  content: fa-content($fa-var-expand-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-compress:before {
  content: fa-content($fa-var-compress-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eye {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eye-slash {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-warning:before {
  content: fa-content($fa-var-exclamation-triangle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar:before {
  content: fa-content($fa-var-calendar-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows-v:before {
  content: fa-content($fa-var-arrows-alt-v);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows-h:before {
  content: fa-content($fa-var-arrows-alt-h);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bar-chart {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bar-chart:before {
  content: fa-content($fa-var-chart-bar);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bar-chart-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bar-chart-o:before {
  content: fa-content($fa-var-chart-bar);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-twitter-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gears:before {
  content: fa-content($fa-var-cogs);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-up {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-up:before {
  content: fa-content($fa-var-thumbs-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-down {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-down:before {
  content: fa-content($fa-var-thumbs-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-heart-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-heart-o:before {
  content: fa-content($fa-var-heart);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sign-out:before {
  content: fa-content($fa-var-sign-out-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin-square:before {
  content: fa-content($fa-var-linkedin);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thumb-tack:before {
  content: fa-content($fa-var-thumbtack);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-external-link:before {
  content: fa-content($fa-var-external-link-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sign-in:before {
  content: fa-content($fa-var-sign-in-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-github-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lemon-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-lemon-o:before {
  content: fa-content($fa-var-lemon);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-square-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-square-o:before {
  content: fa-content($fa-var-square);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bookmark-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bookmark-o:before {
  content: fa-content($fa-var-bookmark);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-twitter {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook:before {
  content: fa-content($fa-var-facebook-f);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-f {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-f:before {
  content: fa-content($fa-var-facebook-f);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-github {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-credit-card {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-feed:before {
  content: fa-content($fa-var-rss);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hdd-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hdd-o:before {
  content: fa-content($fa-var-hdd);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-right {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-right:before {
  content: fa-content($fa-var-hand-point-right);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-left {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-left:before {
  content: fa-content($fa-var-hand-point-left);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-up {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-up:before {
  content: fa-content($fa-var-hand-point-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-down {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-down:before {
  content: fa-content($fa-var-hand-point-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows-alt:before {
  content: fa-content($fa-var-expand-arrows-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-group:before {
  content: fa-content($fa-var-users);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-chain:before {
  content: fa-content($fa-var-link);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-scissors:before {
  content: fa-content($fa-var-cut);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-files-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-files-o:before {
  content: fa-content($fa-var-copy);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-floppy-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-floppy-o:before {
  content: fa-content($fa-var-save);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-navicon:before {
  content: fa-content($fa-var-bars);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reorder:before {
  content: fa-content($fa-var-bars);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pinterest {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pinterest-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus:before {
  content: fa-content($fa-var-google-plus-g);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-money {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-money:before {
  content: fa-content($fa-var-money-bill-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-unsorted:before {
  content: fa-content($fa-var-sort);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-desc:before {
  content: fa-content($fa-var-sort-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-asc:before {
  content: fa-content($fa-var-sort-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin:before {
  content: fa-content($fa-var-linkedin-in);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rotate-left:before {
  content: fa-content($fa-var-undo);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-legal:before {
  content: fa-content($fa-var-gavel);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tachometer:before {
  content: fa-content($fa-var-tachometer-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dashboard:before {
  content: fa-content($fa-var-tachometer-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-comment-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-comment-o:before {
  content: fa-content($fa-var-comment);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-comments-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-comments-o:before {
  content: fa-content($fa-var-comments);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-flash:before {
  content: fa-content($fa-var-bolt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-clipboard {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-paste {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-paste:before {
  content: fa-content($fa-var-clipboard);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lightbulb-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-lightbulb-o:before {
  content: fa-content($fa-var-lightbulb);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-exchange:before {
  content: fa-content($fa-var-exchange-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cloud-download:before {
  content: fa-content($fa-var-cloud-download-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cloud-upload:before {
  content: fa-content($fa-var-cloud-upload-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-o:before {
  content: fa-content($fa-var-bell);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cutlery:before {
  content: fa-content($fa-var-utensils);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-text-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-text-o:before {
  content: fa-content($fa-var-file-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-building-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-building-o:before {
  content: fa-content($fa-var-building);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hospital-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hospital-o:before {
  content: fa-content($fa-var-hospital);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tablet:before {
  content: fa-content($fa-var-tablet-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mobile:before {
  content: fa-content($fa-var-mobile-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mobile-phone:before {
  content: fa-content($fa-var-mobile-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-o:before {
  content: fa-content($fa-var-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mail-reply:before {
  content: fa-content($fa-var-reply);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-github-alt {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-o:before {
  content: fa-content($fa-var-folder);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-open-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-open-o:before {
  content: fa-content($fa-var-folder-open);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-smile-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-smile-o:before {
  content: fa-content($fa-var-smile);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-frown-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-frown-o:before {
  content: fa-content($fa-var-frown);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-meh-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-meh-o:before {
  content: fa-content($fa-var-meh);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-keyboard-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-keyboard-o:before {
  content: fa-content($fa-var-keyboard);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-flag-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-flag-o:before {
  content: fa-content($fa-var-flag);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mail-reply-all:before {
  content: fa-content($fa-var-reply-all);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-o:before {
  content: fa-content($fa-var-star-half);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-empty {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-empty:before {
  content: fa-content($fa-var-star-half);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-full {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-full:before {
  content: fa-content($fa-var-star-half);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-code-fork:before {
  content: fa-content($fa-var-code-branch);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-chain-broken:before {
  content: fa-content($fa-var-unlink);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-shield:before {
  content: fa-content($fa-var-shield-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-o:before {
  content: fa-content($fa-var-calendar);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-maxcdn {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-html5 {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-css3 {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ticket:before {
  content: fa-content($fa-var-ticket-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-minus-square-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-minus-square-o:before {
  content: fa-content($fa-var-minus-square);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-level-up:before {
  content: fa-content($fa-var-level-up-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-level-down:before {
  content: fa-content($fa-var-level-down-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil-square:before {
  content: fa-content($fa-var-pen-square);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-external-link-square:before {
  content: fa-content($fa-var-external-link-square-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-compass {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-down {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-down:before {
  content: fa-content($fa-var-caret-square-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-down {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-down:before {
  content: fa-content($fa-var-caret-square-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-up {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-up:before {
  content: fa-content($fa-var-caret-square-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-up {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-up:before {
  content: fa-content($fa-var-caret-square-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-right {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-right:before {
  content: fa-content($fa-var-caret-square-right);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-right {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-right:before {
  content: fa-content($fa-var-caret-square-right);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eur:before {
  content: fa-content($fa-var-euro-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-euro:before {
  content: fa-content($fa-var-euro-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gbp:before {
  content: fa-content($fa-var-pound-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-usd:before {
  content: fa-content($fa-var-dollar-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dollar:before {
  content: fa-content($fa-var-dollar-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-inr:before {
  content: fa-content($fa-var-rupee-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rupee:before {
  content: fa-content($fa-var-rupee-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-jpy:before {
  content: fa-content($fa-var-yen-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cny:before {
  content: fa-content($fa-var-yen-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rmb:before {
  content: fa-content($fa-var-yen-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yen:before {
  content: fa-content($fa-var-yen-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rub:before {
  content: fa-content($fa-var-ruble-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ruble:before {
  content: fa-content($fa-var-ruble-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rouble:before {
  content: fa-content($fa-var-ruble-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-krw:before {
  content: fa-content($fa-var-won-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-won:before {
  content: fa-content($fa-var-won-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-btc {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bitcoin {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bitcoin:before {
  content: fa-content($fa-var-btc);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-text:before {
  content: fa-content($fa-var-file-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-alpha-asc:before {
  content: fa-content($fa-var-sort-alpha-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-alpha-desc:before {
  content: fa-content($fa-var-sort-alpha-down-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-amount-asc:before {
  content: fa-content($fa-var-sort-amount-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-amount-desc:before {
  content: fa-content($fa-var-sort-amount-down-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-numeric-asc:before {
  content: fa-content($fa-var-sort-numeric-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-numeric-desc:before {
  content: fa-content($fa-var-sort-numeric-down-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-xing {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-xing-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube-play {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube-play:before {
  content: fa-content($fa-var-youtube);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dropbox {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stack-overflow {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-instagram {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-flickr {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-adn {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bitbucket {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bitbucket-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bitbucket-square:before {
  content: fa-content($fa-var-bitbucket);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tumblr {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tumblr-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-down:before {
  content: fa-content($fa-var-long-arrow-alt-down);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-up:before {
  content: fa-content($fa-var-long-arrow-alt-up);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-left:before {
  content: fa-content($fa-var-long-arrow-alt-left);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-right:before {
  content: fa-content($fa-var-long-arrow-alt-right);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-apple {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-windows {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-android {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linux {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dribbble {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-skype {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-foursquare {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-trello {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gratipay {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gittip {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-gittip:before {
  content: fa-content($fa-var-gratipay);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sun-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-sun-o:before {
  content: fa-content($fa-var-sun);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-moon-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-moon-o:before {
  content: fa-content($fa-var-moon);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vk {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-weibo {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-renren {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pagelines {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stack-exchange {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-right {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-right:before {
  content: fa-content($fa-var-arrow-alt-circle-right);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-left {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-left:before {
  content: fa-content($fa-var-arrow-alt-circle-left);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-left {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-left:before {
  content: fa-content($fa-var-caret-square-left);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-left {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-left:before {
  content: fa-content($fa-var-caret-square-left);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dot-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-dot-circle-o:before {
  content: fa-content($fa-var-dot-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vimeo-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-try:before {
  content: fa-content($fa-var-lira-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-turkish-lira:before {
  content: fa-content($fa-var-lira-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-plus-square-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-plus-square-o:before {
  content: fa-content($fa-var-plus-square);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-slack {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wordpress {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-openid {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-institution:before {
  content: fa-content($fa-var-university);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bank:before {
  content: fa-content($fa-var-university);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mortar-board:before {
  content: fa-content($fa-var-graduation-cap);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yahoo {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reddit {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reddit-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stumbleupon-circle {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stumbleupon {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-delicious {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-digg {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pied-piper-pp {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pied-piper-alt {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-drupal {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-joomla {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-spoon:before {
  content: fa-content($fa-var-utensil-spoon);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-behance {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-behance-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-steam {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-steam-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-automobile:before {
  content: fa-content($fa-var-car);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-o:before {
  content: fa-content($fa-var-envelope);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-spotify {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-deviantart {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-soundcloud {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-pdf-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-pdf-o:before {
  content: fa-content($fa-var-file-pdf);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-word-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-word-o:before {
  content: fa-content($fa-var-file-word);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-excel-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-excel-o:before {
  content: fa-content($fa-var-file-excel);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-powerpoint-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-powerpoint-o:before {
  content: fa-content($fa-var-file-powerpoint);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-image-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-image-o:before {
  content: fa-content($fa-var-file-image);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-photo-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-photo-o:before {
  content: fa-content($fa-var-file-image);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-picture-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-picture-o:before {
  content: fa-content($fa-var-file-image);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-archive-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-archive-o:before {
  content: fa-content($fa-var-file-archive);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-zip-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-zip-o:before {
  content: fa-content($fa-var-file-archive);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-audio-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-audio-o:before {
  content: fa-content($fa-var-file-audio);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-sound-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-sound-o:before {
  content: fa-content($fa-var-file-audio);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-video-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-video-o:before {
  content: fa-content($fa-var-file-video);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-movie-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-movie-o:before {
  content: fa-content($fa-var-file-video);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-code-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-code-o:before {
  content: fa-content($fa-var-file-code);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vine {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-codepen {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-jsfiddle {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-ring {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-bouy {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-life-bouy:before {
  content: fa-content($fa-var-life-ring);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-buoy {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-life-buoy:before {
  content: fa-content($fa-var-life-ring);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-saver {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-life-saver:before {
  content: fa-content($fa-var-life-ring);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-support {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-support:before {
  content: fa-content($fa-var-life-ring);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-o-notch:before {
  content: fa-content($fa-var-circle-notch);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rebel {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ra {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-ra:before {
  content: fa-content($fa-var-rebel);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-resistance {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-resistance:before {
  content: fa-content($fa-var-rebel);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-empire {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ge {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-ge:before {
  content: fa-content($fa-var-empire);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-git-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-git {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hacker-news {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-y-combinator-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-y-combinator-square:before {
  content: fa-content($fa-var-hacker-news);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yc-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-yc-square:before {
  content: fa-content($fa-var-hacker-news);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tencent-weibo {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-qq {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-weixin {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wechat {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-wechat:before {
  content: fa-content($fa-var-weixin);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-send:before {
  content: fa-content($fa-var-paper-plane);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-paper-plane-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-paper-plane-o:before {
  content: fa-content($fa-var-paper-plane);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-send-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-send-o:before {
  content: fa-content($fa-var-paper-plane);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-thin {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-thin:before {
  content: fa-content($fa-var-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-header:before {
  content: fa-content($fa-var-heading);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sliders:before {
  content: fa-content($fa-var-sliders-h);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-futbol-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-futbol-o:before {
  content: fa-content($fa-var-futbol);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-soccer-ball-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-soccer-ball-o:before {
  content: fa-content($fa-var-futbol);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-slideshare {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-twitch {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yelp {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-newspaper-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-newspaper-o:before {
  content: fa-content($fa-var-newspaper);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-paypal {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-wallet {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-visa {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-mastercard {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-discover {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-amex {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-paypal {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-stripe {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-slash-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-slash-o:before {
  content: fa-content($fa-var-bell-slash);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-trash:before {
  content: fa-content($fa-var-trash-alt);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-copyright {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eyedropper:before {
  content: fa-content($fa-var-eye-dropper);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-area-chart:before {
  content: fa-content($fa-var-chart-area);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pie-chart:before {
  content: fa-content($fa-var-chart-pie);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-line-chart:before {
  content: fa-content($fa-var-chart-line);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lastfm {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lastfm-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ioxhost {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-angellist {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-cc:before {
  content: fa-content($fa-var-closed-captioning);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ils:before {
  content: fa-content($fa-var-shekel-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-shekel:before {
  content: fa-content($fa-var-shekel-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sheqel:before {
  content: fa-content($fa-var-shekel-sign);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-meanpath {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-meanpath:before {
  content: fa-content($fa-var-font-awesome);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-buysellads {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-connectdevelop {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dashcube {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-forumbee {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-leanpub {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sellsy {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-shirtsinbulk {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-simplybuilt {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-skyatlas {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-diamond {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-diamond:before {
  content: fa-content($fa-var-gem);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-intersex:before {
  content: fa-content($fa-var-transgender);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-official {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-official:before {
  content: fa-content($fa-var-facebook);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pinterest-p {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-whatsapp {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hotel:before {
  content: fa-content($fa-var-bed);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-viacoin {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-medium {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-y-combinator {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yc {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-yc:before {
  content: fa-content($fa-var-y-combinator);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-optin-monster {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-opencart {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-expeditedssl {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-4:before {
  content: fa-content($fa-var-battery-full);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery:before {
  content: fa-content($fa-var-battery-full);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-3:before {
  content: fa-content($fa-var-battery-three-quarters);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-2:before {
  content: fa-content($fa-var-battery-half);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-1:before {
  content: fa-content($fa-var-battery-quarter);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-0:before {
  content: fa-content($fa-var-battery-empty);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-object-group {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-object-ungroup {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sticky-note-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-sticky-note-o:before {
  content: fa-content($fa-var-sticky-note);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-jcb {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-diners-club {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-clone {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-o:before {
  content: fa-content($fa-var-hourglass);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-1:before {
  content: fa-content($fa-var-hourglass-start);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-2:before {
  content: fa-content($fa-var-hourglass-half);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-3:before {
  content: fa-content($fa-var-hourglass-end);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-rock-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-rock-o:before {
  content: fa-content($fa-var-hand-rock);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-grab-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-grab-o:before {
  content: fa-content($fa-var-hand-rock);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-paper-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-paper-o:before {
  content: fa-content($fa-var-hand-paper);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-stop-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-stop-o:before {
  content: fa-content($fa-var-hand-paper);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-scissors-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-scissors-o:before {
  content: fa-content($fa-var-hand-scissors);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-lizard-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-lizard-o:before {
  content: fa-content($fa-var-hand-lizard);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-spock-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-spock-o:before {
  content: fa-content($fa-var-hand-spock);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-pointer-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-pointer-o:before {
  content: fa-content($fa-var-hand-pointer);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-peace-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-peace-o:before {
  content: fa-content($fa-var-hand-peace);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-registered {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-creative-commons {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gg {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gg-circle {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tripadvisor {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-odnoklassniki {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-odnoklassniki-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-get-pocket {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wikipedia-w {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-safari {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-chrome {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-firefox {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-opera {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-internet-explorer {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-television:before {
  content: fa-content($fa-var-tv);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-contao {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-500px {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-amazon {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-plus-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-plus-o:before {
  content: fa-content($fa-var-calendar-plus);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-minus-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-minus-o:before {
  content: fa-content($fa-var-calendar-minus);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-times-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-times-o:before {
  content: fa-content($fa-var-calendar-times);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-check-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-check-o:before {
  content: fa-content($fa-var-calendar-check);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-map-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-map-o:before {
  content: fa-content($fa-var-map);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-commenting:before {
  content: fa-content($fa-var-comment-dots);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-commenting-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-commenting-o:before {
  content: fa-content($fa-var-comment-dots);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-houzz {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vimeo {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-vimeo:before {
  content: fa-content($fa-var-vimeo-v);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-black-tie {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-fonticons {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reddit-alien {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-edge {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-credit-card-alt:before {
  content: fa-content($fa-var-credit-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-codiepie {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-modx {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-fort-awesome {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-usb {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-product-hunt {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mixcloud {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-scribd {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pause-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-pause-circle-o:before {
  content: fa-content($fa-var-pause-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stop-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-stop-circle-o:before {
  content: fa-content($fa-var-stop-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bluetooth {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bluetooth-b {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gitlab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wpbeginner {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wpforms {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-envira {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wheelchair-alt {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-wheelchair-alt:before {
  content: fa-content($fa-var-accessible-icon);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-question-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-question-circle-o:before {
  content: fa-content($fa-var-question-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-volume-control-phone:before {
  content: fa-content($fa-var-phone-volume);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-asl-interpreting:before {
  content: fa-content($fa-var-american-sign-language-interpreting);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-deafness:before {
  content: fa-content($fa-var-deaf);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hard-of-hearing:before {
  content: fa-content($fa-var-deaf);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-glide {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-glide-g {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-signing:before {
  content: fa-content($fa-var-sign-language);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-viadeo {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-viadeo-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat-ghost {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat-square {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pied-piper {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-first-order {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yoast {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-themeisle {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-official {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-official:before {
  content: fa-content($fa-var-google-plus);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-circle {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-circle:before {
  content: fa-content($fa-var-google-plus);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-font-awesome {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-fa {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-fa:before {
  content: fa-content($fa-var-font-awesome);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-handshake-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-handshake-o:before {
  content: fa-content($fa-var-handshake);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-open-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-open-o:before {
  content: fa-content($fa-var-envelope-open);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linode {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-address-book-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-address-book-o:before {
  content: fa-content($fa-var-address-book);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vcard:before {
  content: fa-content($fa-var-address-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-address-card-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-address-card-o:before {
  content: fa-content($fa-var-address-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vcard-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-vcard-o:before {
  content: fa-content($fa-var-address-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-user-circle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-user-circle-o:before {
  content: fa-content($fa-var-user-circle);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-user-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-user-o:before {
  content: fa-content($fa-var-user);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-id-badge {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-drivers-license:before {
  content: fa-content($fa-var-id-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-id-card-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-id-card-o:before {
  content: fa-content($fa-var-id-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-drivers-license-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-drivers-license-o:before {
  content: fa-content($fa-var-id-card);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-quora {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-free-code-camp {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-telegram {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-4:before {
  content: fa-content($fa-var-thermometer-full);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer:before {
  content: fa-content($fa-var-thermometer-full);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-3:before {
  content: fa-content($fa-var-thermometer-three-quarters);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-2:before {
  content: fa-content($fa-var-thermometer-half);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-1:before {
  content: fa-content($fa-var-thermometer-quarter);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-0:before {
  content: fa-content($fa-var-thermometer-empty);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bathtub:before {
  content: fa-content($fa-var-bath);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-s15:before {
  content: fa-content($fa-var-bath);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-window-maximize {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-window-restore {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-times-rectangle:before {
  content: fa-content($fa-var-window-close);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-window-close-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-window-close-o:before {
  content: fa-content($fa-var-window-close);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-times-rectangle-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-times-rectangle-o:before {
  content: fa-content($fa-var-window-close);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bandcamp {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-grav {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-etsy {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-imdb {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ravelry {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eercast {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-eercast:before {
  content: fa-content($fa-var-sellcast);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snowflake-o {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-snowflake-o:before {
  content: fa-content($fa-var-snowflake);
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-superpowers {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wpexplorer {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cab:before {
  content: fa-content($fa-var-taxi);
}
