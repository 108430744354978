/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

.Dialog.SwapRemove {
  width: 320px;
  height: 410px;
  top: 5%;
  margin-left: -160px;

  .section-container {
    @include span-columns(14);
    @include outer-container;
    @include pad(5px 0px 0px 0px);
    overflow: hidden;
    @include calc(height, "100% - 115px");

    .section {
      height: 100%;
      @include span-columns(56);

      &._1 {
        margin-left: 0;
      }

      &._2 {
        margin-left: -320px;
      }

      &._3 {
        margin-left: -640px;
      }

      &._4 {
        margin-left: -960px;
      }

      transition: (margin-left 0.3s ease 0s);
    }
  }

  .content {
    position: relative;
    height: 100%;
    overflow-y: auto;
    @include lb-scrollbar(transparent, $lightblue);
    @include span-columns(14 of 56);

    .details {
      @include span-columns(14);
      text-align: left;
      max-height: calc(100vh - 160px);
      @include pad(5px 7px);

      .sentence-container {
        @include span-columns(14);
        @include pad(5px 10px 15px);
      }
    }

    .DatePickerControls {
      margin-top: 10px;
      font-size: 16px;

      i {
        font-size: 20px;
      }
    }

    .DatePickerSubmit {
      display: none;
    }

    .slot-group {
      @include span-columns(14);
      @include pad(0 0 20px 0);

      .date {
        color: $darkblue;
        font-size: 15px;
        padding-bottom: 10px;
      }

      .checkable {
        margin-top: 0;
        margin-bottom: 0;
        @include pad(0px 0px);
      }
    }
  }
}

.SwapRemove {
  @include span-columns(14);
}
