@use "~@/common/scss/variables/theme";

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: theme.$border-radius;
  cursor: pointer;
  line-height: 1.5715;
  white-space: nowrap;
  touch-action: manipulation;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: none;

  &:focus:not(.disabled) {
    outline: 0;

    &::after {
      content: "";
      pointer-events: none;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.4;
      border-radius: inherit;
      animation-fill-mode: forwards;
    }
  }

  .spinner {
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    animation: slide-in 0.3s forwards, spin 0.6s infinite linear;
  }
}

.filled {
  &.default {
    background-color: theme.$lightgray;
    border: 1px solid theme.$gray;
    color: theme.$black;

    &:hover:not(.disabled) {
      background-color: darken(theme.$lightgray, 4%);
      border-color: darken(theme.$gray, 10%);
    }

    &:active:not(.disabled) {
      background-color: darken(theme.$lightgray, 8%);
      border-color: darken(theme.$gray, 15%);
    }

    &:focus:not(.disabled) {
      &::after {
        animation: fade-out 1.15s cubic-bezier(0.08, 0.82, 0.17, 1), default-wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
    }

    .spinner {
      border-color: rgba(theme.$darkgray, 0.25);
      border-top-color: rgba(theme.$darkgray, 1);
    }
  }

  &.primary {
    background-color: theme.$lightblue;
    border: 1px solid theme.$lightblue;
    color: theme.$white;

    &:hover:not(.disabled) {
      background-color: darken(theme.$lightblue, 10%);
      border-color: darken(theme.$lightblue, 10%);
    }

    &:active:not(.disabled) {
      background-color: darken(theme.$lightblue, 15%);
      border-color: darken(theme.$lightblue, 15%);
    }

    &:focus:not(.disabled) {
      &::after {
        animation: fade-out 1.15s cubic-bezier(0.08, 0.82, 0.17, 1), primary-wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
    }

    .spinner {
      border-color: rgba(theme.$white, 0.25);
      border-top-color: rgba(theme.$white, 1);
    }
  }

  &.secondary {
    background-color: theme.$orange;
    border: 1px solid theme.$orange;
    color: theme.$white;

    &:hover:not(.disabled) {
      background-color: darken(theme.$orange, 10%);
      border-color: darken(theme.$orange, 10%);
    }

    &:active:not(.disabled) {
      background-color: darken(theme.$orange, 15%);
      border-color: darken(theme.$orange, 15%);
    }

    &:focus:not(.disabled) {
      &::after {
        animation: fade-out 1.15s cubic-bezier(0.08, 0.82, 0.17, 1),
          secondary-wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
    }

    .spinner {
      border-color: rgba(theme.$white, 0.25);
      border-top-color: rgba(theme.$white, 1);
    }
  }
}

.outlined {
  &.default {
    background-color: theme.$white;
    border: 1px solid theme.$gray;
    color: theme.$black;

    &:hover:not(.disabled) {
      background-color: darken(theme.$white, 5%);
      border-color: darken(theme.$gray, 10%);
      color: darken(theme.$black, 10%);
    }

    &:active:not(.disabled) {
      border-color: darken(theme.$gray, 15%);
      color: darken(theme.$black, 15%);
    }

    &:focus:not(.disabled) {
      &::after {
        animation: fade-out 1.15s cubic-bezier(0.08, 0.82, 0.17, 1), default-wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
    }

    .spinner {
      border-color: rgba(theme.$darkgray, 0.25);
      border-top-color: rgba(theme.$darkgray, 1);
    }
  }

  &.primary {
    background-color: theme.$white;
    border: 1px solid theme.$lightblue;
    color: theme.$lightblue;

    &:hover:not(.disabled) {
      background-color: lighten(theme.$lightblue, 40%);
      border-color: darken(theme.$lightblue, 10%);
      color: darken(theme.$lightblue, 10%);
    }

    &:active:not(.disabled) {
      border-color: darken(theme.$lightblue, 15%);
      color: darken(theme.$lightblue, 15%);
    }

    &:focus:not(.disabled) {
      &::after {
        animation: fade-out 1.15s cubic-bezier(0.08, 0.82, 0.17, 1), primary-wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
    }

    .spinner {
      border-color: rgba(theme.$lightblue, 0.25);
      border-top-color: rgba(theme.$lightblue, 1);
    }
  }

  &.secondary {
    background-color: theme.$white;
    border: 1px solid theme.$orange;
    color: theme.$orange;

    &:hover:not(.disabled) {
      background-color: lighten(theme.$orange, 47%);
      border-color: darken(theme.$orange, 10%);
      color: darken(theme.$orange, 10%);
    }

    &:active:not(.disabled) {
      border-color: darken(theme.$orange, 15%);
      color: darken(theme.$orange, 15%);
    }

    &:focus:not(.disabled) {
      &::after {
        animation: fade-out 1.15s cubic-bezier(0.08, 0.82, 0.17, 1),
          secondary-wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
    }

    .spinner {
      border-color: rgba(theme.$orange, 0.25);
      border-top-color: rgba(theme.$orange, 1);
    }
  }
}

.text {
  background-color: transparent;
  border: none;
  min-width: 0 !important;
  padding: 0 !important;

  &.default {
    color: theme.$black;

    &:hover:not(.disabled) {
      color: darken(theme.$black, 15%);
    }

    .spinner {
      border-color: rgba(theme.$black, 0.25);
      border-top-color: rgba(theme.$black, 1);
    }
  }

  &.primary {
    color: theme.$lightblue;

    &:hover:not(.disabled) {
      color: darken(theme.$lightblue, 15%);
    }

    .spinner {
      border-color: rgba(theme.$lightblue, 0.25);
      border-top-color: rgba(theme.$lightblue, 1);
    }
  }

  &.secondary {
    color: theme.$orange;

    &:hover:not(.disabled) {
      color: darken(theme.$orange, 15%);
    }

    .spinner {
      border-color: rgba(theme.$orange, 0.25);
      border-top-color: rgba(theme.$orange, 1);
    }
  }

  &:hover:not(.disabled) {
    text-decoration: underline;
  }
}

.full-width {
  width: 100%;
}

.loading {
  opacity: 0.55;
  pointer-events: none;
  user-select: none;
  cursor: unset;
}

.disabled {
  opacity: 0.4;
  cursor: unset;
}

.small {
  height: 24px;
  min-width: 55px;
  font-size: 12px;
  padding: 0 7px;

  .spinner {
    width: 10px;
    height: 10px;
  }
}

.medium {
  height: 32px;
  min-width: 75px;
  font-size: 14px;
  padding: 4px 15px;

  .spinner {
    width: 12px;
    height: 12px;
  }
}

.large {
  height: 40px;
  min-width: 110px;
  font-size: 16px;
  padding: 6px 15px;

  .spinner {
    width: 14px;
    height: 14px;
  }
}

@keyframes default-wave {
  100% {
    box-shadow: 0 0 0 6px lighten(theme.$darkgray, 15%);
  }
}

@keyframes primary-wave {
  100% {
    box-shadow: 0 0 0 6px theme.$lightblue;
  }
}

@keyframes secondary-wave {
  100% {
    box-shadow: 0 0 0 6px theme.$orange;
  }
}

@keyframes fade-out {
  100% {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    margin-right: 0;
  }
  to {
    margin-right: 8px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
