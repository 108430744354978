@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

@include media($tablet) {
  .Dialog.BulkAction {
    width: 80%;
    margin-left: -40%;

    &.no-form {
      width: 40%;
      margin-left: -20%;
    }

    &.wide {
      width: 70%;
      margin-left: -35%;
    }
  }
}

@include media($mobile) {
  .Dialog.BulkAction {
    display: none;
  }
}
