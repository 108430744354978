$grid_headertype_color: rgba(22, 131, 215, 0.9);
$grid_headertooltip_color: rgba(0, 0, 0, 0.5);
$grid_headerweekday_color: rgba(62, 171, 255, 0.85);
$grid_headerweekend_color: rgba(42, 151, 255, 0.85);
$grid_oddrowheader_color: rgba(0, 0, 0, 0.025);
$grid_oddrowheader_color_solid: rgba(241, 241, 241, 0.98);
$grid_evenrowheader_color: rgba(0, 0, 0, 0.025);
$grid_evenrowheader_color_solid: rgba(236, 236, 236, 0.98);
$grid_darkrowheader_color: #dbd9d9;
$grid_lightrowheader_color: #f3f1f1;

$grid_oddrow_color: #f7f7f7;
$grid_evenrow_color: #f3f3f3;
$grid_darkrow_color: #e1e1e1;
$grid_lightrow_color: #f3f3f3;
$grid_row_hover_color: #7dbf03;

$grid_today_color: #7dbf03;

$noteindicator_color: #ffbf00;
$pendingindicator_color: #006fee;
$deniedindicator_color: #ff4c4c;
$deletedindicator_color: #663399;
$requestindicator_color: #468c00;

$grid_normalborder: 1px solid #888;
$grid_ganttborder: 1px solid #ccc;

$grid_headersize: 14px;
$grid_compactheadersize: 9px;
$grid_tooltipsize: 10px;

$viewer_black: #080e33;

$context_clues_background: #f6f3e3;
