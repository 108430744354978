/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.ScheduleEditsExt.TimeEditDialog {
  width: 240px;
  height: 200px;
  top: 15%;
  margin-left: -120px;

  & .content {
    @include span-columns(14);
    @include pad(20px 0 0px);
  }

  .header {
    @include span-columns(14);
    color: $gray1;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 10px 10px 10px 10px;
    display: none;
  }

  .timepicker {
    @include span-columns(14);
    text-align: center;
    margin-top: 20px;
  }
}
