/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.Dialog.Message {
  left: 50%;
  top: 20%;
  margin-left: -50%;
  width: 100%;
  height: 120px;
  animation: none;

  .message {
    font-size: 14px;
    padding: 20px;
    color: $gray1;
    text-transform: uppercase;
    text-align: center;
  }

  .link-container {
    text-align: center;
  }

  .loader {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 60%;
    left: 50%;
    margin-left: -10px;
  }

  .flipper {
    position: relative;
    display: block;
    height: inherit;
    width: inherit;
    animation: flip 1.2s infinite ease-in-out;
  }

  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    display: block;
    background-color: $darkblue;
    height: 100%;
    width: 100%;
  }

  &.no-loader {
    top: 30%;
    height: auto;
  }
}

@-webkit-keyframes flip {
  0% {
    @include transform(rotateX(0deg) rotateY(0deg));
  }

  50% {
    @include transform(rotateX(-180.1deg) rotateY(0deg));
  }

  100% {
    @include transform(rotateX(-180deg) rotateY(-179.9deg));
  }
}

@keyframes flip {
  0% {
    @include transform(rotateX(0deg) rotateY(0deg));
  }

  50% {
    @include transform(rotateX(-180.1deg) rotateY(0deg));
  }

  100% {
    @include transform(rotateX(-180deg) rotateY(-179.9deg));
  }
}

@import "./responsive";
