@import "bourbon.scss";
@import "neat.scss";
@import "grid-settings";
@import "variables";
@import "mixins";

.Feed {
  .options {
    background-color: $darkblue;

    .option {
      display: inline-block;
      padding: 5px 20px;
      text-transform: uppercase;
      font-size: 14px;
      color: $white;

      i {
        padding-right: 5px;
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
        background-color: $darkdarkblue;
      }

      &.active {
        background-color: $lightblue;
      }
    }
  }

  .feed-list {
    @include span-columns(14);
    @include pad(0px 0px 0px 0px);

    .loader {
      @include span-columns(14);
      @include pad(20px);
      text-align: center;

      i {
        font-size: 28px;
      }
    }

    .empty {
      @include pad(20px);
      @include span-columns(14);
      text-align: center;
      font-size: 14px;
      text-transform: uppercase;
    }

    .feed-items-container {
      border-left: 1px solid $lightlightlightgray;
      padding-bottom: 10px;

      .date {
        @include pad(5px 10px);
        margin-right: 25px;
        text-transform: uppercase;
        text-align: right;
        font-size: 14px;
        color: $gray1;
        background-color: $lightlightlightgray;
        animation: fadeIn 0.3s ease-out;
      }

      .feed-item {
        margin-right: 25px;
        border-bottom: 1px solid $lightlightlightgray;

        .feed-item-header {
          @include pad(5px 5px);

          .fa-stack {
            margin-right: 0px;
            vertical-align: top;

            &.pending {
              float: right;
            }
          }

          i {
            color: $lightgray;

            &.fa-square {
              color: $lightlightlightgray;
            }

            &.fa-paper-plane {
              color: $lightgray;
            }
          }

          .feed-item-header-text {
            display: inline-block;
            padding-left: 5px;

            .time {
              font-size: 10px;
            }

            .action-text {
              color: $gray1;
              text-transform: uppercase;
            }
          }
        }

        .message {
          @include pad(0px 8px 5px);

          .text {
            .blue {
              color: $lightblue;
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }

        animation: fadeIn 0.3s ease-out;
      }
    }

    @include lb-scrollbar($lightlightlightgray, #d2e7ae);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
