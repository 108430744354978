.interactive-icon {
  font-size: 15px !important;
  position: relative;
  top: -8px;
  left: -5px;
  width: 30px !important;
  height: 30px !important;
  text-align: center !important;
  background-color: transparent;

  &::before {
    position: relative;
    top: 25%;
    transition: all 100ms ease;
  }

  &--warning {
    color: rgb(221, 170, 4);
  }

  &--info {
    color: rgb(66, 114, 158);
  }

  &--help {
    color: rgb(11, 55, 131);
  }

  @keyframes pulseIcon {
    from {
      box-shadow: 0 0 5px currentColor;
    }

    to {
      box-shadow: 0 0 10px currentColor;
    }
  }

  &--pulse {
    &::before {
      border-radius: 50%;
      animation-name: pulseIcon;
      animation-duration: 750ms;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-fill-mode: forwards;
    }
  }
}
