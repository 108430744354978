/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.Dialog.AccountSettings {
  width: 50%;
  margin-left: -25%;

  .title-container {
    text-transform: uppercase;
    font-size: 16px;
    padding: 9px 10px;
    background-color: $darkblue;
    color: $white;

    i {
      padding-right: 10px;
    }

    & .close {
      background-color: $darkblue;
    }
  }

  .body-container {
    width: 100%;
    @include calc(height, "100% - 40px");
  }

  .setting-items {
    @include span-columns(4);
    min-height: 100%;
    background-color: $lightlightlightgray;

    button {
      text-transform: uppercase;
      width: 100%;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      color: $viewer_black;
      padding: 5px 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .setting-item {
    width: 100%;
    display: inline-block;
    text-align: left;
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: $darkblue;
      color: $white;
    }

    &.active {
      color: $white;
      background-color: $darkdarkdarkblue;
    }
  }

  .body-form {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    height: 100%;
    min-height: 100%;
    padding: 10px;
    background-color: $white;
    width: 300px;

    .action-bar {
      height: 20px;
      position: absolute;
      bottom: 0;
      right: 10px;
    }

    button {
      margin-right: 4px;
      text-transform: uppercase;
      font-size: 12px;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      color: $viewer_black;
      padding: 5px 5px;
      position: absolute;
      bottom: 10px;
      right: 10px;

      &:hover {
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;
        cursor: pointer;
      }
    }

    input[type="text"] {
      width: 100%;
    }

    label {
      font-weight: bold;
    }

    .action-bar {
      text-align: center;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .error-text {
      color: $red;
    }

    .success-text {
      color: $green;
    }

    .edit-exclude {
      color: $lightblue;

      &:hover {
        cursor: pointer;
      }
    }

    .error {
      border-color: red;
    }
  }

  .body-form__notificationEmails {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    height: 100%;
    min-height: 100%;
    padding: 10px;
    background-color: $white;

    .notificationEmailsContainer {
      word-break: break-all;
    }

    .action-bar {
      height: 20px;
      position: absolute;
      bottom: 0;
      right: 10px;
    }

    button {
      margin-right: 4px;
      text-transform: uppercase;
      font-size: 12px;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;
      color: $viewer_black;
      padding: 5px 5px;
      position: absolute;
      bottom: 10px;
      right: 10px;

      &:hover {
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;
        cursor: pointer;
      }
    }

    input[type="text"] {
      width: 100%;
    }

    label {
      font-weight: bold;
    }

    .action-bar {
      text-align: center;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    .error-text {
      color: $red;
    }

    .success-text {
      color: $green;
    }

    .edit-exclude {
      color: $lightblue;

      &:hover {
        cursor: pointer;
      }
    }

    .error {
      border-color: red;
    }
  }
}
