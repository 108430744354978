@import "../_lib/ui/styles/variables";

.search-bar {
  position: relative;

  .magnifying-glass {
    position: absolute;
    left: 9px;
    top: 6px;
    font-size: 18px;
    color: $lightgray;
  }
  .search-input {
    height: 46px;
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.3333333;
    width: 100%;
    border-radius: 10px;
    height: 30px;
    padding: 10px;
    background-color: $lightlightlightgray;
    border: 1px solid $lightlightgray;
    padding: 0px 40px 0px 35px;
    &:focus {
      outline: none;
    }
  }
  .search-clear {
    text-decoration: none;
    position: absolute;
    right: 8px;
    top: 3px;
    font-size: 18px;
    color: $lightlightgray;
    &:hover {
      color: $darkblue;
    }
  }
}
