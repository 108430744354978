/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

@include media($mobile) {
  .Dialog.RequestDetails {
    width: 320px;
    height: auto;
    top: 5%;
    margin-left: -160px;
    pointer-events: all;

    .mobile-section-nav {
      @include span-columns(3);
    }

    .content {
      .stages-container {
        .stages-item {
          @include span-columns(4);
        }
      }
    }

    & .footer {
      .edit-button {
        @include span-columns(2);
      }
    }
  }
}
