@use "~@/common/scss/variables/theme";
@use "~@/common/scss/mixins/breakpoint";

.root {
  height: 325px;
  font-size: 14px;

  .details {
    padding: 8px 28px;
  }

  .content {
    min-height: 250px;
    padding: 16px 28px;
  }
}
