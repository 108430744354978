/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.SlotHistory {
  width: 300px;
  height: auto;
  top: 8%;
  margin-left: -150px;

  & .close {
    &:hover {
      color: $gray1;
    }
  }

  .content {
    @include span-columns(14);
    color: $gray1;

    .header {
      @include span-columns(12);
      @include pad(10px 10px 5px);
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
        margin-right: 5px;
      }
    }

    .num-entries {
      @include span-columns(12);
      @include pad(5px 10px);
      font-size: 12px;
      background-color: $lightlightlightgray;
      text-transform: uppercase;
    }

    .slot-history-container {
      @include span-columns(14);
      @include pad(5px 0 20px);
      max-height: 350px;
      overflow-y: auto;

      .slot-history-item {
        @include span-columns(14);
        @include pad(5px 10px 5px);

        .slot-history-item-date {
          @include span-columns(14);
          text-transform: uppercase;
          text-decoration: underline;
        }

        .slot-history-item-content {
          @include span-columns(14);
          font-size: 13px;
        }
      }

      @include lb-scrollbar(transparent, rgba(38, 148, 232, 0.2));
    }
  }
}
