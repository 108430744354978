/* topbar styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.LogoMenu {
  position: absolute;
  top: 40px;
  left: 5px;
  background-color: $white;
  z-index: 2;
  padding-top: 5px;
  min-width: 225px;
  display: none;

  &.active {
    display: block;
    animation: bounce 0.2s ease-out;
  }

  @include dialog-shadow();

  a {
    text-decoration: none;
  }

  .LogoMenuItem {
    background-color: $white;
    color: $darkblue;
    text-transform: uppercase;
    padding: 10px 25px;
    vertical-align: top;
    border-left: 2px solid $white;

    .title {
      font-size: 16px;
      color: $darkdarkdarkblue;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .items {
      .item {
        font-size: 12px;
        color: $lightgray;
        display: inline-block;
        padding: 5px 10px 5px 0px;

        &:hover {
          color: $viewer_black;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    i {
      width: 20px;
      font-size: 14px;

      &.fa-square.purple {
        color: #ffb7f2;
      }

      &.fa-square.yellow {
        color: #feff84;
      }
    }

    &:hover {
      border-left: 2px solid $darkblue;
    }
  }

  &:before {
    content: "";
    @include triangle(10px, $white, up);
    position: absolute;
    top: -5px;
    left: 10px;
  }
}
