/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.ScheduleEditsExt {
  & .bottom-buttons {
    position: absolute;
    bottom: 0;
    @include span-columns(14);

    & .submit {
      @include span-columns(14);
      @include pad(15px);
      text-align: center;

      i {
        padding: 10px;
        border-radius: 50%;
        background-color: #5bbf19;
        color: $white;
        font-size: 32px;

        &:hover {
          background-color: $green;
          cursor: pointer;
        }

        &.disabled {
          background-color: $extremelightgray;
          border: 1px solid $lightlightlightgray;
          color: $lightgray;

          &:hover {
            background-color: $extremelightgray;
            border: 1px solid $lightlightlightgray;
            color: $lightgray;
            cursor: default;
          }
        }
      }
    }
  }

  & .bottom-controls {
    @include up-shadow();
    width: 100%;
    height: 65px;
    background-color: $extremelightgray;
    position: absolute;
    bottom: 0px;

    & .left-side {
      position: absolute;
      left: 0;
      width: 100px;
      height: 100%;
    }

    & .right-side {
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
    }

    span.step,
    span.submit {
      @include pad(10px);
      display: block;
      text-align: center;
      font-size: 13px;
      text-transform: uppercase;
      color: $viewer_black;
      background-color: $lightlightlightgray;

      i {
        display: block;
        font-size: 22px;
        padding-bottom: 5px;
      }

      &:hover {
        background-color: #e5e5e5;
        cursor: pointer;
      }
    }

    span.submit {
      background-color: #5bbf19;
      color: $white;

      &:hover {
        background-color: $green;
      }
    }
  }
}
