/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

@include media($mobile) {
  .Dialog.Feedback {
    width: 95%;
    margin-left: -47.5%;
    height: 90%;
    top: 20px;

    p {
      padding: 5px 20px 5px 20px;
      font-size: 12px;
    }
  }
}
