.buttons {
  .button-list {
    .button-list-item {
      display: inline-block;
      list-style: none;
      margin-bottom: -1px;
      padding: 0.2rem 0.4rem;
      cursor: pointer;
      border: 1px solid rgba(16, 22, 26, 0.2);

      &.active-button {
        color: #fff;
        background-color: #0c8eff;
        border: 1px solid #007aff;
        cursor: unset;
      }

      &:first-child {
        border-radius: 8px 0px 0px 8px;
        padding: 5px 12px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
        padding: 5px 12px;
      }
    }
  }

  @media screen and (max-width: 767px) and (min-width: 0) {
    text-align: center;
    left: unset;
    right: unset;
    top: unset;
    position: static;

    .button-list {
      padding: unset;

      .button-list-item:last-child,
      .button-list-item:first-child {
        padding: 5px 36px;
      }
    }
  }
}
