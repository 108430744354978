/* dialog styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables";
@import "mixins";

.Dialog.ExtMenu {
  top: 5%;
  min-height: 90%;
  width: 70%;
  margin-left: -35%;

  .content {
    height: 100%;
    position: relative;
    @include outer-container;
    @include pad(5px 5px 5px 5px);

    .header {
      @include span-columns(13);
      color: $gray1;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      padding: 5px 5px 5px 5px;
      border-bottom: 1px solid #dbdbdb;
      position: relative;
    }

    .requests-container {
      @include span-columns(9);
      @include calc(height, "100% - 125px");
    }

    .RequestsList {
      height: 100%;
      @include span-columns(14);
      @include pad(0 10px 0 0);
      margin-top: 10px;
      height: 100%;

      .tabs {
        @include span-columns(14);
        color: $white;
        font-size: 14px;
        line-height: 23px;
        text-transform: uppercase;
        background-color: #ffb566;
        position: relative;

        & .new {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
          color: $viewer_black;
          padding: 2px 5px;
          font-size: 14px;
          float: right;
          margin-top: -3px;

          > i {
            padding-right: 2px;
          }

          &:hover {
            background-color: $lightblue;
            border: 1px solid $darkblue;
            color: $white;
            cursor: pointer;
          }
        }

        & .tab {
          padding: 8px 10px;
          display: inline-block;
          text-decoration: line-through;
          color: $gray1;

          &.active {
            background-color: #ffb566;
            text-decoration: none;
            color: $white;
          }

          &:hover {
            background-color: #ffce99;
            cursor: pointer;
          }
        }

        .mode {
          @include span-columns(1);
          display: none;
          color: $viewer_black;

          i {
            display: inline-block;
            padding: 7px 5px 5px;
            font-size: 20px;

            &:hover {
              background-color: $lightlightgray;
              cursor: pointer;
            }
          }

          .controls {
            position: relative;

            .Options {
              position: absolute;
              left: -2px;
              top: 2px;
              width: 250px;
              min-height: 200px;
              z-index: 2;
              background-color: rgba(255, 255, 255, 1);
              @include flydown-shadow;
              padding: 10px;
              display: none;

              .title {
                width: 100%;
                font-size: 12px;
                border-bottom: 1px solid $lightlightgray;
                padding: 0px 10px;
              }

              .values {
                font-size: 12px;
                padding: 5px 0px;

                .item {
                  display: inline-block;

                  span.text {
                    padding: 2px 10px;
                  }

                  .date {
                    .DatePicker {
                      position: absolute;
                      left: 20px;
                      top: 20px;
                      width: 250px;
                      min-height: 200px;
                      z-index: 2;
                      background-color: rgba(255, 255, 255, 1);
                      @include flydown-shadow;
                      padding: 5px;
                      display: none;

                      .DatePickerControls {
                        font-size: 12px;

                        i {
                          font-size: 14px;
                        }
                      }

                      .DatePickerCalendarHeader {
                        display: none;
                      }
                    }

                    &.active {
                      .DatePicker {
                        display: block;
                      }
                    }
                  }

                  &:hover {
                    background-color: $lightlightlightgray;
                    cursor: pointer;
                  }

                  &.active {
                    background-color: $lightblue;
                    color: $white;
                  }
                }
              }

              .list-item {
                padding: 5px 10px;
                font-size: 12px;
                text-transform: uppercase;
                color: $viewer_black;

                &:hover {
                  cursor: pointer;
                  background-color: rgba(0, 0, 0, 0.1);
                }

                &.inactive {
                  color: $lightgray;
                }

                & i {
                  padding-right: 10px;
                }
              }

              &:before {
                content: "";
                @include triangle(12px, #fff, up);
                position: absolute;
                top: -6px;
                left: 8px;
              }
            }

            & .submit {
              background-color: $lightlightlightgray;
              border: 1px solid $lightlightgray;
              color: $viewer_black;
              padding: 2px 5px;
              font-size: 12px;
              margin-top: 20px;
              text-align: center;

              > i {
                display: none;
                padding-right: 2px;
              }

              &:hover {
                background-color: $lightblue;
                border: 1px solid $darkblue;
                color: $white;
                cursor: pointer;
              }

              &.disabled {
                background-color: $extremelightgray;
                border: 1px solid $lightlightlightgray;
                color: $lightgray;

                &:hover {
                  background-color: $extremelightgray;
                  border: 1px solid $lightlightlightgray;
                  color: $lightgray;
                  cursor: default;
                }
              }
            }

            &.active {
              .Options {
                display: block;
              }
            }
          }
        }

        .date-control {
          position: absolute;
          top: 0px;
          right: 5px;
          padding: 6px 0px;

          & .month {
            display: inline-block;
            padding: 0 5px;
          }

          i {
            display: inline-block;
            padding: 5px 10px;
            font-size: 16px;

            &:hover {
              background-color: $orange;
              cursor: pointer;
            }
          }
        }

        .sub-header {
          @include span-columns(14);
          @include pad(5px 5px);
          background-color: #ffb566;
          color: $white;
          position: relative;
          display: none;

          .date-control {
            @include span-columns(14);
            text-align: right;

            & .month {
              display: inline-block;
              padding: 0 5px;
            }

            i {
              display: inline-block;
              padding: 5px 10px;
              font-size: 16px;

              &:hover {
                background-color: $orange;
              }
            }
          }

          .filter {
            @include span-columns(2);
            display: none;
            color: $viewer_black;

            i {
              display: inline-block;
              padding: 7px 5px 5px;
              font-size: 20px;

              &:hover {
                background-color: $lightlightgray;
                cursor: pointer;
              }
            }

            .controls {
              position: relative;

              .Options {
                position: absolute;
                left: -2px;
                top: 2px;
                width: 250px;
                min-height: 200px;
                z-index: 2;
                background-color: rgba(255, 255, 255, 1);
                @include flydown-shadow;
                padding: 10px;
                display: none;

                .title {
                  width: 100%;
                  font-size: 12px;
                  border-bottom: 1px solid $lightlightgray;
                  padding: 0px 10px;
                }

                .values {
                  font-size: 12px;
                  padding: 5px 0px;

                  .item {
                    display: inline-block;

                    span.text {
                      padding: 2px 10px;
                    }

                    .date {
                      .DatePicker {
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        width: 250px;
                        min-height: 200px;
                        z-index: 2;
                        background-color: rgba(255, 255, 255, 1);
                        @include flydown-shadow;
                        padding: 5px;
                        display: none;

                        .DatePickerControls {
                          font-size: 12px;

                          i {
                            font-size: 14px;
                          }
                        }

                        .DatePickerCalendarHeader {
                          display: none;
                        }
                      }

                      &.active {
                        .DatePicker {
                          display: block;
                        }
                      }
                    }

                    &:hover {
                      background-color: $lightlightlightgray;
                      cursor: pointer;
                    }

                    &.active {
                      background-color: $lightblue;
                      color: $white;
                    }
                  }
                }

                .list-item {
                  padding: 5px 10px;
                  font-size: 12px;
                  text-transform: uppercase;
                  color: $viewer_black;

                  &:hover {
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.1);
                  }

                  &.inactive {
                    color: $lightgray;
                  }

                  & i {
                    padding-right: 10px;
                  }
                }

                &:before {
                  content: "";
                  @include triangle(12px, #fff, up);
                  position: absolute;
                  top: -6px;
                  left: 8px;
                }
              }

              & .submit {
                background-color: $lightlightlightgray;
                border: 1px solid $lightlightgray;
                color: $viewer_black;
                padding: 2px 5px;
                font-size: 12px;
                margin-top: 20px;
                text-align: center;

                > i {
                  display: none;
                  padding-right: 2px;
                }

                &:hover {
                  background-color: $lightblue;
                  border: 1px solid $darkblue;
                  color: $white;
                  cursor: pointer;
                }

                &.disabled {
                  background-color: $extremelightgray;
                  border: 1px solid $lightlightlightgray;
                  color: $lightgray;

                  &:hover {
                    background-color: $extremelightgray;
                    border: 1px solid $lightlightlightgray;
                    color: $lightgray;
                    cursor: default;
                  }
                }
              }

              &.active {
                .Options {
                  display: block;
                }
              }
            }
          }

          .search {
            @include span-columns(14);
            text-align: left;
            display: none;

            i {
              font-size: 16px;
              background-color: #ffce99;
              border-right: none;
              color: $white;
              padding: 12px 10px 12px 10px;
            }

            input {
              border: none;
              height: 40px;
              padding: 10px;
              font-size: 14px;
              vertical-align: top;
              color: $white;
              width: 262px;
              background-color: #ffce99;

              &:focus {
                outline: none;
                background-color: $orange;
              }
            }
          }
        }
      }

      .loader {
        @include span-columns(14);
        text-align: left;
        margin-top: 20px;
        margin-left: 5px;

        i {
          font-size: 24px;
        }
      }

      .items-container {
        @include span-columns(14);
        @include calc(height, "100% - 15px");
        margin-top: 5px;
        overflow-y: scroll;
        @include pad(0px 5px 0 0);

        .items-date-group-container {
          @include span-columns(14);

          .date-header {
            @include pad(5px 10px);
            text-transform: uppercase;
            text-align: right;
            color: $gray1;
            background-color: $lightlightlightgray;
          }
        }

        .Item {
          @include span-columns(14);
          margin-right: 5px;
          padding: 5px 5px 5px 70px;
          border-left: 1px solid $lightlightlightgray;
          border-bottom: 1px solid $lightlightlightgray;
          position: relative;

          div.type {
            @include span-columns(1);
            text-align: center;
            min-height: 20px;
          }

          div.icons {
            position: absolute;
            top: 9px;
            left: 5px;
            width: 75px;
          }

          div.icon-status {
            display: inline-block;
            margin-left: -7px;

            .fa-stack {
              margin-right: 0px;
              vertical-align: top;
            }

            i {
              color: $lightgray;

              &.fa-square {
                color: $lightlightlightgray;
              }

              &.fa-paper-plane {
                color: $lightgray;
              }

              &.fa-question {
                color: $lightblue;
              }

              &.fa-thumbs-o-up {
                color: $green;
              }

              &.fa-thumbs-o-down {
                color: red;
              }
            }
          }

          div.icon-type {
            display: inline-block;
            margin-left: -7px;

            .fa-stack {
              margin-right: 0px;
              vertical-align: top;
            }

            i {
              color: $lightgray;

              &.fa-square {
                color: $lightlightlightgray;
              }

              &.fa-paper-plane {
                color: $lightgray;
              }

              &.fa-question {
                color: $lightblue;
              }

              &.fa-thumbs-o-up {
                color: $green;
              }

              &.fa-thumbs-o-down {
                color: red;
              }

              &.fa-ban {
                color: rgba(255, 0, 0, 0.25);
              }
            }
          }

          div.info {
            @include span-columns(14);
            padding: 2px 0px 2px 10px;

            &:hover {
              background-color: $postit;
              cursor: pointer;
            }
          }

          div.actions {
            text-align: right;
            @include span-columns(2);
            display: none;

            i.fa-trash-o {
              font-size: 18px;
              height: 100%;
              padding: 5px;

              &:hover {
                color: $gray1;
              }
            }
          }

          span.date {
            font-size: 12px;
            display: block;
          }

          span.assignment {
            color: $gray1;

            i {
              padding-right: 5px;
            }
          }

          span.message {
            margin-top: 4px;
            vertical-align: middle;
            display: inline-block;
            background-color: rgba(0, 0, 0, 0.05);
            padding: 5px 10px 5px 20px;
          }

          span.command {
            font-size: 12px;
            text-transform: lowercase;
          }

          span.status {
            @include span-columns(12);
            padding-top: 0px;

            span.out,
            span.in {
              @include span-columns(14);
            }

            i {
              font-size: 12px;
            }
          }

          span.delete {
            vertical-align: middle;
            display: inline-block;
            padding: 10px;
            width: 24px;
            height: 24px;

            &:hover {
              cursor: pointer;
            }
          }

          &.granted {
            div.indicator {
              height: 32px;
            }

            div.icon-status {
              i {
                &.fa-square {
                  color: $green;
                }

                &.fa-thumbs-o-up {
                  color: $white;
                }
              }
            }
          }

          &.denied {
            div.indicator {
              height: 32px;
            }

            div.icon-status {
              i {
                &.fa-square {
                  color: rgba(255, 0, 0, 0.75);
                }

                &.fa-thumbs-o-down {
                  color: $white;
                }
              }
            }
          }

          &.suspended {
            div.indicator {
              height: 32px;
            }

            div.icon-status {
              i {
                &.fa-square {
                  color: $lightblue;
                }

                &.fa-thumbs-o-up {
                  color: $white;
                }
              }
            }
          }

          &.pending {
            div.indicator {
              height: 32px;
            }

            div.icon-status {
              i {
                &.fa-square {
                  color: $lightblue;
                }

                &.fa-question {
                  color: $white;
                }
              }
            }
          }

          &.deleted {
            div.indicator {
              height: 32px;
            }

            div.icon-status {
              i {
                &.fa-square {
                  color: $purple;
                }

                &.fa-trash {
                  color: $white;
                }
              }
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }

        @include lb-scrollbar($lightlightlightgray, $lightlightgray);
      }
    }

    .feed-container {
      @include span-columns(7);
      @include pad(10px 5px 0 0);
      height: 100%;

      .feed {
        height: 100%;

        .feed-title {
          @include span-columns(14);
          text-align: center;
          color: $white;
          font-size: 14px;
          text-transform: uppercase;
          padding: 10px 5px;
          background-color: #a1cd54;
          position: relative;

          .filter {
            position: absolute;
            left: 5px;
            top: 3px;
            color: $white;

            i {
              display: inline-block;
              padding: 7px 10px 6px;
              font-size: 20px;

              &:hover {
                background-color: $green;
                cursor: pointer;
              }
            }

            .controls {
              position: relative;
              color: $viewer_black;

              .Options {
                position: absolute;
                left: -2px;
                top: 2px;
                width: 150px;
                min-height: 150px;
                text-align: left;
                z-index: 2;
                background-color: rgba(255, 255, 255, 1);
                @include flydown-shadow;
                padding: 10px;
                display: none;

                .title {
                  width: 100%;
                  font-size: 12px;
                  border-bottom: 1px solid $lightlightgray;
                  padding: 0px 5px;
                }

                .values {
                  font-size: 12px;
                  padding: 5px 0px;

                  .item {
                    display: inline-block;

                    span.text {
                      display: block;
                      padding: 5px 5px;
                    }

                    .date {
                      .DatePicker {
                        position: absolute;
                        left: 20px;
                        top: 20px;
                        width: 250px;
                        min-height: 200px;
                        z-index: 2;
                        background-color: rgba(255, 255, 255, 1);
                        @include flydown-shadow;
                        padding: 5px;
                        display: none;

                        .DatePickerControls {
                          font-size: 12px;

                          i {
                            font-size: 14px;
                          }
                        }

                        .DatePickerCalendarHeader {
                          display: none;
                        }
                      }

                      &.active {
                        .DatePicker {
                          display: block;
                        }
                      }
                    }

                    &:hover {
                      background-color: $lightlightlightgray;
                      cursor: pointer;
                    }

                    &.active {
                      background-color: $lightblue;
                      color: $white;
                    }
                  }
                }

                .list-item {
                  padding: 5px 10px;
                  font-size: 12px;
                  text-transform: uppercase;
                  color: $viewer_black;

                  &:hover {
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, 0.1);
                  }

                  &.inactive {
                    color: $lightgray;
                  }

                  & i {
                    padding-right: 10px;
                  }
                }

                &:before {
                  content: "";
                  @include triangle(12px, #fff, up);
                  position: absolute;
                  top: -6px;
                  left: 8px;
                }
              }

              & .submit {
                position: absolute;
                bottom: 10px;
                background-color: $lightlightlightgray;
                border: 1px solid $lightlightgray;
                color: $viewer_black;
                padding: 2px 10px;
                width: 130px;
                font-size: 12px;
                text-align: center;

                > i {
                  display: none;
                  padding-right: 2px;
                }

                &:hover {
                  background-color: $lightblue;
                  border: 1px solid $darkblue;
                  color: $white;
                  cursor: pointer;
                }

                &.disabled {
                  background-color: $extremelightgray;
                  border: 1px solid $lightlightlightgray;
                  color: $lightgray;

                  &:hover {
                    background-color: $extremelightgray;
                    border: 1px solid $lightlightlightgray;
                    color: $lightgray;
                    cursor: default;
                  }
                }
              }

              &.active {
                .Options {
                  display: block;
                }
              }
            }
          }
        }

        .feed-list {
          @include span-columns(14);
          @include pad(5px 0px 0px 0px);
          @include calc(height, "100% - 75px");
          overflow-y: scroll;

          .loader {
            @include span-columns(14);
            @include pad(20px);
            text-align: center;

            i {
              font-size: 28px;
            }
          }

          .empty {
            @include pad(20px);
            @include span-columns(14);
            text-align: center;
          }

          .feed-items-container {
            border-left: 1px solid $lightlightlightgray;

            .date {
              @include pad(5px 10px);
              margin-right: 5px;
              text-transform: uppercase;
              text-align: right;
              color: $gray1;
              background-color: $lightlightlightgray;
              animation: fadeIn 0.3s ease-out;
            }

            .feed-item {
              margin-right: 5px;
              border-bottom: 1px solid $lightlightlightgray;

              .feed-item-header {
                @include pad(5px 5px);

                .fa-stack {
                  margin-right: 0px;
                  vertical-align: top;

                  &.pending {
                    float: right;
                  }
                }

                i {
                  color: $lightgray;

                  &.fa-square {
                    color: $lightlightlightgray;
                  }

                  &.fa-paper-plane {
                    color: $lightgray;
                  }
                }

                .feed-item-header-text {
                  display: inline-block;
                  padding-left: 5px;

                  .time {
                    font-size: 10px;
                  }

                  .action-text {
                    color: $gray1;
                    text-transform: uppercase;
                  }
                }
              }

              .message {
                @include pad(0px 8px 5px);

                .text {
                  .blue {
                    color: $lightblue;
                  }
                }
              }

              animation: fadeIn 0.3s ease-out;
            }
          }

          @include lb-scrollbar($lightlightlightgray, #d2e7ae);
        }
      }
    }

    .sections {
      @include span-columns(5);
      @include pad(10px 0px);

      .section {
        .section-title {
          @include span-columns(14);
          padding: 10px 15px;
          font-size: 14px;
          text-transform: uppercase;
          text-align: center;
        }

        .buttons {
          @include span-columns(14);
          @include pad(5px 5px);

          .button {
            @include span-columns(6 of 12);
            font-size: 12px;
            text-transform: uppercase;
            color: $white;
            text-align: center;
            padding: 10px;

            i {
              display: block;
              width: 100%;
              font-size: 32px;
              padding-bottom: 10px;
            }

            &:hover {
              background-color: $postit;
              color: $viewer_black;
              cursor: pointer;
            }
          }
        }

        &.requests {
          .section-title {
            background-color: #74bcff;
            color: $white;
          }

          .buttons {
            background-color: #a5d4ff;
          }
        }

        &.navigate {
          .section-title {
            background-color: #6395bc;
            color: $white;
          }

          .buttons {
            background-color: #a8cce7;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: right;
    padding: 10px 10px 10px;

    span.feedback-link {
      color: $lightblue;
      text-decoration: underline;
      cursor: pointer;
    }

    .item {
      padding-top: 5px;
    }

    a.refer-link {
      color: $lightblue;
    }
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@import "./responsive";
