@import "neat";

// Change the grid settings
$column: 120px;
$gutter: 0px;
$grid-columns: 14;
$max-width: em(3000);

$fg-column: $column;
$fg-gutter: $gutter;

// Define your breakpoints
$desktop: new-breakpoint(min-width 992px $grid-columns);
$tablet: new-breakpoint(min-width 768px max-width 991px 8);
$mobile: new-breakpoint(min-width 0px max-width 767px 4);
