@use "~@/common/scss/variables/theme";

.root {
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s;

  .input {
    font-family: theme.$font;
    border: 1px solid theme.$light-border;
    color: theme.$black;
    background-color: theme.$white;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 2px;
    transition: all 0.3s;

    &::placeholder {
      color: theme.$black;
      opacity: 0.4;
    }

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    &:hover {
      border-color: theme.$lightblue;
    }

    &:focus {
      border-color: theme.$lightblue;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      outline: 0;
    }
  }

  .medium {
    padding: 7px 11px;
  }

  .small {
    padding: 3px 9px;
  }

  .error {
    border-color: theme.$dark-error;

    &:hover {
      border-color: theme.$dark-error;
    }

    &:focus {
      border-color: theme.$dark-error;
      box-shadow: 0 0 0 2px rgba(226, 104, 104, 0.2);
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  .input {
    border-color: theme.$light-border !important;
  }
}
