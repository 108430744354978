/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.swap-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;

  div.container-middle {
    position: relative;
    height: 100%;

    .loading-container {
      @include pad(200px 0);
      text-align: center;
      font-size: 48px;
      color: $white;
    }
  }
}

.Dialog {
  overflow: hidden;

  & .loading-container {
    @include span-columns(14);
    @include pad(50px 40px);
    height: 100%;
    text-align: center;

    i {
      font-size: 28px;
      color: $lightblue;
    }
  }

  .header-container {
    @include span-columns(14);

    & .header {
      @include span-columns(14);
      padding: 5px;
      color: $gray1;
      font-size: 14px;
      line-height: 23px;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .mobile-section-nav {
      @include span-columns(14);

      .mobile-section-nav-tab {
        display: inline-block;
        @include pad(10px 10px);
        font-size: 24px;
        color: $darkdarkdarkblue;

        &.group-2 {
          display: none;
        }

        &.active {
          color: $darkdarkdarkblue;
        }
      }
    }
  }

  & .details {
    & .swap-sentences {
      @include span-columns(14);
      @include pad(0px 0px 20px 0px);
      height: 100%;
      overflow-y: scroll;
      @include lb-scrollbar(transparent, $lightlightgray);

      & .sentence-container {
        @include span-columns(13.5);
        @include pad(10px 5px 10px 13px);

        &:nth-child(2n) {
          background-color: $lightlightlightgray;
        }
      }

      & .swap-num {
        @include span-columns(2);
        @include pad(0px 0px 0);
        height: 100%;
        color: $lightgray;
        font-size: 15px;
        text-align: left;
      }

      & .sentence {
        @include span-columns(12);
      }
    }

    & .sentence-container {
      & .sentence {
        font-size: 12px;

        .value {
          color: $gray1;
          font-size: 12px;
        }

        i {
          font-size: 16px;
          color: $gray1;
        }
      }

      span.underline {
        text-decoration: underline;
      }

      span.semi-large {
        color: $gray1;
        font-size: 13px;
      }

      span.large {
        color: $gray1;
        font-size: 14px;
      }

      span.uppercase {
        text-transform: uppercase;
      }

      span.dark {
        color: $gray1;
      }

      span.gray {
        color: $lightgray;
      }
    }
  }

  & .bottom-controls {
    @include up-shadow();
    width: 100%;
    height: 65px;
    background-color: $extremelightgray;
    position: absolute;
    bottom: 0px;

    & .left-side {
      position: absolute;
      left: 0;
      width: 100px;
      height: 100%;
    }

    & .right-side {
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
    }

    span.step,
    span.submit {
      @include pad(10px);
      display: block;
      text-align: center;
      font-size: 13px;
      text-transform: uppercase;
      color: $viewer_black;
      background-color: $lightlightlightgray;

      i {
        display: block;
        font-size: 22px;
        padding-bottom: 5px;
      }

      &:hover {
        background-color: #e5e5e5;
        cursor: pointer;
      }
    }

    span.submit {
      background-color: #5bbf19;
      color: $white;

      &:hover {
        background-color: $green;
      }
    }
  }

  & .editables {
    @include span-columns(14);
    @include pad(5px 10px);

    &.xpad {
      @include pad(5px 17px);
    }

    &.ypad {
      @include pad(10px 10px);
    }

    & .editables-title {
      @include span-columns(14);
      color: $darkblue;
      font-size: 16px;
      padding-bottom: 5px;
      display: none;
    }

    .selectable {
      @include span-columns(14);
      margin-bottom: 2px;
      cursor: pointer;
      padding: 0px 6px;

      .selectable-label {
        @include span-columns(4);
        text-transform: none;
        color: $darkblue;
        font-size: 13px;
        line-height: 31px;
      }

      .selectable-value {
        @include span-columns(10);
        font-size: 13px;
        padding: 5px 10px;
        background-color: $white;
        border: 1px solid $lightlightgray;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
        }
      }

      &.two-line {
        margin-bottom: 5px;
        background-color: $white;
        padding: 5px 6px;

        & .selectable-label {
          @include span-columns(14);
          color: $darkblue;
          font-size: 13px;
          line-height: 22px;
        }

        & .selectable-value {
          @include span-columns(14);
          background-color: inherit;
          border: none;
          padding: 0;
          font-size: 16px;
          color: $gray1;
          text-decoration: underline;

          &:hover {
            background-color: transparent;
            border: none;
          }
        }

        &:hover {
          background-color: $lightlightlightgray;
        }
      }

      &.disabled {
        cursor: default;

        .selectable-label {
          color: $darkblue;
        }

        .selectable-value {
          color: $lightgray;
          text-decoration: none;
        }

        &:hover {
          background-color: $white;
        }
      }
    }

    .textable {
      @include span-columns(14);
      padding: 0px 6px;
      margin-bottom: 5px;

      .textable-label {
        @include span-columns(4);
        text-transform: none;
        color: $darkblue;
        font-size: 13px;
        line-height: 31px;
      }

      .textable-value {
        @include span-columns(9);
        @include pad(5px 10px);
        font-size: 13px;
        background-color: $white;
        border: 1px solid $lightlightgray;
        height: 2.1em;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
        }
      }

      i.fa.fa-ellipsis-v {
        @include span-columns(0.75);
        @include shift(0.25);
        @include pad(4px 5px 3px);
        font-size: 20px;
        color: $lightgray;

        &:hover {
          background-color: $lightgray;
          color: $white;
          cursor: pointer;
        }
      }
    }

    .customizable {
      @include span-columns(14);
      padding: 0px 6px;
      margin-bottom: 5px;

      .custom-label {
        @include span-columns(4);
        text-transform: none;
        color: $darkblue;
        font-size: 13px;
        line-height: 13px;
      }

      .custom-value {
        @include span-columns(10);
        @include pad(5px 10px);
        font-size: 13px;
        background-color: $white;
        border: 1px solid $lightlightgray;
        height: 2.1em;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
        }
      }
    }

    .timeable {
      @include span-columns(14);
      margin-bottom: 2px;
      padding: 0px 6px;

      .timeable-label {
        @include span-columns(4);
        text-transform: none;
        color: $darkblue;
        font-size: 13px;
        line-height: 32px;
      }

      .timeable-value {
        @include span-columns(10);
        @include pad(0);
        line-height: 32px;
        overflow: hidden;

        & .label {
          vertical-align: top;
          padding-left: 25px;
          font-size: 13px;
        }

        & .value {
          padding-right: 20px;
          position: relative;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          /* Hide the browser's default radio button */
          & input {
            position: absolute;
            opacity: 0;
          }

          /* Create a custom radio button */
          & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: #eee;
            border-radius: 50%;
          }

          /* On mouse-over, add a grey background color */
          & input ~ .checkmark:hover {
            background-color: #ccc;
          }

          /* When the radio button is checked, add a blue background */
          & input:checked ~ .checkmark {
            background-color: #2196f3;
          }

          /* Create the indicator (the dot/circle - hidden when not checked) */
          & .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          & input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          & .checkmark:after {
            top: 6px;
            left: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: white;
          }
        }
      }
    }

    .checkable {
      @include span-columns(14);
      @include pad(0px 6px);
      margin-top: 10px;
      margin-bottom: 2px;

      .checkable-value {
        & .label {
          vertical-align: top;
          padding-left: 30px;
          font-size: 13px;
        }

        & .value {
          font-size: 14px;
          display: block;
          position: relative;
          line-height: 18px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          /* Hide the browser's default checkbox */
          & input {
            position: absolute;
            opacity: 0;
          }

          /* Create a custom checkbox */
          & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 18px;
            width: 18px;
            background-color: #eee;
          }

          /* On mouse-over, add a grey background color */
          & input ~ .checkmark:hover {
            background-color: #ccc;
          }

          /* When the checkbox is checked, add a blue background */
          & input:checked ~ .checkmark {
            background-color: #2196f3;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          & .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          & input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          & .checkmark:after {
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }

    .selectable-order {
      @include span-columns(14);
      margin-bottom: 2px;
      cursor: pointer;
      padding: 0px 6px;

      &.disabled {
        cursor: default;

        .selectable-label {
          color: $darkblue;
        }

        .selectable-value {
          color: $lightgray;
          text-decoration: none;
        }

        &:hover {
          background-color: $white;
        }
      }

      .selectable-order-label {
        @include span-columns(4);
        text-transform: none;
        color: $darkblue;
        font-size: 13px;
        line-height: 31px;
      }

      .selectable-order-value {
        @include span-columns(10);
        font-size: 13px;
        padding: 5px 10px;
        background-color: $white;
        border: 1px solid $lightlightgray;

        &:hover {
          background-color: $lightlightlightgray;
          border: 1px solid $lightlightgray;
        }
      }

      .light {
        opacity: 0.5;
      }
    }
  }

  .spacer {
    @include span-columns(14);
    margin-top: 5px;
    margin-bottom: 15px;
    height: 1px;

    &.bordered {
      border-bottom: 2px dashed $lightlightlightgray;
    }
  }
}

@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    margin-left: 0%;
  }

  100% {
    opacity: 1;
    margin-left: -30%;
  }
}

@import "./responsive";
