@use "~@/common/scss/variables/theme";

.item {
  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item-header-title {
      font-size: 16px;
      margin: 0;
    }
  }

  .item-field {
    margin: 12px 0;
  }

  .item-divider {
    background-color: theme.$light-border;
    width: 100%;
    height: 1px;
    margin: 24px 0;
  }
}

.label {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}

.error {
  margin-top: 14px;
}
