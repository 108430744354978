@use "~@/common/scss/variables/theme";

.error {
  color: theme.$dark-error;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
}

.label {
  color: theme.$black;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
}
