/* topbar styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.SubMenu {
  position: absolute;
  top: 35px;
  left: 5px;
  background-color: $white;
  z-index: 2;
  padding: 5px 5px;
  min-width: 225px;
  max-height: 90vh;
  overflow: auto;
  cursor: default;
  display: none;
  @include lb-scrollbar($lightlightlightgray, $lightlightgray);

  &.active {
    display: block;

    animation: (bounce 0.2s ease-out);
  }

  @include dialog-shadow();

  a {
    text-decoration: none;
  }

  .SubMenuItem {
    color: $darkblue;
    text-transform: uppercase;
    padding: 10px 10px 10px 10px;
    vertical-align: top;

    & i.fa {
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
    }

    .title {
      font-size: 14px;
      color: $lightblue;

      &:hover {
        color: $darkblue;
        cursor: pointer;
      }
    }

    &.active {
      background-color: $lightlightlightgray;

      .title {
        font-size: 14px;
        color: $lightgray;

        &:hover {
          color: $lightgray;
          cursor: default;
        }
      }
    }

    &.action {
      background-color: transparent;

      .title {
        font-size: 12px;
        color: $lightblue;

        &:hover {
          color: $lightblue;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .items {
      .item {
        font-size: 12px;
        color: $lightgray;
        display: inline-block;
        padding: 5px 10px 5px 0px;

        &:hover {
          color: $viewer_black;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    i {
      width: 20px;
      font-size: 14px;

      &.fa-square.purple {
        color: #ffb7f2;
      }

      &.fa-square.yellow {
        color: #feff84;
      }
    }
  }

  &:before {
    content: "";
    @include triangle(10px, $white, up);
    position: absolute;
    top: -5px;
    left: 10px;
  }
}
