/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../_styles";

.Dialog.SwapPending,
.Dialog.PreSwapPending,
.Dialog.RequestAction {
  width: 320px;
  height: 410px;
  top: 5%;
  margin-left: -160px;

  .section-container {
    @include span-columns(14);
    @include outer-container;
    @include pad(5px 0px 0px 0px);
    overflow: hidden;
    @include calc(height, "100% - 115px");

    .section {
      height: 100%;
      @include span-columns(14);

      &._1 {
        margin-left: 0;
      }

      transition: (margin-left 0.3s ease 0s);
    }
  }

  .content {
    position: relative;
    height: 100%;
    overflow-y: auto;
    @include lb-scrollbar(transparent, $lightblue);
    @include span-columns(14 of 14);

    .details {
      @include span-columns(14);
      text-align: left;
      max-height: calc(100vh - 160px);
      @include pad(5px 7px);

      .sentence-container {
        @include span-columns(14);
        @include pad(5px 10px 15px);
        border-bottom: 2px dashed $lightlightgray;
      }
    }
  }

  &.grant {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #87d200;
        }
      }
    }
  }

  &.deny {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #ff4c4c;
        }
      }
    }
  }

  &.suspend {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #05a7ff;
        }
      }
    }
  }

  &.delete {
    .mobile-section-nav {
      .mobile-section-nav-tab {
        &.active {
          color: #8c53c6;
        }
      }
    }
  }
}

.SwapPending {
  @include span-columns(14);

  .message {
    @include span-columns(14);
    padding: 10px 10px 10px;
    text-align: left;
    color: $gray1;

    .date {
      color: $viewer_black;
      text-transform: uppercase;
    }

    .text {
      display: block;
      padding-bottom: 20px;
    }

    .action {
      color: $lightblue;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
    }

    .assignmentText {
      color: $viewer_black;
      font-size: 12px;

      .value {
        font-size: 12px;
        color: $gray1;
      }

      .large {
        font-size: 14px;
      }

      .underline {
        text-decoration: underline;
      }

      & .grant {
        background-color: rgba(185, 243, 174, 0.4);
        border: 1px solid rgba(170, 228, 159, 1);
        color: $green;
      }

      & .deny {
        background-color: rgba(255, 0, 0, 0.25);
        border: 1px solid rgba(255, 0, 0, 0.6);
        color: $red;
      }

      & .suspend {
        background-color: rgba(62, 171, 255, 0.1);
        border: 1px solid rgba(62, 171, 255, 0.5);
        color: $lightblue;
      }
    }

    .uppercase {
      text-transform: uppercase;
    }

    .replace-type-button {
      @include span-columns(12);
      @include shift(1);
      @include pad(10px);
      text-align: center;
      margin-top: 5px;
      font-size: 12px;
      text-transform: uppercase;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;

      &:hover {
        cursor: pointer;
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;
      }
    }
  }
}
