.flex-display-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-display-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-display,
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-display-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-display-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.flex-display-justify-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.flex-display-between,
.flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.flex-display-around {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
}

.flex-display-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
}

.flex-column,
.flex-column-1,
.flex-1 {
  flex: 1;
  min-width: 0;
}

.flex-column-double,
.flex-column-2,
.flex-2 {
  flex: 2;
  min-width: 0;
}

.flex-column-triple,
.flex-column-3,
.flex-3 {
  flex: 3;
  min-width: 0;
}

.flex-column-4,
.flex-4 {
  flex: 4;
  min-width: 0;
}

.flex-column-5,
.flex-5 {
  flex: 5;
  min-width: 0;
}

.flex-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: inherit;

  div[class*="flex-column"]:not(:last-child) {
    margin-right: 15px;
  }
}

.min-width {
  min-width: 0;
}

.min-height {
  min-height: 0;
}

.flex-self-end {
  align-self: flex-end;
}
