@import "bourbon.scss";
@import "neat.scss";
@import "grid-settings";
@import "variables";
@import "mixins";
@import "../styles";

$img_holiday: url("../../../../../_lib/ui/images/holiday_icon.svg");

.ColumnContainer {
  overflow: hidden;

  @media print {
    overflow: unset;
    font-size: clamp(6px, 8px, 10px) !important;
  }

  margin-top: 40px;
  margin-bottom: 0;
  height: calc(100vh - 40px);
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  &--print-container {
    .columns-items {
      display: block;
      width: 100%;

      @media print {
        break-inside: avoid !important;
        break-before: auto !important;
        font-size: clamp(6px, 8px, 10px) !important;
      }

      .columns-row {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;

        @media print {
          break-inside: avoid !important;
          break-before: auto !important;
        }

        &__wrapper {
          width: 100%;
          display: block;
        }
      }
    }
  }

  &.activity {
    width: calc(100% - 400px);
  }

  &--dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &--no-weekends {
    .header-wrapper .header {
      &__right-column {
        .cell {
          &--weekend {
            display: none;
          }
        }
      }
    }

    .row-wrapper .row {
      .cell {
        &--weekend {
          display: none;
        }
      }
    }
  }

  .header-wrapper {
    position: sticky;
    width: fit-content;
    padding-top: 10px;
    top: 0;
    z-index: 5;
    font-size: $grid_headersize;

    @media print {
      font-size: clamp(6px, 8px, 10px) !important;
    }

    .header {
      display: flex;
      width: 100%;

      &__left-column {
        position: sticky;
        left: 0;
        width: 150px;
        padding: 2px 10px;
        flex-shrink: 0;
        text-transform: uppercase;
        background-color: $grid_headertype_color;
        color: $white;

        &--condensed {
          width: 75px;
          height: 42px;
          padding: 2px;
          text-align: left;
        }
      }

      &__right-column {
        height: auto;
        display: flex;

        .cell {
          display: flex;
          padding: 2px 5px;
          text-align: center;
          min-width: 100px;
          height: 100%;
          color: $white;
          font-size: $grid_headersize;

          @media print {
            font-size: clamp(6px, 8px, 10px) !important;
          }

          background-color: $grid_headerweekday_color;
          border-left: 1px solid $lightblue;

          &:first-child {
            border-left: none;
          }

          &:hover {
            cursor: pointer;
            padding-bottom: 0px;
            border-bottom: 2px solid $darkblue;
          }

          .cell__header--container {
            display: block;
            width: 100%;

            img {
              margin-right: 3px;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }

          &--condensed {
            min-width: 44px;
            max-width: 44px;
            height: 42px;

            &.cell--holiday {
              background-image: $img_holiday;
              background-position: 95% 5% !important;
              background-repeat: no-repeat;
              background-size: 25%;
            }
          }

          &--today {
            background-color: $grid_today_color;
          }

          &--weekend {
            background-color: $grid_headerweekend_color;

            &.cell--notInSchedule {
              background-color: rgba(168, 168, 168, 0.7);
            }
          }

          &--notInSchedule {
            background-color: rgba(188, 188, 188, 0.7);
            border-left: 1px dotted #ababab;
          }
        }
      }
    }
  }

  .row-wrapper {
    width: auto !important;
    display: table;

    &--tallies {
      .row {
        background-color: #f6e2e0;

        .fixed-cell {
          background-color: #fee5e2;
        }
      }
    }

    &--slots {
      .row {
        &--even {
          background-color: $grid_evenrow_color;

          .fixed-cell {
            background-color: #f3f1f1;
          }
        }

        &--odd {
          background-color: $grid_darkrow_color;

          .fixed-cell {
            background-color: #dbd9d9;
          }
        }
      }
    }

    &--requests {
      .row {
        &--even {
          background-color: #f6f3e3;

          .fixed-cell {
            background-color: rgba(248, 238, 182, 0.98);
          }
        }

        &--odd {
          background-color: #e1e1e1;

          .fixed-cell {
            background-color: rgba(241, 232, 181, 0.98);
          }
        }
      }
    }

    .group-banner {
      padding: 5px 10px;
      text-transform: uppercase;
      border-bottom: 1px solid $lightlightgray;
      font-size: 12px;

      @media print {
        font-size: clamp(6px, 8px, 10px) !important;
      }

      color: #000000;

      i {
        display: none;
        font-size: 18px;

        @media print {
          font-size: clamp(6px, 8px, 10px) !important;
        }

        padding-right: 5px;
      }

      &--requests {
        background: repeating-linear-gradient(-55deg, #fff9d6, #fff9d6 2%, #fffceb 2%, #fffceb 4%);
      }

      &--tallies {
        background: repeating-linear-gradient(-55deg, #ffeae8, #ffeae8 2%, #fff1f0 2%, #fff1f0 4%);
      }
    }

    .row {
      width: 100%;
      display: flex;

      &--hidden {
        display: none;
      }

      .fixed-cell {
        color: $viewer_black;
        width: 150px;
        padding: 2px 10px;
        z-index: 1;
        position: sticky;
        left: 0;
        line-height: 17px;
        overflow: hidden;
        white-space: nowrap;
        flex-shrink: 0;
        border-right: $grid_normalborder;
        border-bottom: $grid_normalborder;

        &--condensed {
          width: 75px;
          text-align: left;
          text-overflow: clip;
          padding: 2px;
        }
      }

      .cell {
        min-width: 100px;
        max-width: 100px;
        text-overflow: ellipsis;
        border-left: $grid_normalborder;
        border-bottom: $grid_normalborder;

        &:nth-of-type(2) {
          border-left: none;
        }

        &--condensed {
          min-width: 44px;
          max-width: 44px;
          text-overflow: clip;
        }

        .slot,
        .tally {
          display: block;
          text-align: center;
          line-height: 17px;
          position: relative;

          &--condensed {
            text-align: left;
            text-overflow: clip;
          }

          &--over {
            color: #679f00 !important;
          }

          &--under {
            color: #ff3333 !important;
          }

          i.fa-star-o {
            position: absolute;
            color: $lightgray;
            font-size: 10px;

            @media print {
              font-size: clamp(6px, 8px, 10px) !important;
            }

            top: 2px;
            left: 2px;
          }

          span.text {
            display: inline-block;
            padding: 2px 0px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover {
              background-color: $postit;
              color: black;
              cursor: pointer;
            }

            &.highlighted {
              background-color: $postit;
            }
          }

          .slot-personnel-container {
            @include slot-personnel-container;
          }

          span.times {
            display: block;
            font-size: 11px;

            @media print {
              font-size: clamp(6px, 8px, 10px) !important;
            }

            line-height: 14px;
            font-style: italic;
          }

          div.contextual-clues {
            position: absolute;
            height: 100%;
            display: flex;
            margin-bottom: 2px;
            right: 0px;
            top: 0px;
            pointer-events: none;
            padding-top: 3px;
            padding-right: 3px;

            &.show-times {
              height: 60%;
            }

            div.contextual-clue {
              width: 5px;
              height: 5px;
              margin: 0 0 0 1px;

              &.hasNote {
                background-color: $noteindicator_color;
              }

              &.isPending {
                background-color: $pendingindicator_color;
              }

              &.isDenied {
                background-color: $deniedindicator_color;
              }

              &.isDeleted {
                background-color: $deletedindicator_color;
              }

              &.isWebRequest {
                background-color: $requestindicator_color;
              }
            }
          }

          &.Placeholder {
            font-size: 10px;

            @media print {
              font-size: clamp(6px, 8px, 10px) !important;
            }

            font-style: italic;
            background-color: #ffe8e8;
            color: #ff9c9c;
          }

          &.Status {
            font-size: 10px;
            color: #b6d5af;

            &.full {
              color: #ffcccc;
            }

            &.personnel {
              font-size: 6px;

              @media print {
                font-size: clamp(6px, 8px, 10px) !important;
              }
            }
          }

          @include transition(background-color 0.2s ease-in 0s, color 0.2s ease-in 0s);

          &.hidden {
            display: none;
          }

          &.trans-hover {
            & span.text {
              background-color: transparent !important;
              color: $white !important;
            }
          }

          // error classes
          &.compat {
            @include animation-name(compat_throb);
            @include animation-duration(2s);
            @include animation-timing-function(linear);
            @include animation-iteration-count(infinite);
          }

          &.demand {
            @include animation-name(demand_throb);
            @include animation-duration(2s);
            @include animation-timing-function(linear);
            @include animation-iteration-count(infinite);
          }

          &.role {
            @include animation-name(role_throb);
            @include animation-duration(2s);
            @include animation-timing-function(linear);
            @include animation-iteration-count(infinite);
          }

          &.transparent {
            background-color: transparent !important;
          }

          &.mapped {
            color: $lightgray;
          }
        }

        & .tally .text {
          &:hover {
            background-color: inherit !important;
            color: inherit !important;
            cursor: default !important;
          }
        }

        // error classes
        &.demand {
          @include animation-name(demand_throb);
          @include animation-duration(2s);
          @include animation-timing-function(linear);
          @include animation-iteration-count(infinite);
        }

        &.notInSchedule {
          color: $lightgray !important;

          .slot {
            .text {
              &:hover {
                background-color: inherit;
                cursor: text;
              }
            }
          }
        }
      }
    }
  }
}
