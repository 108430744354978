@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

body {
  margin: 0;
  padding: 0;
  font-family: $bodyfontfamily;
  background-color: $extremelightgray;

  p.browsehappy {
    color: $gray1;
    padding: 50px 20px 0 20px;
  }
}

#content {
  font-family: $bodyfontfamily;
  font-size: $bodytextsize;
  color: $bodytextcolor;

  &.error {
    width: 100%;
    text-align: center;

    h1 {
      font-size: 32px;

      .red {
        background-color: transparent !important;
        color: #ff3c3c !important;
      }

      &.unbold {
        font-weight: normal;
      }
    }

    #page-content {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }

    p {
      font-size: 16px;

      .title {
        display: inline-block;
        vertical-align: top;
        padding: 5px;
        font-size: 16px;
        width: 60px;
      }

      .text {
        display: inline-block;
        font-size: 14px;
        padding: 5px 10px;
        width: 210px;
      }
    }

    .red {
      background-color: #ff3c3c;
      color: white;
    }

    .green {
      background-color: $green;
      color: white;
    }

    .orange {
      color: $orange;
      font-weight: bold;
    }

    .blue {
      color: $lightblue;
    }

    .pup {
      display: block;
      width: 400px;
      margin: 0 auto;
    }
  }
}

#LbsLoginApp {
  #please-wait {
    display: none;
    padding: 20px 35px 20px 35px;
    width: 200px;
    position: fixed;
    bottom: 300px;
    left: 50%;
    margin-left: -100px;
    font-size: $smallheadersize;
    background-color: rgba(0, 0, 0, 0.8);
    color: $white;
    z-index: 100000;
    text-transform: uppercase;

    .text {
      float: left;
    }

    .spinner {
      float: right;
      background-color: $white;
      height: 20px;
      width: 20px;
      -webkit-animation: rotateplane 1.2s infinite ease-in-out;
      animation: rotateplane 1.2s infinite ease-in-out;
    }
  }

  @include media($mobile) {
    .ContextRibbonItem {
      &:nth-child(1) {
        display: none;
      }
    }
  }

  .ContextRibbonItem {
    a {
      color: $white;
    }
  }
}

@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/* helper classes */
.position-reset {
  position: relative;
  width: 100%;
  height: 100%;
}

.tooltip-wrapper {
  position: relative;

  & .tooltip {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    padding: 5px;
    display: none;
    color: $white;

    &.tooltip-bottom {
      top: 22px;

      &:before {
        content: "";
        @include triangle(8px, rgba(0, 0, 0, 0.5), up);
        position: absolute;
        top: -4px;
      }
    }

    &.tooltip-left {
      right: -5px;

      &:before {
        top: -4px;
        right: 8px;
      }
    }
  }

  &:hover {
    & .tooltip {
      display: block;
    }
  }
}
