@use "~@/common/scss/variables/theme";

@mixin up($breakpoint) {
  @if map-has-key(theme.$breakpoints, $breakpoint) {
    $breakpoint-value: map-get(theme.$breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn "Must specify a valid breakpoint.";
  }
}

@mixin down($breakpoint) {
  @if map-has-key(theme.$breakpoints, $breakpoint) {
    @if $breakpoint == "xs" {
      @media (min-width: 0) and (max-width: (map-get(theme.$breakpoints, "sm") - 1)) {
        @content;
      }
    } @else if($breakpoint == "sm") {
      @media (min-width: 0) and (max-width: (map-get(theme.$breakpoints, "md") - 1)) {
        @content;
      }
    } @else if($breakpoint == "md") {
      @media (min-width: 0) and (max-width: (map-get(theme.$breakpoints, "lg") - 1)) {
        @content;
      }
    } @else if($breakpoint == "lg") {
      @media (min-width: 0) and (max-width: (map-get(theme.$breakpoints, "xl") - 1)) {
        @content;
      }
    } @else {
      @media (min-width: 0) {
        @content;
      }
    }
  } @else {
    @warn "Must specify a valid breakpoint.";
  }
}
