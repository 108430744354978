/* topbar styles */
@import "bourbon";
@import "grid-settings";
@import "neat";
@import "variables.scss";
@import "mixins.scss";

#TopBar {
  @include media($mobile) {
    #user-details {
      div.text {
        display: none;

        span.customer {
          font-size: 10px;
          display: block;
        }

        span.user {
          display: block;
          text-align: right;
        }
      }

      div.icon {
        display: block;
      }
    }

    .subnav-menu {
      right: 5px;
    }
  }

  @include media($mobile) {
    .no-mobile {
      display: none;
    }

    .ContextRibbonItem,
    .top-bar-btn {
      padding: 3px 2px 1px 2px;

      &.date {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      div.ribbon-text {
        min-width: 175px;
        padding: 8px 10px 7px 10px;

        &.no-mobile {
          display: none;
        }
      }

      div.icons-mobile {
        display: inline-block;
        color: $viewer_black;

        & i.fa {
          padding: 7px 5px 5px;
          border: 1px solid $white;
        }
      }

      div.icons-left {
        color: $viewer_black;
        border-left: 1px solid #f0f0f0;

        & i.fa {
          padding: 9px 5px 9px;
          border: 1px solid $white;
        }
      }
      &.filter,
      &.today-template {
        display: none;
      }

      &.view {
        border-right: 1px solid $lightlightgray;
      }
    }

    .dropdown .open {
      position: fixed;
      left: 0;
      width: 100% !important;
    }

    #app-menu {
      padding: 2px 10px 5px 5px;
      .fa {
        font-size: 20px;
      }
    }
  }

  .column-print {
    &__warning-container {
      position: relative;
      top: 20px;

      span {
        z-index: 10;
        text-align: center;
      }
    }
  }
}

@keyframes slide {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 0;
  }
}
