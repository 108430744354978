/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.ScheduleEditsExt.DateSelectDialog {
  width: 300px;
  top: 10%;
  margin-left: -150px;
  height: 380px;

  &.hasBlockDetails {
    height: 425px;

    & .content .reset u {
      margin-top: 0;
    }
  }

  & .content {
    @include span-columns(14);
    @include pad(20px 0 20px);

    & .reset {
      @include pad(5px 15px);
      font-size: 12px;
      color: $viewer_black;

      u {
        display: inline-block;
        margin-top: 10px;

        &:hover {
          color: $gray1;
          cursor: pointer;
        }
      }
    }
  }
}

@import "./responsive.scss";
