/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

@include media($mobile) {
  .Dialog.SlotDetails {
    top: 5%;

    .mobile-section-nav {
      @include span-columns(3);
    }

    & .content-body {
      @include span-columns(4);

      .pending {
        @include span-columns(4);
      }

      & .details {
        @include span-columns(4);

        .personnel-container {
          @include span-columns(4);

          .contact-details {
            .contact-details-content {
              display: none !important;
            }

            .contact-details-content-mobile {
              display: flex !important;
            }
          }
        }
      }
    }

    & .footer {
      .edit-button {
        @include span-columns(2);
      }
    }

    &.pending {
      width: 320px;
      margin-left: -160px;

      & .content-body {
        @include span-columns(4);

        & .details {
          @include span-columns(4);
        }
      }
    }

    &.expanded {
      & .mobile-section-nav {
        & .mobile-section-nav-tab {
          &.group-1 {
            display: none;
          }

          &.group-2 {
            display: inline-block;
          }
        }
      }

      & .content-body {
        @include span-columns(4);

        .pending {
          @include span-columns(4);
        }

        & .details {
          @include span-columns(4);

          .personnel-container {
            @include span-columns(4);

            .contact-details {
              .contact-details-content {
                display: none !important;
              }

              .contact-details-content-mobile {
                display: flex !important;
              }
            }
          }
        }
      }

      & .footer {
        .edit-button {
          @include span-columns(2);
        }
      }

      &.general-active {
        .pending-update {
          display: none !important;
        }
      }

      &.pending-active {
        .current-update {
          display: none !important;
        }
      }
    }

    &.swop {
      &.pending-active {
        .details {
          display: none !important;
        }
      }
    }

    & .SlotDetailsTabs {
      & .content-container {
        & .SlotDetailsTabContent {
          &.Personnel {
            & .contact {
              & .options {
                display: none;
              }

              & .options.mobile {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
