/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

.Dialog.NoteFull {
  width: 300px;
  height: auto;
  top: 8%;
  margin-left: -150px;
  background-color: #ffd866;

  & .close {
    color: #997200;

    &:hover {
      color: $gray1;
    }
  }

  .content {
    @include span-columns(14);
    @include pad(5px 0px 5px 0px);

    .header {
      @include span-columns(12);
      margin-left: 5px;
      color: #997200;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      padding: 5px 0px 5px 5px;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
        margin-right: 5px;
      }
    }
  }

  .editables {
    @include span-columns(14);
    @include pad(0 0px);

    & .note-text-container {
      @include span-columns(14);
      @include pad(0px 0px);

      & textarea {
        @include pad(10px);
        border: none;
        background-color: #ffe599;
        width: 100%;
      }
    }
  }
}
