/* dialog styles */
@import "bourbon";
@import "neat";
@import "grid-settings";
@import "variables";
@import "mixins";

$noteindicator_color: rgb(255, 191, 0);
$requestindicator_color: rgba(185, 243, 174, 0.4);
$requestindicator_bordercolor: rgba(185, 243, 174, 1);
$pendingindicator_color: rgba(62, 171, 255, 0.1);
$pendingindicator_bordercolor: rgba(62, 171, 255, 0.5);

.slot-details {
  @include span-columns(14);
  height: 100vh;
  z-index: -1;
  pointer-events: none;

  &.isTop {
    z-index: 1;

    & .Dialog {
      z-index: 1 !important;
    }
  }

  div.container-middle {
    @include span-columns(14);
    position: relative;
    height: 100vh;

    .loading-container {
      @include span-columns(14);
      @include pad(200px 0);
      text-align: center;
      font-size: 48px;
      color: $white;
    }
  }
}

.Dialog.SlotDetails {
  width: 320px;
  height: auto;
  top: 5%;
  margin-left: -160px;
  pointer-events: all;
  max-height: 100vh;

  .mobile-section-nav {
    @include span-columns(14);
    @include pad(0px 0px 0);

    .mobile-section-nav-tab {
      display: inline-block;
      @include pad(10px 10px);
      font-size: 13px;
      color: $viewer_black;

      &.group-2 {
        display: none;
      }

      &.active {
        background-color: $lightlightlightgray;
        color: $gray1;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    @include span-columns(14);

    .header {
      @include span-columns(14);
      color: $gray1;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      position: relative;

      .tag {
        padding: 2px 5px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .content-body {
      @include span-columns(14);
      @include pad(0px 0 0);

      .loading-container {
        @include span-columns(14);
        @include pad(75px 100px);
        text-align: center;
        font-size: 32px;
      }

      .time-container {
        @include span-columns(14);
        @include pad(2px 10px 2px 10px);
      }

      .customdisplay-container {
        @extend .time-container;
        padding-left: 0px;
      }

      .assignment-container {
        @include span-columns(14);
        @include pad(5px 5px 0);

        .assignment-name {
          @include pad(5px 5px 2px 5px);
          display: inline-block;
        }

        .template-name {
          @include pad(0px 5px 5px);
          display: block;
          display: none;
        }
      }

      .personnel-container {
        @include span-columns(14);
        @include pad(5px 5px 5px);

        .personnel-name {
          @include pad(5px 5px 2px);
          display: inline-block;

          .fa {
            font-size: 16px;
          }
        }

        .contact-details {
          @include span-columns(14);
          @include pad(3px 5px 5px 5px);

          .contact-details-content {
            .contact-details-title {
              font-size: 13px;
              text-transform: uppercase;
              color: $gray1;
            }
          }

          .contact-details-content-mobile {
            display: none;

            a {
              color: $viewer_black;
              text-decoration: none;
            }

            .contact-details-item {
              @include pad(5px 10px);
              height: 55px;
              margin-right: 2px;
              margin-bottom: 2px;
              background-color: $lightlightlightgray;
              text-align: center;

              a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }

              img {
                height: 32px;
              }

              .fa {
                font-size: 24px;
                line-height: 24px;
                padding-bottom: 5px;

                &.fa-fw {
                  width: 34px;
                }
              }
            }
          }

          .contact-details-content-pager {
            a {
              color: $viewer_black;
              text-decoration: none;
            }

            .contact-details-item-pager {
              @include pad(5px 10px);
              height: 55px;
              margin-right: 2px;
              margin-bottom: 2px;
              display: inline-block;
              background-color: $lightlightlightgray;
              text-align: center;
              cursor: pointer;

              .fa {
                display: block;
                font-size: 24px;
                line-height: 24px;
                padding-bottom: 5px;

                &.fa-fw {
                  width: 34px;
                }
              }
            }
          }
        }

        .pager-details {
          @include span-columns(14);
          @include pad(3px 5px 5px 5px);
          max-width: 100%;
          overflow-x: hidden;
          height: 330px;
          text-align: center;
          .pager-details-content-mobile {
            a {
              color: $viewer_black;
              text-decoration: none;
            }

            .pager-details-item {
              @include pad(5px 10px);
              height: 55px;
              margin-right: 2px;
              margin-bottom: 2px;
              display: inline-block;
              background-color: $lightlightlightgray;
              text-align: center;

              .fa {
                display: block;
                font-size: 24px;
                line-height: 24px;
                padding-bottom: 5px;

                &.fa-fw {
                  width: 34px;
                }
              }
            }

            .pager-details-message {
              @include pad(5px 10px);
              height: 55px;
              margin-right: 2px;
              margin-bottom: 2px;
              display: inline-block;
              background-color: $lightlightlightgray;
              text-align: center;

              .fa {
                display: block;
                font-size: 24px;
                line-height: 24px;
                padding-bottom: 5px;

                &.fa-fw {
                  width: 34px;
                }
              }
            }
          }

          .textable {
            @include span-columns(21);
            padding: 0px 6px;
            margin-bottom: 5px;
            margin-top: 5px;
            .textable-value {
              @include span-columns(9);
              @include pad(5px 10px);
              font-size: 13px;
              background-color: $lightlightlightgray;
              border: 1px solid $lightlightgray;

              &:hover {
                background-color: $lightlightlightgray;
                border: 1px solid $lightlightgray;
              }
            }
          }

          .textable-counter {
            @include span-columns(14);
            padding: 0px 0px 0px 6px;
            margin-top: 5px;
            margin-bottom: 5px;
            text-align: right;

            & .right-side {
              display: inline-block;
              right: 0;
              width: 100px;
              height: 100%;
              padding: 5px;
              span.submit {
                @include pad(10px);
                display: block;
                text-align: center;
                font-size: 13px;
                text-transform: uppercase;
                color: $viewer_black;
                background-color: $lightlightgray;

                i {
                  display: block;
                  font-size: 22px;
                  padding-bottom: 5px;
                }

                &:hover {
                  background-color: $lightgray;
                  color: $white;
                  cursor: pointer;
                }
              }

              span.submit {
                background-color: #5bbf19;
                color: $white;

                &:hover {
                  background-color: $green;
                }
              }
            }
          }

          .phi-warning {
            color: $red;
          }
        }
      }

      .slot-note-container {
        @include span-columns(14);
        @include pad(5px 10px);

        .slot-note-title {
          font-size: 13px;
          text-transform: uppercase;
          color: $gray1;
        }
      }

      .slot-request-note-container {
        @include span-columns(14);
        @include pad(5px 10px 5px);

        .slot-request-note-title {
          @include pad(2px 5px 2px);
          display: inline-block;
          font-size: 13px;
          color: $white;
          background-color: $lightgray;
        }

        .slot-request-note-content {
          @include pad(2px 0px 2px 0px);

          & .empty-details {
            @include pad(2px 0 2px 15px);
          }
        }
      }

      .details-ext {
        @include span-columns(14);
        @include pad(10px 0 10px);
        margin-top: 0px;
      }

      .additional-details {
        @include span-columns(14);
        @include pad(5px 10px 5px);

        .additional-details-title {
          @include pad(2px 5px 2px);
          display: inline-block;
          font-size: 13px;
          color: $white;
          background-color: $lightgray;
        }

        .additional-details-content {
          @include pad(2px 0px 2px 10px);

          .additional-details-item {
            @include span-columns(14);

            & .additional-details-item-text-container {
              display: inline-block;
              @include pad(1px 5px);

              &.pending-update {
                background-color: rgba(62, 171, 255, 0.2);
                font-style: italic;
              }
            }

            & .additional-details-item-department-text-container {
              display: flex;
              @include pad(1px 5px);
            }
          }
        }
      }

      .history-link {
        display: inline-block;
        font-size: 12px;
        text-transform: lowercase;
        color: $lightblue;
        text-decoration: underline;
        margin-left: 10px;

        &:hover {
          cursor: pointer;
          color: $darkblue;
        }
      }
    }

    .message {
      @include span-columns(14);
      font-size: 10px;
      margin-top: 2px;

      i.fa-quote-left {
        font-size: 8px;
        padding-right: 2px;
        display: none;
      }

      i.fa-quote-right {
        font-size: 8px;
        padding-left: 2px;
        display: none;
      }

      background-color: rgba(255, 191, 0, 0.4);
      color: #997200;
      border-left: 3px solid rgba(255, 195, 85, 1);

      & .message-text {
        @include span-columns(12);
        @include pad(8px 10px);
        height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $viewer_black;
      }

      & .expand-text {
        @include span-columns(2);
        @include pad(8px 2px);
        text-align: center;
        text-decoration: underline;
        text-transform: lowercase;

        &:hover {
          color: #333333;
          cursor: pointer;
        }
      }

      &.pending {
        background-color: rgba(62, 171, 255, 0.1);
        color: $lightblue;
        border-left: 0px solid rgba(62, 171, 255, 0.5);

        & .message-title {
          @include span-columns(14);
          @include pad(8px 10px 0);
          font-size: 13px;
          text-transform: uppercase;
        }

        & .message-text {
          @include span-columns(14);
          @include pad(8px 10px);
          height: auto;
          white-space: normal;
          overflow: visible;
          text-overflow: normal;
          font-size: 12px;
        }
      }
    }

    .assignment-info {
      @include span-columns(14);
      font-size: 12px;
      align-items: end;
      display: flex;

      & .assignment-text {
        @include span-columns(12);
        @include pad(5px 5px);
        overflow: hidden;
        display: -webkit-box;
        height: 40px;
        -webkit-line-clamp: 2;
        cursor: initial;
        -webkit-box-orient: vertical;
      }

      & .expand-text {
        @include span-columns(2);
        @include pad(8px 2px);
        float: left;
        height: 27px;
        text-decoration: underline;
        text-transform: lowercase;
      }

      &:hover {
        color: #333333;
        cursor: pointer;
      }
    }

    .sentence {
      font-size: 12px;

      .value {
        color: $gray1;
        font-size: 12px;
      }
    }

    span.underline {
      text-decoration: underline;
    }

    span.semi-large {
      color: $gray1;
      font-size: 13px;
    }

    span.large {
      color: $gray1;
      font-size: 14px;
    }

    span.super-large {
      color: $gray1;
      font-size: 20px;
    }

    span.super-large-sub {
      color: $gray1;
      font-size: 12px;
      padding: 8px;
    }

    span.uppercase {
      text-transform: uppercase;
    }

    span.dark {
      color: $gray1;
    }

    span.department-name-overflow {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details {
      @include span-columns(14);
      text-align: left;
      max-height: calc(100vh - 160px);
      overflow-y: auto;

      @include lb-scrollbar(transparent, rgba(255, 149, 45, 0.5));
    }

    .pending {
      @include pad(0 0 0px);
      @include span-columns(6);
      background-color: rgba(62, 171, 255, 0.2);

      .pending-title {
        @include span-columns(14);
        @include pad(25px 10px 5px);
        background-color: rgba(62, 171, 255, 0.75);
        font-size: 16px;
        text-transform: uppercase;
        color: $white;
        display: none;
      }

      & .pending-body {
        @include span-columns(14);
        max-height: calc(100vh - 160px);
        overflow-y: scroll;

        @include lb-scrollbar(transparent, rgba(38, 148, 232, 0.2));
      }

      .submission-details {
        @include span-columns(14);
        @include pad(5px 10px 5px);
        font-size: 11px;
        display: none;

        .submission-details-title {
          @include pad(2px 0px 2px);
          display: inline-block;
          color: $white;
          background-color: $lightgray;
        }

        .submission-details-content {
          @include pad(2px 0px 2px 0px);
        }
      }

      .changing-container {
        @include span-columns(14);
        @include pad(3px 5px 5px 10px);

        .changing-item {
          @include span-columns(14);
          padding-bottom: 10px;

          .changing-item-title {
            @include pad(2px 5px 2px);
            display: inline-block;
            font-size: 13px;
            color: $white;
            background-color: rgba(62, 171, 255, 0.75);
          }

          .changing-item-content {
            @include pad(0px 0px 0px 0px);

            & .personnel-container {
              @include span-columns(14);
              @include pad(0px);
            }

            & .slot-note-container {
              @include pad(0px);
            }

            & .additional-details {
              @include pad(0px);

              & .additional-details-title {
                display: none;
              }
            }
          }

          .swapper-details {
            @include pad(5px 5px);
          }
        }
      }

      .swaportunity-container {
        @include span-columns(14);
        @include pad(10px 10px 10px);

        .changing-item {
          @include span-columns(14);

          .changing-item-title {
            @include pad(2px 5px 2px);
            display: inline-block;
            font-size: 13px;
            color: $white;
            background-color: rgba(62, 171, 255, 0.75);
          }

          .changing-item-content {
            @include pad(2px 0px 2px 0px);

            & .personnel-container {
              @include span-columns(14);
              @include pad(0px);
            }

            & .slot-note-container {
              @include pad(0px);
            }

            & .additional-details {
              @include pad(0px);

              & .additional-details-title {
                display: none;
              }
            }
          }

          &.accepted {
            & .changing-item-title {
              background-color: #87d200;
            }
          }

          &.denied {
            & .changing-item-title {
              background-color: #ff4c4c;
            }
          }

          .swapper-details {
            @include span-columns(14);
            @include pad(5px 10px);

            & .swapper-details-item {
              @include span-columns(14);
              @include pad(2px 5px);

              & .swapper-details-item-name {
                @include span-columns(8);
              }

              & .swapper-details-item-response {
                @include span-columns(6);
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  & .SlotDetailsTabs {
    @include span-columns(14);
    position: relative;
    z-index: 1;

    & .content-container {
      @include span-columns(14);
      @include omega;
      height: 177px;

      & .SlotDetailsTabContent {
        height: 100%;

        @include span-columns(12);

        & .title {
          font-size: 12px;
          text-transform: uppercase;
          padding: 5px 15px 0px 15px;
        }

        &.Personnel {
          background-color: #ffe9d7;
          color: rgba(232, 122, 37, 1);

          & .name {
            font-size: 18px;
            padding: 6px 15px 0px;
          }

          & .contact {
            padding: 20px 15px;

            & .options {
              font-size: 10px;

              .contact-item {
                padding: 1px 0;
                text-decoration: none;

                span.label {
                  display: inline-block;
                  text-transform: uppercase;
                  width: 25%;
                }

                & i {
                  font-size: 14px;
                }

                a {
                  color: inherit;
                  text-decoration: none;
                }
              }
            }

            & .options.mobile {
              display: none;
              margin-left: -5px;

              .contact-item {
                width: 50px;
                text-align: center;
                background-color: rgba(232, 122, 37, 0.15);
                color: rgba(232, 122, 37, 1);
                padding: 5px;
                float: left;
                margin-right: 2px;
                font-size: 12px;
                text-transform: uppercase;

                i {
                  font-size: 16px;
                }
              }
            }
          }
        }

        &.Details {
          background-color: rgba(38, 148, 232, 0.15);
          color: rgba(38, 148, 232, 1);

          & .info {
            @include span-columns(14);
            font-size: 10px;

            .info-item {
              padding: 0px 0;

              .sentence {
                padding: 0 15px;
              }

              span.label {
                display: inline-block;
                padding-top: 20px;
                padding: 20px 15px 0;
                text-transform: uppercase;
                font-size: 12px;
                width: 100%;
              }

              span.value {
                text-decoration: underline;
                padding: 0 15px;
                font-size: 16px;

                &.small {
                  font-size: 12px;
                }

                &.no-underline {
                  text-decoration: none;
                }
              }

              & > i {
                font-size: 14px;
              }
            }

            .responses {
              @include span-columns(9 of 9);
              @include pad(0 0px);
              padding-top: 10px;

              .responses-title {
                display: none;
                font-size: 12px;
                text-transform: uppercase;
              }

              .response-container-titles {
                @include span-columns(9 of 9);
                @include pad(2px 10px 2px 15px);
                background-color: rgba(38, 148, 232, 0.05);

                .response-container-title {
                  @include span-columns(3 of 9);
                  font-size: 12px;
                  text-transform: uppercase;
                  text-align: left;

                  &.pend {
                    color: $lightblue;
                  }

                  &.deny {
                    color: #ff4343;
                  }

                  &.accept {
                    color: $green;
                  }
                }
              }

              .response-containers {
                @include span-columns(9 of 9);
                @include pad(2px 0 2px 15px);
                height: 110px;
                overflow-y: scroll;

                .response-container {
                  @include span-columns(3 of 9);

                  .items {
                    padding: 2px 0px;
                    text-align: left;
                  }
                }

                @include lb-scrollbar(transparent, rgba(38, 148, 232, 0.15));
              }
            }
          }
        }

        &.History {
          background-color: rgba(255, 83, 241, 0.15);
          color: rgba(255, 83, 241, 1);

          & .title {
            background-color: rgba(255, 83, 241, 0.075);
            padding: 5px 15px 5px 15px;
          }

          & .info {
            height: 150px;
            overflow-y: scroll;

            & .options {
              font-size: 10px;
              padding: 5px 10px 0 10px;

              .info-item {
                padding: 2px 5px 5px;

                span.label {
                  display: block;
                  text-decoration: underline;
                  text-transform: uppercase;
                }

                & i {
                  font-size: 14px;
                }
              }
            }

            @include lb-scrollbar(transparent, rgba(255, 83, 241, 0.25));
          }
        }

        &.Actions {
          background-color: rgba(232, 122, 37, 0.15);
          color: rgba(232, 122, 37, 1);

          & .actions {
            padding: 15px;

            & .action-item {
              width: 50%;
              padding: 5px 10px 5px 5px;
              margin: 2px 0;
              background-color: rgba(232, 122, 37, 0.2);
              text-transform: uppercase;

              & i {
                font-size: 16px;
              }

              & .text {
                padding-left: 10px;
              }

              &:hover {
                background-color: rgba(232, 122, 37, 0.8);
                color: $white;
                cursor: pointer;
              }
            }
          }
        }

        &.RequestInfo {
          background-color: $requestindicator_color;
          color: $green;

          & .title {
            background-color: rgba(185, 243, 174, 0.375);
            padding: 5px 15px 5px 15px;
          }

          & .info {
            padding: 0px 5px;
            height: 150px;
            overflow-y: scroll;

            & .options {
              font-size: 10px;
              padding: 5px 10px 0 10px;

              span.label {
                display: block;
                text-decoration: underline;
                text-transform: uppercase;
              }

              & i {
                font-size: 14px;
              }
            }

            @include lb-scrollbar(transparent, rgba(255, 83, 241, 0.25));
          }
        }
      }
    }

    & .tabs {
      position: absolute;
      right: 0;
      top: 0;

      & .tab {
        padding: 10px 9px;
        background-color: $lightlightlightgray;
        border-right: 3px solid $white;
        z-index: 1;

        i {
          font-size: 18px;
        }

        &:hover {
          background-color: $lightlightgray;
          cursor: pointer;
        }

        &.active {
          position: relative;
          z-index: 3;
        }

        /* specific tabs */
        &.personnel {
          &:hover {
            background-color: #ffe9d7;
          }

          &.active {
            background-color: #ffe9d7;
            border-right: 3px solid rgba(232, 122, 37, 0.5);
            color: rgba(232, 122, 37, 1);

            &:hover {
              cursor: initial;
            }
          }
        }

        &.details {
          &:hover {
            background-color: rgba(38, 148, 232, 0.15);
          }

          &.active {
            background-color: rgba(38, 148, 232, 0.15);
            border-right: 3px solid rgba(38, 148, 232, 0.5);
            color: rgba(38, 148, 232, 1);

            &:hover {
              cursor: initial;
            }
          }
        }

        &.history {
          &:hover {
            background-color: rgba(255, 83, 241, 0.15);
          }

          &.active {
            background-color: rgba(255, 83, 241, 0.15);
            border-right: 3px solid rgba(255, 83, 241, 0.5);
            color: rgba(255, 83, 241, 1);

            &:hover {
              cursor: initial;
            }
          }
        }

        &.actions {
          &:hover {
            background-color: rgba(232, 122, 37, 0.15);
          }

          &.active {
            background-color: rgba(232, 122, 37, 0.15);
            border-right: 5px solid rgba(232, 122, 37, 0.5);
            color: rgba(232, 122, 37, 1);

            &:hover {
              cursor: initial;
            }
          }
        }

        &.requestinfo {
          &:hover {
            background-color: $requestindicator_color;
          }

          &.active {
            background-color: $requestindicator_color;
            border-right: 3px solid $requestindicator_bordercolor;
            color: $green;

            &:hover {
              cursor: initial;
            }
          }
        }
      }
    }
  }

  .footer {
    @include span-columns(14);
    @include shift(0);
    padding: 10px 10px 10px;
    text-align: center;

    .edit-button {
      @include span-columns(4);
      margin: 0 auto;
      text-align: center;
      padding: 5px 5px;
      font-size: 14px;
      text-transform: uppercase;
      color: $gray1;
      background-color: $lightlightlightgray;
      border: 1px solid $lightlightgray;

      & .fa {
        font-size: 17px;
        margin-right: 5px;
        display: none;
      }

      &:hover {
        cursor: pointer;
        background-color: $lightblue;
        border: 1px solid $darkblue;
        color: $white;

        & .fa {
          color: $white;
        }
      }
    }

    .last-modified {
      @include span-columns(14);
      @include pad(10px 5px 5px);
      text-align: left;

      .history-link {
        margin-left: 10px;
        color: $lightblue;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          color: $darkblue;
        }
      }
    }
  }

  // statuses
  &.scheduled {
    border: 5px solid $lightorange;

    .header {
      background-color: $lightorange;
    }

    .close {
      color: $darkdarkorange;

      &:hover {
        color: $darkdarkdarkorange;
      }
    }

    .mobile-section-nav {
      @include span-columns(14);
      @include pad(0px 0px 0);

      .mobile-section-nav-tab {
        color: $darkorange;

        &.active {
          background-color: $darkorange;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .tag {
      color: $white;
    }
  }

  &.pending {
    border: 5px solid rgba(62, 171, 255, 0.8);

    .close {
      color: $darkblue;
      &:hover {
        color: $darkdarkdarkblue;
      }
    }

    .mobile-section-nav {
      @include span-columns(14);
      @include pad(0px 0px 0);

      .mobile-section-nav-tab {
        color: $darkblue;

        &.active {
          background-color: $lightblue;
          color: $white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .header {
      @include span-columns(14);
      background-color: rgba(62, 171, 255, 0.8);
      color: $white;
    }

    .tag {
      color: $white;
    }

    & .content-body {
      & .details {
        @include lb-scrollbar(transparent, rgba(62, 171, 255, 0.3));
      }
    }
  }

  &.expanded {
    width: 480px;
    margin-left: -240px;

    & .content-body {
      & .details {
        & .personnel-container {
          @include span-columns(6);
        }

        & .pending-replace-icon-container {
          @include span-columns(1);
          @include pad(15px 0);
          text-align: right;

          .fa {
            font-size: 16px;
            color: $gray1;
          }
        }
      }
    }

    .footer {
      .edit-button {
        @include span-columns(3);
      }
    }
  }

  &.swop {
    width: 640px;
    margin-left: -320px;

    & .content-body {
      & .details {
        @include span-columns(8);

        & .personnel-container {
          @include span-columns(14);
        }
      }
    }

    .footer {
      .edit-button {
        @include span-columns(2);
      }
    }
  }
}

@import "./responsive";
